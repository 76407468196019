<template>
    <v-card width="100%" elevation="3">
        <!-- NIFE Number -->
        <v-card
            v-if="account.isMainAccount"
            class="mt-n5 ml-8 pink"
            width="240"
        >
            <v-card-title
                class="py-1 white--text justify-center font-weight-medium"
            >
                Correspondentieadres
            </v-card-title>
        </v-card>
        <v-card
            v-else
            :class="
                account.customerTypeCode == '603540000'
                    ? 'mt-n5 ml-8 grey'
                    : 'mt-n5 ml-8 primary'
            "
            width="140"
        >
            <v-card-title
                class="py-1 white--text justify-center font-weight-medium"
            >
                {{ account.dynamicsNifenummer }}
            </v-card-title>
        </v-card>

        <v-card-text class="mt-2 px-10">
            <div>{{ account.name }}</div>
            <p class="text-h5 black--text">
                {{ account.fullAddress }}
            </p>

            <!-- genneral contact info -->
            <v-subheader class="pl-0"
                ><v-icon class="mr-3 mb-1">call</v-icon>Algemeen contact
            </v-subheader>

            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <!-- <h5 :class="account.isMainAccount ? 'pink--text' : 'primary--text'"> -->
                    <h5>Telefoon</h5>

                    <div class="black--text mb-5">
                        {{ account.generalTelephone }}
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <!-- <h5 :class="account.isMainAccount ? 'pink--text' : 'primary--text'"> -->
                    <h5>
                        Email
                    </h5>

                    <div class="black--text mb-5">
                        {{ account.generalEmail }}
                    </div>
                </v-col>
            </v-row>

            <!-- contact person -->
            <v-subheader class="pl-0"
                ><v-icon class="mr-3 mb-1">person</v-icon>Contactpersonen
            </v-subheader>
            <v-row>
                <!-- Energy Invoice Contact Person -->
                <v-col cols="12" sm="6" md="4">
                    <!-- <h5 :class="account.isMainAccount ? 'pink--text' : 'primary--text'"> -->
                    <h5>
                        Energiefacturen
                    </h5>
                    <v-row>
                        <v-col cols="12">
                            <div
                                v-if="account.energyInvoiceContact"
                                class="black--text mb-5"
                            >
                                <!-- {{ account.energyInvoiceContact.fullName
                                }}<br /> -->
                                {{ account.energyInvoiceContact.email }}<br />
                                {{ account.energyInvoiceContact.telephone }}
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div
                                v-if="account.energyInvoiceContact2"
                                class="black--text mb-5"
                            >
                                <!-- {{ account.energyInvoiceContact2.fullName
                                }}<br /> -->
                                {{ account.energyInvoiceContact2.email }}<br />
                                {{ account.energyInvoiceContact2.telephone }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Purchase Energy Contact Person -->
                <v-col cols="12" sm="6" md="4">
                    <!-- <h5 :class="account.isMainAccount ? 'pink--text' : 'primary--text'"> -->
                    <h5>
                        Inkoop Energie
                    </h5>
                    <v-row>
                        <v-col cols="12">
                            <div
                                v-if="account.purchaseEnergyContact"
                                class="black--text mb-5"
                            >
                                <!-- {{ account.purchaseEnergyContact.fullName
                                }}<br /> -->
                                {{ account.purchaseEnergyContact.email }}<br />
                                {{ account.purchaseEnergyContact.telephone }}
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div
                                v-if="account.purchaseEnergyContact2"
                                class="black--text mb-5"
                            >
                                <!-- {{ account.purchaseEnergyContact2.fullName
                                }}<br /> -->
                                {{ account.purchaseEnergyContact2.email }}<br />
                                {{ account.purchaseEnergyContact2.telephone }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Service Invoice Contact Person -->
                <v-col cols="12" sm="6" md="4">
                    <!-- <h5 :class="account.isMainAccount ? 'pink--text' : 'primary--text'"> -->
                    <h5>
                        Dienstverleningsfactuur
                    </h5>
                    <v-row>
                        <v-col cols="12">
                            <div
                                v-if="account.serviceInvoiceContact"
                                class="black--text mb-5"
                            >
                                <!-- {{ account.serviceInvoiceContact.fullName
                                }}<br /> -->
                                {{ account.serviceInvoiceContact.email }}<br />
                                {{ account.serviceInvoiceContact.telephone }}
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div
                                v-if="account.serviceInvoiceContact2"
                                class="black--text mb-5"
                            >
                                <!-- {{ account.serviceInvoiceContact2.fullName
                                }}<br /> -->
                                {{ account.serviceInvoiceContact2.email }}<br />
                                {{ account.serviceInvoiceContact2.telephone }}
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
export default {
    name: "account-detail-card",
    props: ["account"],
    data: () => ({})
};
</script>



