<template>
    <v-container fluid class="pa-0" v-if="me.isStaff">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-col cols="auto">
                        <h2 class="display-3 font-weight-medium">Accounts</h2>
                        <!-- <v-progress-linear
                            :active="$apollo.queries.accounts.loading"
                            color="primary"
                            buffer-value="0"
                            stream
                            class="mx-0 mt-2 px-0"
                        ></v-progress-linear> -->
                    </v-col>
                    <v-spacer></v-spacer>
                </v-card-title>
            </v-card>
        </v-container>

        <v-card flat color="grey lighten-4 pt-5" min-height="73vh" fill-width>
            <v-container>
                <!-- filter -->
                <v-card class="mt-0 py-5" elevation="3">
                    <v-row align="center" class="px-5">
                        <v-col cols="auto">
                            <v-text-field
                                v-model="filter.dynamicsNifenummer"
                                label="Nife Nummer"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field
                                v-model="filter.name"
                                label="Naam"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field
                                v-model="filter.fullAddress"
                                label="Adres"
                                placeholder="Straat / Postcode"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field
                                v-model="filter.search"
                                label="EAN"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-switch
                                color="pink"
                                v-model="isMainAccountValue"
                                label="Hoofd accounts"
                                hide-details
                                class="mt-0"
                            ></v-switch>
                        </v-col>
                        <v-spacer />
                    </v-row>
                </v-card>

                <!-- data table card -->
                <v-card class="mt-3" elevation="3">
                    <v-card-text class="pb-3">
                        <v-data-table
                            :headers="headers"
                            :loading="
                                $apollo.queries.accounts.loading ||
                                    isLoggingInAsOtherUser
                            "
                            :items="accounts.edges"
                            :expanded.sync="expanded"
                            show-expand
                            item-key="node.id"
                            sort-by="node.dynamicsNifenummer"
                            :sort-desc="false"
                            loading-text="Een ogenblik geduld aub..."
                            hide-default-footer
                            disable-pagination
                            @click:row="
                                (item, slot) => slot.expand(!slot.isExpanded)
                            "
                        >
                            <!-- data table progress bar -->
                            <template v-slot:progress class="px-0 mx-0">
                                <v-progress-linear
                                    absolute
                                    height="2"
                                    indeterminate
                                ></v-progress-linear>
                            </template>

                            <!-- no data availabel -->
                            <template slot="no-data">
                                <div>Geen gegevens beschikbaar</div>
                            </template>

                            <!-- formatted start date -->
                            <template
                                v-slot:item.node.dynamicsNifenummer="{ item }"
                            >
                                <v-chip
                                    label
                                    dark
                                    :class="
                                        item.node.customerTypeCode ==
                                        '603540000'
                                            ? 'grey'
                                            : item.node.isMainAccount
                                            ? 'pink'
                                            : 'primary'
                                    "
                                    >{{ item.node.dynamicsNifenummer }}
                                </v-chip>
                            </template>

                            <!-- expanded area -->
                            <template v-slot:expanded-item="{ headers, item }">
                                <!-- main account -->
                                <td
                                    v-if="item.node.isMainAccount"
                                    :colspan="headers.length"
                                    class="px-5 py-5"
                                >
                                    <!-- users -->
                                    <v-card-text>
                                        <v-row
                                            class="align-center ml-0 pl-0 mb-0"
                                        >
                                            <v-col cols="auto">
                                                <h2>Gebruikers</h2>
                                            </v-col>
                                            <v-spacer />
                                            <v-col cols="auto">
                                                <v-btn
                                                    small
                                                    rounded
                                                    color="primary"
                                                    class="mb-1"
                                                    :disabled="
                                                        !item.node.tenant
                                                    "
                                                    @click="
                                                        addUser(
                                                            item.node.tenant.id
                                                        )
                                                    "
                                                    >Toevoegen</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <!-- no data -->
                                        <v-card
                                            v-if="
                                                !item.node.tenant ||
                                                    item.node.tenant
                                                        .tenantStaffSet.edges
                                                        .length == 0
                                            "
                                            color="transparent"
                                            flat
                                            class="mx-auto text-center"
                                            height="40"
                                            width="200"
                                        >
                                            <v-responsive
                                                height="20"
                                            ></v-responsive>
                                            <div
                                                class="grey--text text--darken-2"
                                            >
                                                Geen gegevens beschikbaar
                                            </div>
                                        </v-card>

                                        <!-- users -->
                                        <v-row v-if="item.node.tenant">
                                            <v-col cols="12">
                                                <v-list two-line>
                                                    <template
                                                        v-for="(tenantStaff,
                                                        index) in [
                                                            ...item.node.tenant
                                                                .tenantStaffSet
                                                                .edges
                                                        ].sort((a, b) => {
                                                            if (
                                                                a.node
                                                                    .isAdmin &&
                                                                !b.node.isAdmin
                                                            ) {
                                                                return -1;
                                                            } else if (
                                                                !a.node
                                                                    .isAdmin &&
                                                                b.node.isAdmin
                                                            ) {
                                                                return 1;
                                                            } else {
                                                                if (
                                                                    a.node.user
                                                                        .firstName >
                                                                    b.node.user
                                                                        .firstName
                                                                ) {
                                                                    return 1;
                                                                } else if (
                                                                    a.node.user
                                                                        .firstName <
                                                                    b.node.user
                                                                        .firstName
                                                                ) {
                                                                    return -1;
                                                                } else {
                                                                    return 0;
                                                                }
                                                            }
                                                        })"
                                                    >
                                                        <v-list-item
                                                            :key="
                                                                tenantStaff.node
                                                                    .id
                                                            "
                                                        >
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <strong>
                                                                        {{
                                                                            tenantStaff
                                                                                .node
                                                                                .user
                                                                                .firstName
                                                                        }}
                                                                        {{
                                                                            tenantStaff
                                                                                .node
                                                                                .user
                                                                                .lastName
                                                                        }}</strong
                                                                    >
                                                                    <v-chip
                                                                        small
                                                                        dark
                                                                        color="pink"
                                                                        class="ml-2 mb-1"
                                                                        v-if="
                                                                            tenantStaff
                                                                                .node
                                                                                .isAdmin
                                                                        "
                                                                        >Beheerder</v-chip
                                                                    ><v-chip
                                                                        v-if="
                                                                            tenantStaff
                                                                                .node
                                                                                .user
                                                                                .enabled2fa
                                                                        "
                                                                        color="success"
                                                                        small
                                                                        class="ml-2 mb-1"
                                                                        ><v-icon
                                                                            small
                                                                            color="white"
                                                                            left
                                                                            >verified_user</v-icon
                                                                        >2FA</v-chip
                                                                    >
                                                                    <v-chip
                                                                        v-else
                                                                        color="grey"
                                                                        disabled
                                                                        small
                                                                        class="ml-2 mb-1"
                                                                        ><v-icon
                                                                            small
                                                                            color="white"
                                                                            left
                                                                            >cancel</v-icon
                                                                        >2FA</v-chip
                                                                    >
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle
                                                                    class="text-wrap"
                                                                    >{{
                                                                        tenantStaff
                                                                            .node
                                                                            .user
                                                                            .email
                                                                    }}
                                                                    <span
                                                                        v-if="
                                                                            tenantStaff
                                                                                .node
                                                                                .user
                                                                                .lastLogin
                                                                        "
                                                                        class="ml-5"
                                                                        >(
                                                                        laatst
                                                                        ingelogd
                                                                        op
                                                                        {{
                                                                            tenantStaff
                                                                                .node
                                                                                .user
                                                                                .lastLogin
                                                                                | moment(
                                                                                    "DD-MM-YYYY HH:mm"
                                                                                )
                                                                        }}
                                                                        )</span
                                                                    >
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>

                                                            <v-list-item-action>
                                                                <v-row>
                                                                    <v-tooltip
                                                                        bottom
                                                                    >
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-btn
                                                                                v-bind="
                                                                                    attrs
                                                                                "
                                                                                v-on="
                                                                                    on
                                                                                "
                                                                                icon
                                                                                outlined
                                                                                small
                                                                                color="warning"
                                                                                class="mr-1"
                                                                                :disabled="
                                                                                    isLoggingInAsOtherUser
                                                                                "
                                                                                @click="
                                                                                    loginAsOtherUser(
                                                                                        tenantStaff
                                                                                            .node
                                                                                            .user
                                                                                            .id
                                                                                    )
                                                                                "
                                                                            >
                                                                                <v-icon
                                                                                    small
                                                                                    >mouse</v-icon
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span
                                                                            >Log
                                                                            in
                                                                            als
                                                                            deze
                                                                            gebruiker</span
                                                                        >
                                                                    </v-tooltip>
                                                                    <v-tooltip
                                                                        bottom
                                                                    >
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-btn
                                                                                v-bind="
                                                                                    attrs
                                                                                "
                                                                                v-on="
                                                                                    on
                                                                                "
                                                                                icon
                                                                                outlined
                                                                                small
                                                                                color="primary"
                                                                                class="mr-1"
                                                                                @click="
                                                                                    editUser(
                                                                                        tenantStaff.node
                                                                                    )
                                                                                "
                                                                            >
                                                                                <v-icon
                                                                                    small
                                                                                    >edit</v-icon
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span
                                                                            >Bewerk
                                                                            gebruiker</span
                                                                        >
                                                                    </v-tooltip>
                                                                    <v-tooltip
                                                                        bottom
                                                                    >
                                                                        <template
                                                                            v-slot:activator="{
                                                                                on,
                                                                                attrs
                                                                            }"
                                                                        >
                                                                            <v-btn
                                                                                v-bind="
                                                                                    attrs
                                                                                "
                                                                                v-on="
                                                                                    on
                                                                                "
                                                                                icon
                                                                                outlined
                                                                                small
                                                                                color="error"
                                                                                @click="
                                                                                    deletingUser(
                                                                                        tenantStaff.node
                                                                                    )
                                                                                "
                                                                            >
                                                                                <v-icon
                                                                                    small
                                                                                    >delete</v-icon
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <span
                                                                            >Verwijder
                                                                            gebruiker</span
                                                                        >
                                                                    </v-tooltip>
                                                                </v-row>
                                                            </v-list-item-action>
                                                        </v-list-item>

                                                        <v-divider
                                                            v-if="
                                                                index + 1 <
                                                                    item.node
                                                                        .tenant
                                                                        .tenantStaffSet
                                                                        .edges
                                                                        .length
                                                            "
                                                            :key="index"
                                                        ></v-divider>
                                                    </template>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </td>

                                <!-- sub account -->
                                <td
                                    v-else
                                    :colspan="headers.length"
                                    class="px-5 py-5"
                                >
                                    <!-- no data -->
                                    <v-card
                                        v-if="
                                            item.node.eanElectricitySet.edges
                                                .length == 0 &&
                                                item.node.eanGasSet.edges
                                                    .length == 0
                                        "
                                        color="transparent"
                                        flat
                                        class="mx-auto text-center"
                                        height="40"
                                        width="200"
                                    >
                                        <v-responsive
                                            height="10"
                                        ></v-responsive>
                                        <div class="grey--text text--darken-2">
                                            Geen gegevens beschikbaar
                                        </div>
                                    </v-card>

                                    <!-- eans of sub accounts -->
                                    <v-card-text>
                                        <!-- Electricity -->
                                        <v-row class="mx-0 px-0">
                                            <v-col
                                                v-for="e in [
                                                    ...item.node
                                                        .eanElectricitySet.edges
                                                ].sort((a, b) => {
                                                    if (
                                                        a.node.ean > b.node.ean
                                                    ) {
                                                        return 1;
                                                    } else if (
                                                        a.node.ean == b.node.ean
                                                    ) {
                                                        return 0;
                                                    } else if (
                                                        a.node.ean < b.node.ean
                                                    ) {
                                                        return -1;
                                                    }
                                                })"
                                                :key="e.node.id"
                                                cols="12"
                                                sm="12"
                                                class="py-0 px-0"
                                            >
                                                <ElektraEanStatusCard
                                                    :key="e.node.id"
                                                    :e="e"
                                                    v-on:showEanMeasurement="
                                                        showEanMeasurement
                                                    "
                                                ></ElektraEanStatusCard>
                                            </v-col>
                                        </v-row>

                                        <!-- Gas -->
                                        <v-row class="mx-0 px-0">
                                            <v-col
                                                v-for="e in [
                                                    ...item.node.eanGasSet.edges
                                                ].sort((a, b) => {
                                                    if (
                                                        a.node.ean > b.node.ean
                                                    ) {
                                                        return 1;
                                                    } else if (
                                                        a.node.ean == b.node.ean
                                                    ) {
                                                        return 0;
                                                    } else if (
                                                        a.node.ean < b.node.ean
                                                    ) {
                                                        return -1;
                                                    }
                                                })"
                                                :key="e.node.id"
                                                cols="12"
                                                sm="12"
                                                class="py-0 px-0"
                                            >
                                                <GasEanStatusCard
                                                    :key="e.node.id"
                                                    :e="e"
                                                    v-on:showEanMeasurement="
                                                        showEanMeasurement
                                                    "
                                                ></GasEanStatusCard>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <!-- infinit loading trigger -->
                    <v-card-actions class="justify-center">
                        <v-btn
                            v-if="hasMoreData"
                            v-intersect="onLoadMoreTriggerIntersect"
                            :disabled="!hasMoreData"
                            :loading="$apollo.queries.accounts.loading"
                            text
                            class="mb-5"
                            @click="loadMore"
                        >
                            Meer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
        </v-card>

        <!-- Delete Tenant Staff Dialog -->
        <DeleteDialog
            :dialog.sync="deleteTenantStaffDialog"
            :item="deletedTenantStaff"
            v-on:confirmed="onDeleteConfirmed"
        >
            <template v-slot:title
                >Verwijderen -
                {{
                    deletedTenantStaff ? deletedTenantStaff.user.email : ""
                }}</template
            >
            <template v-slot:content
                >Weet u zeker dat u deze gebruiker wilt verwijderen?</template
            >
        </DeleteDialog>

        <!-- user edit dialog -->
        <GebruikerForm
            :dialog.sync="tenantStaffDialog"
            :object.sync="editedTenantStaff"
            :tenantNodeId.sync="selectTenantNodeId"
            v-on:done="refetchDate"
        ></GebruikerForm>

        <!-- ean measurement dialog -->
        <VerbruikDialog
            :key="selectedEAN"
            :dialog.sync="eanMeasurmentDialog"
            :measurementType="selectedMeasurementType"
            :ean="selectedEAN"
        >
        </VerbruikDialog>
    </v-container>
</template>


<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import GebruikerForm from "../components/instellingen/forms/GebruikerForm.vue";
import VerbruikDialog from "../components/verbruik/VerbruikDialog.vue";
import ElektraEanStatusCard from "../components/accounts/ElektraEanStatusCard.vue";
import GasEanStatusCard from "../components/accounts/GasEanStatusCard.vue";
import moment from "moment";

export default {
    name: "accounts",
    title: "Accounts",
    components: {
        DeleteDialog,
        GebruikerForm,
        VerbruikDialog,
        ElektraEanStatusCard,
        GasEanStatusCard
    },

    apollo: {
        accounts: {
            query: gql`
                query accounts(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $isMainAccount: Boolean
                    $dynamicsNifenummer: String
                    $fullAddress: String
                    $name: String
                    $search: String
                    $orderBy: String
                ) {
                    accounts(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        isMainAccount: $isMainAccount
                        dynamicsNifenummer_Icontains: $dynamicsNifenummer
                        fullAddress_Icontains: $fullAddress
                        name_Icontains: $name
                        search: $search
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                name
                                isMainAccount
                                dynamicsNifenummer
                                customerTypeCode
                                fullAddress
                                tenant {
                                    id
                                    # tenantStaffGroupSet {
                                    #     edges {
                                    #         node {
                                    #             id
                                    #             name
                                    #         }
                                    #     }
                                    # }
                                    tenantStaffSet {
                                        edges {
                                            node {
                                                id
                                                isAdmin
                                                user {
                                                    id
                                                    email
                                                    firstName
                                                    lastName
                                                    require2fa
                                                    enabled2fa
                                                    lastLogin
                                                }
                                            }
                                        }
                                    }
                                }
                                eanElectricitySet {
                                    edgeCount
                                    edges {
                                        node {
                                            id
                                            ean
                                            kvOrGv
                                            myMeasurementDataCode
                                            myMeasurementData
                                            meterType
                                            measurementCompany
                                            dynamicsSlimmemeter
                                            dynamicsMeetbedrijf
                                            dynamicsMeetbedrijfeinddatum
                                            dynamicsMeetbedrijfnieuw
                                            dynamicsIngangsdatumnieuw
                                            measurementApiDataStatus
                                            measurementApiLastSyncDate
                                            measurementApiLastSyncRows
                                            measurementApiLastSyncError
                                        }
                                    }
                                }
                                eanGasSet {
                                    edgeCount
                                    edges {
                                        node {
                                            id
                                            ean
                                            kvOrGv
                                            myMeasurementDataCode
                                            myMeasurementData
                                            meterType
                                            measurementCompany
                                            dynamicsSlimmemeter
                                            dynamicsMeetbedrijf
                                            dynamicsMeetbedrijfeinddatum
                                            dynamicsMeetbedrijfnieuw
                                            dynamicsIngangsdatumnieuw
                                            measurementApiDataStatus
                                            measurementApiLastSyncDate
                                            measurementApiLastSyncRows
                                            measurementApiLastSyncError
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.filter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.accounts,
            // result({ data, loading }) {
            //     // load next page if loadMore trigger is still visible
            //     if (
            //         !loading &&
            //         data.energyContracts?.pageInfo?.hasNextPage &&
            //         this.shouldLoadMore
            //     ) {
            //         console.log(
            //             "loadMore() triggered by apollo result: loading:",
            //             loading,
            //             ",hasNextPage:",
            //             data.energyContracts.pageInfo.hasNextPage,
            //             ",shouldLoadMore",
            //             this.shouldLoadMore
            //         );
            //         this.loadMore();
            //     }
            // },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return !this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            menu: false,
            isMainAccountValue: false,
            tenantStaffDialog: false,
            deleteTenantStaffDialog: false,
            editedTenantStaff: null,
            deletedTenantStaff: null,
            isLoggingInAsOtherUser: false,
            selectTenantNodeId: null,
            eanMeasurmentDialog: false,
            selectedMeasurementType: null,
            selectedEAN: null,

            // graphql query filter
            filter: {
                first: 25,
                after: null,
                dynamicsNifenummer: null,
                isMainAccount: null,
                fullAddress: null,
                name: null,
                search: null,
                orderBy: "dynamicsNifenummer"
            },
            expanded: [],
            accounts: {},

            // infinite loading trigger
            shouldLoadMore: false,

            headers: [
                {
                    text: "Nife Nummer",
                    align: "left",
                    value: "node.dynamicsNifenummer",
                    sortable: true
                },
                {
                    text: "Naam",
                    value: "node.name",
                    sortable: true
                },
                {
                    text: "Adres",
                    align: "left",
                    value: "node.fullAddress",
                    sortable: true
                },
                { text: "", value: "data-table-expand" }
            ]
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        // infinite
        hasMoreData() {
            return this.accounts?.pageInfo?.hasNextPage;
        }
    },
    watch: {
        filter: {
            deep: true,

            handler(val) {
                localStorage.accountFilter = JSON.stringify(val);
                this.refetchDate();
            }
        },
        isMainAccountValue(val) {
            this.filter.isMainAccount = val ? val : null;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        if (localStorage.accountFilter) {
            this.filter = JSON.parse(localStorage.accountFilter);
        }

        this.filter.isMainAccount = this.isMainAccountValue
            ? this.isMainAccountValue
            : null;

        // this.fetchMe();
    },
    methods: {
        // fetchMe() {
        //     this.$store
        //         .dispatch("user/fetchMe")
        //         .then(() => {})
        //         .catch(error => {
        //             console.log("fetchMe catched error: ", error);
        //         });
        // },
        formatDate(date) {
            if (date) {
                return moment(date).format("DD-MM-YYYY");
            } else {
                return "-";
            }
        },

        // infinite loading trigger
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.accounts?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.accounts.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.accounts?.pageInfo?.hasNextPage) {
                this.$apollo.queries.accounts.fetchMore({
                    variables: {
                        after: this.accounts.pageInfo.endCursor
                    }
                });
            }
        },

        addUser(tenantNodeId) {
            this.editedTenantStaff = null;
            this.selectTenantNodeId = tenantNodeId;
            this.tenantStaffDialog = true;
        },
        editUser(user) {
            this.editedTenantStaff = JSON.parse(JSON.stringify(user));
            this.tenantStaffDialog = true;
        },
        deletingUser(item) {
            this.deletedTenantStaff = item;
            this.deleteTenantStaffDialog = true;
        },
        onDeleteConfirmed(item) {
            if (item) {
                const payload = {
                    id: item.id
                };

                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation deleteTenantStaff(
                                $input: DeleteTenantStaffInput!
                            ) {
                                deleteTenantStaff(input: $input) {
                                    tenantStaff {
                                        id
                                        isAdmin
                                        user {
                                            id
                                            email
                                            firstName
                                            lastName
                                            require2fa
                                            enabled2fa
                                            lastLogin
                                        }
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(() => {
                        this.deleteTenantStaffDialog = false;
                        this.refetchDate();

                        // show snackbar
                        const payload = {
                            color: "success",
                            message: "De gebruiker is verwijderd!"
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        // Error
                        console.error(error);
                    })
                    .finally(() => {});
            }
        },

        loginAsOtherUser(userId) {
            this.isLoggingInAsOtherUser = true;

            const payload = { userId: userId };

            this.$store
                .dispatch("user/loginAsOtherUser", payload)
                // .then(() => {
                //     return new Promise((resolve) => {
                //         this.$apollo.getClient().clearStore();
                //         resolve();
                //     });
                // })
                .then(() => {
                    this.$router.push(this.$route.query.redirect || "/");
                })
                // .then(()=>{
                //     this.$apollo.getClient().clearStore();
                // })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            // show snackbar
                            const payload = {
                                color: "error",
                                message: err.message
                            };
                            this.$store.dispatch(
                                "snackbar/showMessage",
                                payload
                            );
                        }
                    }
                })
                .finally(() => {
                    this.isLoggingInAsOtherUser = false;
                });
        },

        showEanMeasurement(measurementType, ean) {
            this.eanMeasurmentDialog = true;
            this.selectedMeasurementType = measurementType;
            this.selectedEAN = ean;
        }
    }
};
</script>