var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ",attrs:{"fluid":""}},[_c('v-container',{staticClass:"pa-0"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"my-0 px-3 align-end"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"display-3 font-weight-medium"},[_vm._v(" Facturen ")])]),_c('v-spacer'),_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto"}},[_c('v-tabs',{staticClass:"justify-center",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                                    _vm.me.isTenantAdmin ||
                                        _vm.hasPermission(
                                            'energy.view_energyinvoice'
                                        )
                                ),expression:"\n                                    me.isTenantAdmin ||\n                                        hasPermission(\n                                            'energy.view_energyinvoice'\n                                        )\n                                "}]},[_vm._v("Energiefacturen")]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                                    _vm.me.isTenantAdmin ||
                                        _vm.hasPermission(
                                            'energy.view_serviceinvoice'
                                        )
                                ),expression:"\n                                    me.isTenantAdmin ||\n                                        hasPermission(\n                                            'energy.view_serviceinvoice'\n                                        )\n                                "}]},[_c('v-badge',{attrs:{"color":"pink","content":_vm.openInvoiceCount,"value":_vm.openInvoiceCount}},[_vm._v("Dienstverlening ")])],1)],1)],1)],1)],1)],1)],1),_c('v-card',{attrs:{"flat":"","color":"grey lighten-4 pt-15","min-height":"73vh","fill-width":""}},[_c('router-view',{on:{"loading":_vm.onLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }