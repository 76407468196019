<template>
    <!-- Edit profile -->
    <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
        <v-card>
            <v-card-title class="primary white--text">
                {{ formTitle }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-alert v-if="error" tile type="error" class="mb-0">{{
                error
            }}</v-alert>

            <v-card-text class="pt-10">
                <v-form v-model="valid" ref="form">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.firstName"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="Voornaam*"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.lastName"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="Achternaam*"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.email"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="E-mailadres*"
                                type="email"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                :disabled="editedItem.email == me.email"
                                v-model="editedItem.isAdmin"
                                :label="`Beheerder`"
                                class="my-0 py-0"
                                hide-details
                            ></v-switch>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                v-model="editedItem.require2fa"
                                :label="`2FA vereist`"
                                class="my-0 py-0"
                                hide-details
                            ></v-switch>
                        </v-col>
                        <v-col v-if="!isNewUser" cols="12">
                            <v-row class="align-center">
                                <!-- <v-col
                                    v-if="
                                        editedItem.enabled2fa &&
                                            !editedItem.clear2fa
                                    "
                                    cols="6"
                                    class="mb-0 pb-0"
                                >
                                    <v-icon color="success"
                                        >verified_user</v-icon
                                    >
                                    2FA is ingeschakeld!
                                </v-col>
                                <v-col
                                    v-else-if="
                                        editedItem.enabled2fa &&
                                            editedItem.clear2fa
                                    "
                                    cols="6"
                                    class="mb-0 pb-0"
                                >
                                    <v-icon color="error">highlight_off</v-icon>
                                    2FA wordt gereset!
                                </v-col>
                                <v-col v-else cols="6" class="mb-0 pb-0">
                                    <v-icon color="warning"
                                        >highlight_off</v-icon
                                    >
                                    2FA is nog niet ingesteld!
                                </v-col> -->
                                <v-col
                                    cols="6"
                                    class="mb-0 pb-0"
                                >
                                    <v-checkbox
                                        :disabled="!editedItem.enabled2fa"
                                        v-model="editedItem.clear2fa"
                                        color="error"
                                        label="Wissen 2FA"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="isSaving"
                    text
                    rounded
                    @click="closeUserDialog"
                    >Annuleren</v-btn
                >
                <v-btn
                    outlined
                    rounded
                    :disabled="!valid"
                    :loading="isSaving"
                    color="primary"
                    @click="saveUser"
                    >Opslaan</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "gebruiker-form",
    props: ["dialog", "object", "tenantNodeId"],
    data() {
        return {
            valid: false,
            error: null,
            isSaving: false,

            editedItem: {
                id: null,
                email: null,
                firstName: null,
                lastName: null,
                isAdmin: false,
                require2fa: false,
                enabled2fa: false,
                clear2fa: false
            },
            defaultItem: {
                id: null,
                email: null,
                firstName: null,
                lastName: null,
                isAdmin: false,
                require2fa: false,
                enabled2fa: false,
                clear2fa: false
            },
            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                minLength: len => v =>
                    (v || "").length >= len ||
                    `Invalid character length, required ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
            }
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        isNewUser() {
            return this.object ? false : true;
        },
        formTitle() {
            return this.isNewUser
                ? "Voeg gebruiker toe"
                : "Gebruiker bijwerken";
        }
    },
    watch: {
        // reset form when dialog open or close
        dialog() {
            this.resetForm();
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    methods: {
        resetForm() {
            // reset form state
            this.error = null;
            this.isSaving = false;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }

            // when editing, set form with object
            if (this.dialog && this.object) {
                this.editedItem.id = this.object.id;
                this.editedItem.email = this.object.user.email;
                this.editedItem.firstName = this.object.user.firstName;
                this.editedItem.lastName = this.object.user.lastName;
                this.editedItem.isAdmin = this.object.isAdmin;
                this.editedItem.require2fa = this.object.user.require2fa;
                this.editedItem.enabled2fa = this.object.user.enabled2fa;
                this.editedItem.clear2fa = false;
            }
            // when new or closing, set form with default
            else {
                this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
            }
        },

        closeUserDialog() {
            this.$emit("update:dialog", false);
            this.resetForm();
        },

        saveUser() {
            if (!this.$refs.form.validate()) {
                return;
            }

            // set form state
            this.error = null;
            this.isSaving = true;

            // prepare api call payload
            var payload = {
                email: this.editedItem.email,
                firstName: this.editedItem.firstName,
                lastName: this.editedItem.lastName,
                isAdmin: this.editedItem.isAdmin,
                require2fa: this.editedItem.require2fa
            };

            // if editing
            if (this.object) {
                payload = {
                    ...payload,
                    id: this.editedItem.id,
                    clear2fa: this.editedItem.clear2fa
                };

                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation updateTenantStaff(
                                $input: UpdateTenantStaffInput!
                            ) {
                                updateTenantStaff(input: $input) {
                                    tenantStaff {
                                        id
                                        isAdmin
                                        user {
                                            id
                                            email
                                            firstName
                                            lastName
                                            lastLogin
                                            require2fa
                                            enabled2fa
                                        }
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: { ...payload }
                        }
                    })
                    .then(() => {
                        this.closeUserDialog();
                        this.$emit("done");
                    })
                    .catch(error => {
                        if (error.graphQLErrors) {
                            this.error = error.graphQLErrors[0].message;
                            console.error(error.graphQLErrors[0].message);
                            // this.error =
                            //     "Er is iets misgegaan, probeer het later opnieuw.";
                        }
                    })
                    .finally(() => {
                        this.isSaving = false;
                    });
            }
            // new
            else {
                payload = {
                    ...payload,
                    tenantNodeId: this.me.isStaff ? this.tenantNodeId : this.me.tenant.id,
                    sendInvitationEmail: true
                };
                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation createTenantStaff(
                                $input: CreateTenantStaffInput!
                            ) {
                                createTenantStaff(input: $input) {
                                    tenantStaff {
                                        id
                                        isAdmin
                                        user {
                                            id
                                            email
                                            firstName
                                            lastName
                                            lastLogin
                                            require2fa
                                            enabled2fa
                                        }
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: { ...payload }
                        }
                    })
                    .then(() => {
                        this.closeUserDialog();
                        this.$emit("done");
                    })
                    .catch(error => {
                        if (error.graphQLErrors) {
                            this.error = error.graphQLErrors[0].message;
                            console.error(error.graphQLErrors[0].message);
                            // this.error =
                            //     "Er is iets misgegaan, probeer het later opnieuw.";
                        }
                    })
                    .finally(() => {
                        this.isSaving = false;
                    });
            }
        }
    }
};
</script>