<template>
    <v-container>
        <!-- div for help to place filter card -->
        <div class="mt-n16 white--text">.</div>

        <!-- filter -->
        <v-card class="mt-0 py-5" elevation="3">
            <v-row align="center" class="px-5">
                <!-- <v-col cols="auto">
                    <v-text-field
                        v-model="filter.contractNumber"
                        label="Contractnummer"
                        clearable
                        class="subtitle-2"
                    ></v-text-field>
                </v-col> -->
                <!-- <v-col cols="auto">
                    <v-text-field
                        v-model="filter.ean"
                        label="EAN"
                        clearable
                        class="subtitle-2"
                    ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="4" md="3" lg="3" xl="2">
                    <v-text-field
                        v-model="filter.agreementNumber"
                        label="Overeenkomstnummer"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="auto">
                    <v-text-field
                        v-model="filter.account_FullAddress"
                        label="Adres"
                        placeholder="Straat / Postcode"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="auto">
                    <v-text-field
                        v-model="filter.supplier"
                        label="Leverancier"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" sm="4" md="2">
                    <v-select
                        v-model="filter.energyType"
                        :items="energyTypes"
                        label="Product"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    >
                    </v-select>
                </v-col>
                <v-col cols="6" sm="4" md="2">
                    <v-select
                        v-model="filter.contractYear"
                        :items="contractYears"
                        label="Contractjaar"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    >
                    </v-select>
                </v-col>
                <v-spacer />
            </v-row>
        </v-card>

        <!-- data table card -->
        <v-card class="mt-3" elevation="3">
            <v-card-text class="pb-3">
                <v-data-table
                    :headers="headers"
                    :loading="$apollo.queries.allContracten.loading"
                    :items="allContracten.edges"
                    :expanded.sync="expanded"
                    single-expand
                    show-expand
                    item-key="node.id"
                    sort-by="node.agreementNumber"
                    :sort-desc="false"
                    loading-text="Een ogenblik geduld aub..."
                    hide-default-footer
                    disable-pagination
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                    <!-- data table progress bar -->
                    <template v-slot:progress class="px-0 mx-0">
                        <v-progress-linear
                            absolute
                            height="2"
                            indeterminate
                        ></v-progress-linear>
                    </template>

                    <!-- no data availabel -->
                    <template slot="no-data">
                        <div>Geen gegevens beschikbaar</div>
                    </template>

                    <!-- Address -->
                    <template v-slot:item.node.account.fullAddress="{ item }">
                        <span class="hidden-lg-and-up">{{
                            item.node.account.fullAddress | truncate(30)
                        }}</span>
                        <span class="hidden-md-and-down">{{
                            item.node.account.fullAddress
                        }}</span>
                    </template>
                    <!-- Energy Type -->
                    <template v-slot:item.node.energyType="{ item }">
                        <span v-if="item.node.energyType == 'Electricity'">
                            Elektra
                        </span>
                        <span v-else>{{ item.node.energyType }}</span>
                    </template>
                    <!-- formatted start date -->
                    <template v-slot:item.node.startDate="{ item }">
                        <span v-if="item.node.startDate">{{
                            item.node.startDate | moment("DD-MM-YYYY")
                        }}</span>
                    </template>

                    <!-- formatted end date -->
                    <template v-slot:item.node.endDate="{ item }">
                        <span v-if="item.node.endDate">{{
                            item.node.endDate | moment("DD-MM-YYYY")
                        }}</span>
                    </template>

                    <!-- expanded area -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="px-5 py-5">
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Klantnummer</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.account.dynamicsNifenummer }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>EAN</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.ean }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Adres</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.account.fullAddress }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Type inkoop</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.purchaseType }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Type prijs </strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.priceType }}
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>

            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.allContracten.loading"
                    text
                    class="mb-5"
                    @click="loadMore"
                >
                    Meer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "allContracten",
    title: "allContracten",
    components: {},

    apollo: {
        allContracten: {
            query: gql`
                query energyContracts(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $contractNumber: String
                    $agreementNumber: String
                    $ean: String
                    $energyType: String
                    $account_FullAddress: String
                    $supplier: String
                    $contractYear: Float
                    $startDateLte: DateTime
                    $endDateGte: DateTime
                    $orderBy: String
                ) {
                    energyContracts(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        contractNumber_Icontains: $contractNumber
                        agreementNumber_Icontains: $agreementNumber
                        ean_Icontains: $ean
                        energyType: $energyType
                        account_FullAddress_Icontains: $account_FullAddress
                        supplierAccount_Name_Icontains: $supplier
                        startDateYear: $contractYear
                        startDate_Lte: $startDateLte
                        endDate_Gte: $endDateGte
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                contractName
                                contractNumber
                                agreementNumber
                                ean
                                startDate
                                endDate
                                energyType
                                purchaseType
                                priceType
                                supplierAccount {
                                    id
                                    name
                                }
                                account {
                                    id
                                    dynamicsNifenummer
                                    fullAddress
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                // IMPORTANT: add same variables as refetch and fetchMore, otherwise merge result may contain duplicate keys
                return this.filter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.energyContracts,
            // result({ data, loading }) {
            //     // load next page if loadMore trigger is still visible
            //     if (
            //         !loading &&
            //         data.energyContracts?.pageInfo?.hasNextPage &&
            //         this.shouldLoadMore
            //     ) {
            //         console.log(
            //             "loadMore() triggered by apollo result: loading:",
            //             loading,
            //             ",hasNextPage:",
            //             data.energyContracts.pageInfo.hasNextPage,
            //             ",shouldLoadMore",
            //             this.shouldLoadMore
            //         );
            //         this.loadMore();
            //     }
            // },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            menu: false,
            dateRange: [],

            // graphql query filter
            filter: {
                first: 25,
                after: null,
                contractNumber: null,
                agreementNumber: null,
                contractYear: null,
                ean: null,
                energyType: null,
                account_FullAddress: null,
                supplier: null,
                orderBy: "agreementNumber"
            },
            expanded: [],
            allContracten: {},

            // infinite loading trigger
            shouldLoadMore: false,

            headers: [
                // {
                //     text: "Contractnummer",
                //     align: "left",
                //     value: "node.contractNumber",
                //     sortable: true
                // },
                // {
                //     text: "EAN",
                //     align: "left",
                //     value: "node.ean",
                //     sortable: true
                // },
                {
                    text: "Overeenkomstnummer",
                    align: "left",
                    value: "node.agreementNumber",
                    sortable: true
                },
                {
                    text: "Adres",
                    align: "left",
                    value: "node.account.fullAddress",
                    sortable: true
                },
                {
                    text: "Leverancier",
                    value: "node.supplierAccount.name",
                    sortable: true
                },
                {
                    text: "Product",
                    value: "node.energyType",
                    sortable: true
                },
                {
                    text: "Startdatum",
                    value: "node.startDate",
                    sortable: true
                },
                {
                    text: "Einddatum",
                    value: "node.endDate",
                    sortable: true
                },
                { text: "", value: "data-table-expand" }
            ]
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        defaultStartDate() {
            // by default, get last 12 months' invoices
            let d = new Date();
            d.setDate(1);
            d.setMonth(d.getMonth() - 1);

            return d;
        },

        dateRangeText() {
            return this.filter.dates
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },

        contractYears() {
            // initialize taskYear list and selectedYear
            let _thisYear = new Date().getFullYear();

            return Array.from({ length: 10 }, (_, i) => _thisYear + 4 - i);
        },

        energyTypes() {
            return [
                { text: "Elektra", value: "Electricity" },
                { text: "Gas", value: "Gas" }
            ];
        },

        // infinite
        hasMoreData() {
            return this.allContracten?.pageInfo?.hasNextPage;
        }
    },
    watch: {
        filter: {
            deep: true,

            handler() {
                this.refetchDate();
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        // set current year as default contract year filter
        this.filter.contractYear = new Date().getFullYear();
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        onClearDates() {
            this.dateRange = [];
            this.filter.dates = [];
        },
        // infinite loading trigger
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (
                this.shouldLoadMore &&
                this.allContracten?.pageInfo?.hasNextPage
            ) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.allContracten.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.allContracten?.pageInfo?.hasNextPage) {
                this.$apollo.queries.allContracten.fetchMore({
                    variables: {
                        after: this.allContracten.pageInfo.endCursor
                    }
                });
            }
        }
    }
};
</script>
