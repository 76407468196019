<template>
    <v-container fluid class="pa-0">
        <v-card class="mt-5 pt-3" elevation="3" height="500">
            <v-progress-linear
                :active="$apollo.queries.electricityMeasurementDailys.loading"
                color="primary"
                buffer-value="0"
                indeterminate
                absolute
                top
                height="2"
                class="mx-0 px-0"
            ></v-progress-linear>
            <v-row>
                <v-col
                    cols="0"
                    lg="4"
                    :md="this.propDialog ? '5' : '0'"
                    sm="0"
                    xs="0"
                    class="pb-0 d-lg-flex"
                ></v-col>
                <v-col
                    cols="6"
                    lg="4"
                    :md="this.propDialog ? '3' : '6'"
                    sm="6"
                    class="pb-0"
                >
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                            color="grey darken-2"
                            icon
                            small
                            outlined
                            class="py-1 mr-3"
                            :disabled="yearMonth <= minMonth"
                            @click="addMonths(-1)"
                        >
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn> -->
                        <v-select
                            v-model="selectedPeriod"
                            :items="periodList"
                            label="Periode"
                            item-text="yearPeriodStr"
                            item-value="yearPeriod"
                            return-object
                            hide-details
                            dense
                            class="subtitle-2"
                        >
                        </v-select>
                        <!-- <v-btn
                            color="grey darken-2"
                            icon
                            small
                            outlined
                            class="py-1 ml-3"
                            :disabled="yearMonth >= maxMonth"
                            @click="addMonths(1)"
                        >
                            <v-icon>keyboard_arrow_right</v-icon>
                        </v-btn> -->
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </v-col>
                <v-col
                    cols="6"
                    lg="4"
                    :md="this.propDialog ? '4' : '6'"
                    class="pb-0"
                >
                    <div class="text-right mr-5">
                        <span class="caption" style="color: #E91E63">
                            Hoogste piek (levering):
                            <b>{{ highestPeak }}</b> kWh
                        </span>
                        <br />
                        <span class="caption" style="color: #2071B5">
                            Totaal verbruik: <b>{{ totalUsage }}</b> kWh
                        </span>
                        <br />
                        <span class="caption" style="color: #02c281">
                            Totaal Teruglevering:
                            <b>{{ totalTerugLevering }}</b> kWh
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row class="px-5">
                <v-col cols="12">
                    <VueApexCharts
                        ref="chart"
                        type="line"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                    >
                    </VueApexCharts>
                </v-col>
                <v-col cols="12">
                    <p
                        class="
                            text-center
                            mt-n9
                            grey--text
                            text--darken-1
                            caption
                            pt-3
                        "
                    >
                        <v-icon class="pb-1 mr-1" small>error_outline</v-icon>
                        <span
                            >DISCLAIMER: NIFE is niet verantwoordelijk voor
                            (door partijen geleverde) data en kan hier niet
                            aansprakelijk voor worden gesteld.</span
                        >
                    </p>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    name: "elektriciteit-verbruik-period",
    props: {
        propEan: {
            type: String
        },
        propDialog: {
            type: Boolean,
            default: false
        }
    },
    components: { VueApexCharts },

    apollo: {
        electricityMeasurementDailys: {
            query: gql`
                query electricityMeasurementDailys(
                    $ean: String
                    $datetimeLocalGte: DateTime
                    $datetimeLocalLte: DateTime
                    $orderBy: String
                ) {
                    electricityMeasurementDailys(
                        ean: $ean
                        datetimeLocal_Gte: $datetimeLocalGte
                        datetimeLocal_Lte: $datetimeLocalLte
                        orderBy: $orderBy
                    ) {
                        edges {
                            node {
                                id
                                datetimeLocal
                                value
                                peakValue
                                isConsumption
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    ean: this.ean,
                    datetimeLocalGte: this.selectedPeriod.startDate,
                    datetimeLocalLte: this.selectedPeriod.endDate,
                    orderBy: "datetimeLocal"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            // update: data => data.electricityMeasurementDailys,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.electricityMeasurementDailys) {
                    // initialize leveringSeries
                    var _leveringSeries = {
                        name: "Levering",
                        type: "column",
                        data: []
                    };

                    // initialize leveringSeries
                    var _terugleveringSeries = {
                        name: "Teruglevering",
                        type: "column",
                        data: []
                    };

                    // initlialize peakSeries
                    var _peakSeries = {
                        name: "Piek",
                        type: "line",
                        data: []
                    };

                    // get real data from result
                    let _sortedData = [
                        ...data.electricityMeasurementDailys?.edges
                    ];

                    _sortedData.sort((a, b) =>
                        a.node.datetimeLocal.localeCompare(b.datetimeLocal)
                    );

                    _sortedData.forEach(item => {
                        // replace series value
                        if (item.node.isConsumption) {
                            _leveringSeries.data.push([
                                moment.utc(item.node.datetimeLocal).valueOf(),
                                item.node.value
                            ]);
                            _peakSeries.data.push([
                                moment.utc(item.node.datetimeLocal).valueOf(),
                                item.node.peakValue
                            ]);
                        } else {
                            _terugleveringSeries.data.push([
                                moment.utc(item.node.datetimeLocal).valueOf(),
                                item.node.value
                            ]);
                        }
                    });

                    this.series = [
                        _leveringSeries,
                        _terugleveringSeries,
                        _peakSeries
                    ];

                    let _highestPeak = 0;
                    let _totalUsage = 0;
                    let _totalTerugLevering = 0;

                    if (_peakSeries.data.length > 0) {
                        _highestPeak = Math.max(
                            ..._peakSeries["data"].map(item => item[1])
                        ).toFixed(0);
                    }

                    if (_leveringSeries.data.length > 0) {
                        _totalUsage = _leveringSeries["data"]
                            .map(item => item[1])
                            .reduce((a, b) => a + b, 0)
                            .toFixed(0);
                    }

                    if (_terugleveringSeries.data.length > 0) {
                        _totalTerugLevering = _terugleveringSeries["data"]
                            .map(item => item[1])
                            .reduce((a, b) => a + b, 0)
                            .toFixed(0);
                    }

                    this.highestPeak = helper.numberWithCommas(_highestPeak);
                    this.totalUsage = helper.numberWithCommas(_totalUsage);
                    this.totalTerugLevering = helper.numberWithCommas(
                        _totalTerugLevering
                    );
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            // debounce: 500,
            skip() {
                return !this.ean || !this.selectedPeriod;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            menu: null,

            yearMonth: null,

            selectedPeriod: null,

            series: [],
            highestPeak: 0,
            totalUsage: 0,
            totalTerugLevering: 0,

            chartOptions: {
                chart: {
                    id: "yearly-usage-chart",
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                        dataPointSelection: (event, chartContext, config) => {
                            this.routeDay(event, chartContext, config);
                        }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "datetime",
                    min: null,
                    max: null
                },
                yaxis: [
                    {
                        seriesName: "Levering",
                        showAlways: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#2071B5"
                        },
                        labels: {
                            style: {
                                colors: "#2071B5"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "Levering / Teruglevering (kWh)",
                            style: {
                                color: "#2071B5"
                            }
                        }
                    },
                    {
                        show: false,
                        seriesName: "Levering"
                    },
                    {
                        seriesName: "Peak",
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#E91E63"
                        },
                        labels: {
                            style: {
                                colors: "#E91E63"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "Piek (kWh)",
                            style: {
                                color: "#E91E63"
                            }
                        }
                    }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [1, 1, 2],
                    dashArray: [0, 0, 0]
                },
                markers: {
                    size: [0, 0, 0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#2071B5", "#00E396", "#E91E63"],
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                    offsetX: 0,
                    offsetY: 5
                },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: undefined
                    },
                    y: {
                        show: true,
                        formatter: function(value) {
                            if (value != undefined) {
                                return `${value.toFixed(1)} kWh`;
                            } else {
                                return "";
                            }
                        },
                        title: {
                            formatter: seriesName => seriesName
                        }
                    },
                    marker: {
                        show: true
                    }
                }
            }
        };
    },

    computed: {
        ean() {
            return this.$route.params.ean || this.propEan;
        },
        periodList() {
            // generate Master Production Schedule | MPS for last 5 years
            const _periodList = [];

            for (let i = 0; i < 5; i++) {
                const _year = new Date().getFullYear() - i;

                // generate period list
                for (let p = 13; p >= 1; p--) {
                    const _startDate = moment()
                        .isoWeekYear(_year)
                        .isoWeek(4 * (p - 1) + 1)
                        .startOf("week");
                    let _endDate = moment()
                        .isoWeekYear(_year)
                        .isoWeek(4 * (p - 1) + 4)
                        .endOf("week");

                    // if a year has 53 weeks, put the 53rd week to period 13
                    if (p === 13 && _endDate < moment(`${_year}-12-31`)) {
                        _endDate = moment()
                            .isoWeekYear(_year)
                            .isoWeek(4 * (p - 1) + 5)
                            .endOf("week");
                    }

                    // geneerate period range start from 2020 till current period
                    if (_year >= 2020 && _startDate < moment()) {
                        _periodList.push({
                            yearPeriod: _year * 100 + p,
                            yearPeriodStr: `${_year} periode ${p}`,
                            year: _year,
                            periodNumber: p,
                            startDate: _startDate.format("YYYY-MM-DDT00:00:00"),
                            endDate: _endDate.format("YYYY-MM-DDT23:59:59")
                        });
                    }
                }
            }

            return _periodList;
        }
    },
    watch: {
        selectedPeriod(val) {
            if (val) {
                // save selected period to local storage
                localStorage.measurementFilterPeriod = JSON.stringify(val);

                // set chart xaxis range
                this.chartOptions = {
                    ...this.chartOptions,
                    xaxis: {
                        ...this.chartOptions.xaxis,
                        min: new Date(val.startDate).getTime(),
                        max: new Date(val.endDate).getTime()
                    }
                };

                // clear chart data
                this.series = [];
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.initializeSelectedPeriod();
    },
    methods: {
        initializeSelectedPeriod() {
            if (localStorage.measurementFilterPeriod) {
                this.selectedPeriod = JSON.parse(
                    localStorage.measurementFilterPeriod
                );
            } else {
                // set current month as default filter
                this.selectedPeriod = this.periodList[0];
            }
        },
        routeDay(event, chartContext, config) {
            let x = this.$refs.chart.series[0].data[config.dataPointIndex];
            let timeStamp = x[0];
            let date = new Date(timeStamp);
            let _year = date.getFullYear();
            let _month = date.getMonth() + 1;
            let _day = date.getDate();

            if (
                this.$route.query.year == _year &&
                this.$route.query.month == _month &&
                this.$route.query.day == _day
            ) {
                return;
            }

            this.$router.push({
                query: {
                    year: _year,
                    month: _month,
                    day: _day
                }
            });
        }
    }
};
</script>
