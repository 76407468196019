var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%","elevation":"3"}},[(_vm.account.isMainAccount)?_c('v-card',{class:'mt-n5 ml-8 pink',attrs:{"width":"240"}},[_c('v-card-title',{staticClass:"py-1 white--text justify-center font-weight-medium"},[_vm._v(" Correspondentieadres ")])],1):_c('v-card',{class:_vm.account.customerTypeCode == '603540000'
                ? 'mt-n5 ml-8 grey'
                : 'mt-n5 ml-8 primary',attrs:{"width":"140"}},[_c('v-card-title',{staticClass:"py-1 white--text justify-center font-weight-medium"},[_c('a',{staticClass:"white--text",attrs:{"text":""},on:{"click":function($event){return _vm.goToEnergyInvoicesByAddress(_vm.account.fullAddress)}}},[_vm._v(" "+_vm._s(_vm.account.dynamicsNifenummer)+" ")])])],1),_c('v-card-text',{staticClass:"mt-2 px-5 px-sm-10 py-5"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.account.name))]),_c('p',{staticClass:"text-h6 black--text"},[_c('v-icon',{staticClass:"mb-1 mr-2"},[_vm._v("business")]),_vm._v(" "+_vm._s(_vm.account.fullAddress)+" ")],1),(!_vm.account.isMainAccount)?_c('v-row',{staticClass:"mt-5 mx-0 px-0"},_vm._l((_vm.account.eanElectricitySet.edges),function(e){return _c('v-col',{key:e.node.id,staticClass:"py-0 px-0",attrs:{"cols":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-list-item',{staticClass:"px-0 mb-5"},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v("bolt")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(
                                                    _vm.me.isTenantAdmin ||
                                                        _vm.hasPermission(
                                                            'energy.view_energyinvoice'
                                                        )
                                                )?_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.goToEnergyInvoicesByEAN(
                                                        e.node.ean
                                                    )}}},'v-chip',attrs,false),on),[_vm._v(_vm._s(e.node.ean))]):_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(e.node.ean))])]}}],null,true)},[_c('span',[_vm._v("EAN")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("signal_cellular_alt")]),_vm._v(_vm._s(e.node.meterType))],1)]}}],null,true)},[_c('span',[_vm._v("Metertype")])])],1),(
                                        e.node.kvOrGv == 'GV' &&
                                            e.node.myMeasurementData ==
                                                'Ja' &&
                                            !!e.node.measurementCompany
                                    )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1",attrs:{"small":"","left":"","color":"primary"}},[_vm._v("check_circle")]),(
                                            _vm.me.isTenantAdmin ||
                                                _vm.hasPermission(
                                                    'energy.view_electricitymeasurement'
                                                )
                                        )?_c('a',{attrs:{"text":""},on:{"click":function($event){return _vm.goToElectricityMeasurements(
                                                e.node.ean
                                            )}}},[_vm._v("Meetdata")]):_c('span',[_vm._v("Meetdata")])],1):(e.node.kvOrGv == 'GV')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1",attrs:{"small":"","left":""}},[_vm._v("info")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Meetdata aanvragen")])]}}],null,true)},[_c('span',[_vm._v("Meetdata beschikbaar, neem contact op met NIFE")])])],1):(
                                        e.node.kvOrGv == 'KV' &&
                                            e.node.myMeasurementData ==
                                                'Ja' &&
                                            !!e.node.measurementCompany
                                    )?_c('v-col',{staticClass:"grey--text text-truncate",attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1",attrs:{"small":"","left":"","color":"primary"}},[_vm._v("check_circle")]),(
                                            _vm.me.isTenantAdmin ||
                                                _vm.hasPermission(
                                                    'energy.view_electricitymeasurement'
                                                )
                                        )?_c('a',{attrs:{"text":""},on:{"click":function($event){return _vm.goToElectricityMeasurements(
                                                e.node.ean
                                            )}}},[_vm._v("Meetdata")]):_c('span',[_vm._v("Meetdata")])],1):_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1 grey--text text--lighten-1",attrs:{"small":"","left":""}},[_vm._v("cancel")]),_c('span',{staticClass:"grey--text"},[_vm._v("Geen meetdata beschikbaar")])],1)],1)],1)],1)],1)],1)}),1):_vm._e(),(!_vm.account.isMainAccount)?_c('v-row',{staticClass:"mb-5 mx-0 px-0"},_vm._l((_vm.account.eanGasSet.edges),function(e){return _c('v-col',{key:e.node.id,staticClass:"py-0 px-0",attrs:{"cols":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-list-item',{staticClass:"px-0 mb-5"},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning"}},[_vm._v("local_fire_department")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                            var on = ref.on;
                                            var attrs = ref.attrs;
return [(
                                                    _vm.me.isTenantAdmin ||
                                                        _vm.hasPermission(
                                                            'energy.view_energyinvoice'
                                                        )
                                                )?_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.goToEnergyInvoicesByEAN(
                                                        e.node.ean
                                                    )}}},'v-chip',attrs,false),on),[_vm._v(_vm._s(e.node.ean))]):_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(e.node.ean))])]}}],null,true)},[_c('span',[_vm._v("EAN")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("signal_cellular_alt")]),_vm._v(_vm._s(e.node.meterType))],1)]}}],null,true)},[_c('span',[_vm._v("Metertype")])])],1),(
                                        e.node.kvOrGv == 'GV' &&
                                            e.node.myMeasurementData ==
                                                'Ja' &&
                                            !!e.node.measurementCompany
                                    )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1",attrs:{"small":"","left":"","color":"primary"}},[_vm._v("check_circle")]),(
                                            _vm.me.isTenantAdmin ||
                                                _vm.hasPermission(
                                                    'energy.view_electricitymeasurement'
                                                )
                                        )?_c('a',{attrs:{"text":""},on:{"click":function($event){return _vm.goToGasMeasurements(e.node.ean)}}},[_vm._v("Meetdata")]):_c('span',[_vm._v("Meetdata")])],1):(e.node.kvOrGv == 'GV')?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1",attrs:{"small":"","left":""}},[_vm._v("info")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Meetdata aanvragen")])]}}],null,true)},[_c('span',[_vm._v("Meetdata beschikbaar, neem contact op met NIFE")])])],1):(
                                        e.node.kvOrGv == 'KV' &&
                                            e.node.myMeasurementData ==
                                                'Ja' &&
                                            !!e.node.measurementCompany
                                    )?_c('v-col',{staticClass:"grey--text text-truncate",attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1",attrs:{"small":"","left":"","color":"primary"}},[_vm._v("check_circle")]),(
                                            _vm.me.isTenantAdmin ||
                                                _vm.hasPermission(
                                                    'energy.view_electricitymeasurement'
                                                )
                                        )?_c('a',{attrs:{"text":""},on:{"click":function($event){return _vm.goToElectricityMeasurements(
                                                e.node.ean
                                            )}}},[_vm._v("Meetdata")]):_c('span',[_vm._v("Meetdata")])],1):_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"12","xl":"6"}},[_c('v-icon',{staticClass:"ml-6 mb-1 grey--text text--lighten-1",attrs:{"small":"","left":""}},[_vm._v("cancel")]),_c('span',{staticClass:"grey--text"},[_vm._v("Geen meetdata beschikbaar")])],1)],1)],1)],1)],1)],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }