<template>
    <v-container fluid class="pa-0">
        <v-card class="mt-0 pt-3" elevation="3" height="360">
            <v-progress-linear
                :active="$apollo.queries.marketPrices.loading"
                color="primary"
                buffer-value="0"
                indeterminate
                absolute
                top
                height="2"
                class="mx-0 px-0"
            ></v-progress-linear>
            <v-card-title class="justify-center">
                <h3>
                    LEBA
                </h3>
                <v-btn
                    absolute
                    right
                    icon
                    color="grey lighten-1"
                    class="mt-n10 mr-n3"
                    @click="dialog = !dialog"
                    ><v-icon>open_in_full</v-icon></v-btn
                >
            </v-card-title>

            <v-card-text>
                <VueApexCharts
                    ref="chart"
                    type="line"
                    height="240"
                    :options="chartOptions"
                    :series="series"
                ></VueApexCharts>
            </v-card-text>
        </v-card>

        <!-- large chart -->
        <v-dialog v-model="dialog" width="80%">
            <v-card class="overflow-hidden">
                <v-progress-linear
                    :active="$apollo.queries.longMarketPrices.loading"
                    color="primary"
                    buffer-value="0"
                    indeterminate
                    absolute
                    top
                    height="3"
                    class="mx-0 px-0"
                ></v-progress-linear>
                <v-card-title class="justify-center">
                    <h3>
                        LEBA
                    </h3>
                    <v-btn
                        absolute
                        right
                        icon
                        color="grey"
                        @click="dialog = !dialog"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <VueApexCharts
                        ref="chart"
                        type="line"
                        height="600"
                        :options="chartOptions"
                        :series="longSeries"
                    ></VueApexCharts>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    name: "leba-chart",
    props: [],
    components: { VueApexCharts },

    apollo: {
        marketPrices: {
            query: gql`
                query marketPrices(
                    $priceTypeIexact: String
                    $priceDateGte: Date
                    $priceDateLte: Date
                    $orderBy: String
                    $subTypeIexact: String
                    $graphLineIexact: String
                ) {
                    marketPrices(
                        priceType_Iexact: $priceTypeIexact
                        priceDate_Gte: $priceDateGte
                        priceDate_Lte: $priceDateLte
                        orderBy: $orderBy
                        subType_Iexact: $subTypeIexact
                        graphLine_Iexact: $graphLineIexact
                    ) {
                        edges {
                            node {
                                id
                                subType
                                graphLine
                                priceDate
                                priceValue
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    priceTypeIexact: "Leba",
                    priceDateGte: this.startDate,
                    priceDateLte: this.endDate,
                    orderBy: "priceDate",
                    subTypeIexact: null,
                    graphLineIexact: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            // update: data => data.electricityMeasurementHourlys,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.marketPrices) {
                    // initialize series
                    var _lebaSeries = {
                        name: "Dagmarkt",
                        type: "line",
                        data: data?.marketPrices.edges.map(edge => {
                            return {
                                x: moment(edge.node.priceDate).format(
                                    "YYYY-MM-DD"
                                ),
                                y: edge.node.priceValue
                            };
                        })
                    };
                    this.series = [_lebaSeries];
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return false;
            },
            debounce: 500
            // pollInterval: 60000 // ms
        },
        longMarketPrices: {
            query: gql`
                query marketPrices(
                    $priceTypeIexact: String
                    $priceDateGte: Date
                    $priceDateLte: Date
                    $orderBy: String
                    $subTypeIexact: String
                    $graphLineIexact: String
                ) {
                    marketPrices(
                        priceType_Iexact: $priceTypeIexact
                        priceDate_Gte: $priceDateGte
                        priceDate_Lte: $priceDateLte
                        orderBy: $orderBy
                        subType_Iexact: $subTypeIexact
                        graphLine_Iexact: $graphLineIexact
                    ) {
                        edges {
                            node {
                                id
                                subType
                                graphLine
                                priceDate
                                priceValue
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    priceTypeIexact: "Leba",
                    priceDateGte: this.longStartDate,
                    priceDateLte: this.longEndDate,
                    orderBy: "priceDate",
                    subTypeIexact: null,
                    graphLineIexact: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.marketPrices,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.marketPrices) {
                    // initialize series
                    var _lebaSeries = {
                        name: "Dagmarkt",
                        type: "line",
                        data: data?.marketPrices.edges.map(edge => {
                            return {
                                x: moment(edge.node.priceDate).format(
                                    "YYYY-MM-DD"
                                ),
                                y: edge.node.priceValue
                            };
                        })
                    };
                    this.longSeries = [_lebaSeries];
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.dialog;
            },
            debounce: 500
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            dialog: false,

            series: [],
            longSeries: [],

            startDate: null,
            endDate: null,
            longStartDate: null,
            longEndDate: null,

            chartOptions: {
                chart: {
                    id: "chart",
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "datetime",
                    labels: {
                        datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM",
                            day: "dd MMM",
                            hour: "HH:mm"
                        }
                    }
                },
                yaxis: [
                    {
                        seriesName: "Dagmarkt",
                        showAlways: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#2071B5"
                        },
                        labels: {
                            style: {
                                colors: "#2071B5"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "Dagmarkt (€cent / m3)",
                            style: {
                                color: "#2071B5"
                            }
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2],
                    dashArray: [0]
                },
                markers: {
                    size: [0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#E91E63"],
                // legend: {
                //     position: "bottom",
                //     horizontalAlign: "center",
                //     offsetX: 0,
                //     offsetY: 5
                // },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function(value) {
                            return moment(value).format("DD-MM-YYYY");
                        }
                    },
                    y: {
                        show: true,
                        formatter: function(value) {
                            return `${value.toFixed(2)} €cent/m3`;
                        }
                        // title: {
                        //     formatter: seriesName => seriesName
                        // }
                    },
                    marker: {
                        show: true
                    }
                }
            }
        };
    },

    computed: {},
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.initializeDate();
    },
    methods: {
        initializeDate() {
            // // set date filter
            // let _yesterday = new Date();
            // _yesterday.setDate(_yesterday.getDate() - 1);
            // let _dt = _yesterday.toISOString().substring(0, 10);
            this.startDate = moment()
                .add(-2, "month")
                .format("YYYY-MM-DD");
            this.endDate = moment().format("YYYY-MM-DD");
            this.longStartDate = moment()
                .add(-3, "year")
                .format("YYYY-MM-DD");
            this.longEndDate = moment().format("YYYY-MM-DD");
        }
    }
};
</script>

