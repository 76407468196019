<template>
    <v-container fluid class="pa-0">
        <v-card class="mt-5 pt-3" elevation="3" height="500">
            <v-progress-linear
                :active="$apollo.queries.gasMeasurementMonthlys.loading"
                color="primary"
                buffer-value="0"
                indeterminate
                absolute
                top
                height="2"
                class="mx-0 px-0"
            ></v-progress-linear>
            <v-row>
                <v-col
                    cols="0"
                    lg="4"
                    :md="this.propDialog ? '5' : '0'"
                    sm="0"
                    xs="0"
                    class="pb-0 d-lg-flex"
                ></v-col>
                <v-col
                    cols="6"
                    lg="4"
                    :md="this.propDialog ? '1' : '6'"
                    sm="6"
                    class="pb-0"
                >
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="grey darken-2"
                            icon
                            small
                            outlined
                            class="py-1 mr-3"
                            :disabled="year <= Math.min(...yearList)"
                            @click="addYears(-1)"
                        >
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                        <v-select
                            v-model="year"
                            :items="yearList"
                            label="Jaar"
                            class="subtitle-2 shrink"
                            flat
                            hide-details
                            style="max-width: 148px"
                        >
                        </v-select>
                        <v-btn
                            color="grey darken-2"
                            icon
                            small
                            outlined
                            class="py-1 ml-3"
                            :disabled="year >= Math.max(...yearList)"
                            @click="addYears(1)"
                        >
                            <v-icon>keyboard_arrow_right</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </v-col>
                <v-col cols="6" lg="4" class="pb-0">
                    <div class="text-right mr-5">
                        <span class="caption" style="color: #e91e63">
                            Hoogste piek (levering):
                            <b>{{ highestPeak }}</b> m3
                        </span>
                        <br />
                        <span class="caption" style="color: #2071b5">
                            Totaal verbruik: <b>{{ totalUsage }}</b> m3
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row class="px-5">
                <v-col cols="12">
                    <VueApexCharts
                        ref="chart"
                        type="line"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                    ></VueApexCharts>
                </v-col>
                <v-col cols="12">
                    <p
                        class="
                            text-center
                            mt-n9
                            grey--text
                            text--darken-1
                            caption
                            pt-2
                        "
                    >
                        <v-icon class="pb-1 mr-1" small>error_outline</v-icon
                        ><span
                            >DISCLAIMER: Deze verbruiken zijn
                            ongecorrigeerd.</span
                        ><br />
                        <span
                            >NIFE is niet verantwoordelijk voor (door partijen
                            geleverde) data en kan hier niet aansprakelijk voor
                            worden gesteld.</span
                        >
                    </p>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import VueApexCharts from "vue-apexcharts";

export default {
    name: "gas-verbruik-jaar",
    props: {
        propEan: {
            type: String
        },
        propDialog: {
            type: Boolean,
            default: false
        }
    },
    components: { VueApexCharts },

    apollo: {
        gasMeasurementMonthlys: {
            query: gql`
                query gasMeasurementMonthlys(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $ean: String
                    $datetimeLocalGte: DateTime
                    $datetimeLocalLte: DateTime
                    $orderBy: String
                ) {
                    gasMeasurementMonthlys(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        ean: $ean
                        datetimeLocal_Gte: $datetimeLocalGte
                        datetimeLocal_Lte: $datetimeLocalLte
                        orderBy: $orderBy
                    ) {
                        totalCount
                        edges {
                            node {
                                id
                                isConsumption
                                datetimeLocal
                                value
                                peakValue
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    ean: this.ean,
                    datetimeLocalGte: this.startDate,
                    datetimeLocalLte: this.endDate
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            // update: data => data.gasMeasurementMonthlys,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.gasMeasurementMonthlys) {
                    // initialize leveringSeries
                    var _leveringSeries = {
                        name: "Levering",
                        type: "column",
                        data: Array.from(
                            {
                                length: this.chartOptions.xaxis.categories
                                    .length
                            },
                            () => 0
                        )
                    };

                    // // initialize leveringSeries
                    // var _terugleveringSeries = {
                    //     name: "Teruglevering",
                    //     type: "column",
                    //     data: Array.from(
                    //         {
                    //             length: this.chartOptions.xaxis.categories
                    //                 .length
                    //         },
                    //         () => 0
                    //     )
                    // };

                    // initlialize peakSeries
                    var _peakSeries = {
                        name: "Piek",
                        type: "line",
                        data: Array.from(
                            {
                                length: this.chartOptions.xaxis.categories
                                    .length
                            },
                            () => 0
                        )
                    };

                    // get real data from result
                    let _sortedData = [...data.gasMeasurementMonthlys?.edges];

                    _sortedData.sort((a, b) =>
                        a.node.datetimeLocal.localeCompare(b.datetimeLocal)
                    );

                    _sortedData.forEach(item => {
                        // use month number as series index
                        const _index =
                            parseInt(item.node.datetimeLocal.substring(5, 7)) -
                            1;

                        // replace series value
                        if (item.node.isConsumption) {
                            _leveringSeries["data"][_index] = item.node.value;
                            _peakSeries["data"][_index] = item.node.peakValue;
                        } else {
                            // _terugleveringSeries["data"][_index] =
                            //     item.node.value;
                        }
                    });

                    this.series = [
                        _leveringSeries,
                        // _terugleveringSeries,
                        _peakSeries
                    ];

                    const _highestPeak = Math.max(
                        ..._peakSeries["data"]
                    ).toFixed(0);
                    const _totalUsage = _leveringSeries["data"]
                        .reduce((a, b) => a + b, 0)
                        .toFixed(0);

                    this.highestPeak = helper.numberWithCommas(_highestPeak);
                    this.totalUsage = helper.numberWithCommas(_totalUsage);
                    // update chart
                    // this.$refs?.chart?.updateSeries(this.series);
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            // debounce: 500,
            skip() {
                return !this.ean;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            year: null,

            series: [],
            highestPeak: 0,
            totalUsage: 0,

            chartOptions: {
                chart: {
                    id: "yearly-usage-chart",
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                        dataPointSelection: (event, chartContext, config) => {
                            this.routeToMonth(event, chartContext, config);
                        }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "category",
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "Mei",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Okt",
                        "Nov",
                        "Dec"
                    ]
                    // categories: [
                    //     "Januari",
                    //     "Februari",
                    //     "Maart",
                    //     "April",
                    //     "Mei",
                    //     "Juni",
                    //     "Juli",
                    //     "Augustus",
                    //     "September",
                    //     "Oktober",
                    //     "November",
                    //     "December"
                    // ]
                },
                yaxis: [
                    {
                        seriesName: "Levering",
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#2071B5"
                        },
                        labels: {
                            style: {
                                colors: "#2071B5"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "Levering (m3)",
                            style: {
                                color: "#2071B5"
                            }
                        }
                    },
                    // {
                    //     show: false,
                    //     labels: {
                    //         style: {
                    //             colors: "#2071B5"
                    //         },
                    //         formatter: function(val) {
                    //             return val.toFixed(1);
                    //         }
                    //     },
                    // },
                    {
                        seriesName: "Piek",
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#E91E63"
                        },
                        labels: {
                            style: {
                                colors: "#E91E63"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "Piek (m3)",
                            style: {
                                color: "#E91E63"
                            }
                        }
                    }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [1, 2],
                    dashArray: [0, 0]
                },
                markers: {
                    size: [0, 0, 0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#2071B5", "#E91E63"],
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                    offsetX: 0,
                    offsetY: 5
                },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                },
                tooltip: {
                    tooltip: {
                        x: {
                            show: true,
                            formatter: undefined
                        },
                        y: {
                            show: true,
                            formatter: function(value) {
                                return `${value.toFixed(1)} m3`;
                            },
                            title: {
                                formatter: seriesName => seriesName
                            }
                        },
                        marker: {
                            show: true
                        }
                    }
                }
            }
        };
    },

    computed: {
        ean() {
            return this.$route.params.ean || this.propEan;
        },

        yearList() {
            // initialize taskYear list and selectedYear
            let _thisYear = new Date().getFullYear();

            return Array.from({ length: 5 }, (_, i) => _thisYear - i);
        },

        startDate() {
            if (this.year) {
                return `${this.year}-01-01T00:00:00`;
            } else {
                let _dt = new Date();
                return `${_dt.getFullYear()}-01-01T00:00:00`;
            }
        },
        endDate() {
            if (this.year) {
                return `${this.year}-12-31T23:59:59`;
            } else {
                let _dt = new Date();
                return `${_dt.getFullYear()}-12-31T23:59:59`;
            }
        }
    },
    watch: {
        year(val) {
            localStorage.measurementFilterYear = val;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.initializeYear();
    },
    methods: {
        initializeYear() {
            if (localStorage.measurementFilterYear) {
                this.year = parseInt(localStorage.measurementFilterYear);
            } else {
                // set current year as default year filter
                this.year = new Date().getFullYear();
            }
        },
        addYears(years) {
            this.year = this.year + years;
        },
        routeToMonth(event, chartContext, config) {
            let _month = config.dataPointIndex + 1;
            let _year = this.year;

            if (
                this.$route.query.year == _year &&
                this.$route.query.month == _month
            ) {
                return;
            }

            this.$router.push({
                query: {
                    year: _year,
                    month: _month
                }
            });
        }
    }
};
</script>
