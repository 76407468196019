<template>
    <!-- Notifications -->
    <v-menu
        v-model="isNotificationCenterOpen"
        offset-y
        bottom
        left
        :close-on-content-click="false"
        origin="center center"
        :nudge-bottom="10"
    >
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="mr-0">
                <v-badge
                    :content="unreadNofiticationCount"
                    :value="unreadNofiticationCount"
                    color="warning"
                    overlap
                >
                    <v-icon>notifications</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-card class="elevation-0" style="width: 450px">
            <v-card-title class="pb-0">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            :color="
                                isUnreadNotifiocationFilterEnabled
                                    ? 'primary'
                                    : 'grey'
                            "
                            v-on="on"
                            :disabled="$apollo.queries.notifications.loading"
                            @click="filterNotifications()"
                            ><v-icon>filter_list</v-icon></v-btn
                        >
                    </template>
                    <span>{{
                        isUnreadNotifiocationFilterEnabled
                            ? "Show all"
                            : "Show unread"
                    }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-on="on"
                            :disabled="
                                unreadNofiticationCount == 0 ||
                                    isMarkingAllNotificationsAsRead
                            "
                            @click="markAllNotificationsAsRead()"
                            ><v-icon>drafts</v-icon></v-btn
                        >
                    </template>
                    <span>Mark all read</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-virtual-scroll
                    :items="notifications ? notifications.edges : []"
                    :item-height="80"
                    height="400"
                >
                    <template v-slot:default="{ index, item }">
                        <v-list-item three-line dense>
                            <v-list-item-icon class="ma-4">
                                <v-icon
                                    small
                                    :color="
                                        item.node.isRead
                                            ? 'grey'
                                            : item.node.color || 'primary'
                                    "
                                >
                                    {{
                                        item.node.icon || "fiber_manual_record"
                                    }}
                                </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content
                                class="row-pointer"
                                @click="goToNotificationUrl(item.node.url)"
                            >
                                <v-list-item-title
                                    class="subtitle-2"
                                    v-text="item.node.subject"
                                ></v-list-item-title>

                                <v-list-item-subtitle>{{
                                    item.node.created | moment("from")
                                }}</v-list-item-subtitle>

                                <v-list-item-title
                                    v-html="item.node.content"
                                ></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            :disabled="item.node.isRead"
                                            @click="
                                                markNotificationAsRead(
                                                    item.node.id
                                                )
                                            "
                                            ><v-icon>drafts</v-icon></v-btn
                                        >
                                    </template>
                                    <span>Mark read</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>

                        <!-- infinit loading trigger -->
                        <v-card
                            tile
                            v-if="index == notifications.edges.length - 1"
                            v-intersect="loadMoreNotifications"
                            class="elevation-0"
                        >
                            <v-card-text
                                v-if="$apollo.queries.notifications.loading"
                                class="text-center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-virtual-scroll>
            </v-card-text>

            <v-progress-linear
                :active="$apollo.queries.notifications.loading"
                :indeterminate="$apollo.queries.notifications.loading"
                absolute
                bottom
                color="primary"
                height="3"
            ></v-progress-linear>
        </v-card>
    </v-menu>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "notifications",
    components: {},

    apollo: {
        unreadNotifications: {
            query: gql`
                query unreadNotifications {
                    unreadNotifications {
                        totalCount
                    }
                }
            `,
            variables() {
                return {};
            },
            fetchPolicy: "cache-and-network",
            update: data => data.unreadNotifications,
            skip() {
                return !this.isLoggedIn;
            },
            pollInterval: 60000 // ms
        },
        notifications: {
            query: gql`
                query notifications(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $orderBy: String
                    $isRead: Boolean
                    $search: String #this variable name must by $search
                ) {
                    notifications(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        search: $search
                        isRead: $isRead
                        orderBy: $orderBy
                    ) {
                        edgeCount
                        totalCount
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                created
                                notificationType
                                subject
                                content
                                icon
                                color
                                url
                                isRead
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: 10,
                    search: this.search,
                    isRead: this.isUnreadNotifiocationFilterEnabled
                        ? false
                        : null
                };
            },
            fetchPolicy: "cache-and-network",
            update: data => data.notifications,
            skip() {
                return !this.isLoggedIn || !this.isNotificationCenterOpen;
            }
        }
    },

    data() {
        return {
            isNotificationCenterOpen: false,
            isMarkingAllNotificationsAsRead: false,
            isUnreadNotifiocationFilterEnabled: true,
            notifications: { edges: [] }
        };
    },

    computed: {
        isLoggedIn() {
            return this.$store.state.user.loggedIn;
        },
        today() {
            var _today = new Date();
            _today.setHours(23, 59, 59, 59); // remove time

            return _today;
        },
        me() {
            return this.$store.state.user.me || {};
        },
        unreadNofiticationCount() {
            return this.unreadNotifications
                ? this.unreadNotifications.totalCount
                : 0;
        }
    },
    watch: {
        isNotificationCenterOpen(val) {
            if (val) {
                this.fetchUnreadNotificationsCount();
            }
        }
    },

    created() {
        // copy hasPermission from helper
        this.hasPermission = helper.hasPermission;
    },
    mounted() {},
    methods: {
        fetchUnreadNotificationsCount() {
            this.$apollo.queries.unreadNotifications.refresh();
        },

        fetchNotifications() {
            this.$apollo.queries.notifications.setVariables({
                first: 10,
                isRead: this.isUnreadNotifiocationFilterEnabled ? false : null
            });
            this.$apollo.queries.notifications.refresh();
        },

        filterNotifications() {
            this.isUnreadNotifiocationFilterEnabled = !this
                .isUnreadNotifiocationFilterEnabled;

            this.fetchNotifications();
        },

        loadMoreNotifications() {
            if (this.notifications && this.notifications.pageInfo.hasNextPage) {
                this.$apollo.queries.notifications.fetchMore({
                    // New variables
                    variables: {
                        after: this.notifications.pageInfo.endCursor
                    }
                });
            }
        },

        markAllNotificationsAsRead() {
            this.isMarkingAllNotificationsAsRead = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation markAllNotificationsAsRead {
                            markAllNotificationsAsRead(input: {}) {
                                notificationCount
                            }
                        }
                    `,
                    // Parameters
                    variables: {}
                })
                .then(() => {
                    // // Result
                    this.fetchUnreadNotificationsCount();
                    this.fetchNotifications();
                })
                .catch(error => {
                    // Error
                    console.error(error);
                })
                .finally(() => {
                    this.isMarkingAllNotificationsAsRead = false;
                });
        },

        markNotificationAsRead(id) {
            this.isMarkingAllNotificationsAsRead = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation markNotificationAsRead(
                            $input: MarkNotificationAsReadInput!
                        ) {
                            markNotificationAsRead(input: $input) {
                                notification {
                                    id
                                    created
                                    notificationType
                                    subject
                                    content
                                    icon
                                    color
                                    url
                                    isRead
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            id: id
                        }
                    }
                })
                .then(() => {
                    // // Result
                    // this.fetchNotifications();
                    this.fetchUnreadNotificationsCount();
                })
                .catch(error => {
                    // Error
                    console.error(error);
                })
                .finally(() => {
                    this.isMarkingAllNotificationsAsRead = false;
                });
        },

        goToNotificationUrl(url) {
            // redirect to the url from the notifcation
            this.$router.push(url);
        }
    }
};
</script>

