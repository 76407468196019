<template>
    <v-container fluid class="pa-0">
        <v-card class="mt-5 pt-3" elevation="3" height="500">
            <v-progress-linear
                :active="$apollo.queries.gasMeasurementHourlys.loading"
                color="primary"
                buffer-value="0"
                indeterminate
                absolute
                top
                height="2"
                class="mx-0 px-0"
            ></v-progress-linear>
            <v-row>
                <v-col
                    cols="0"
                    lg="4"
                    :md="this.propDialog ? '5' : '0'"
                    sm="0"
                    xs="0"
                    class="pb-0 d-lg-flex"
                ></v-col>
                <v-col
                    cols="6"
                    lg="4"
                    :md="this.propDialog ? '3' : '6'"
                    sm="6"
                    class="pb-0"
                >
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="grey darken-2"
                            icon
                            small
                            outlined
                            class="py-1 mr-3"
                            :disabled="date <= minDate"
                            @click="addDays(-1)"
                        >
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="dateText"
                                    label="Datum"
                                    class="text-subtitle-2 shrink"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date"
                                :first-day-of-week="1"
                                no-title
                                scrollable
                                :min="minDate"
                                :max="maxDate"
                                color="primary"
                                @input="menu = false"
                            >
                            </v-date-picker>
                        </v-menu>
                        <v-btn
                            color="grey darken-2"
                            icon
                            small
                            outlined
                            class="py-1 ml-3"
                            :disabled="date >= maxDate"
                            @click="addDays(1)"
                        >
                            <v-icon>keyboard_arrow_right</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </v-col>
                <v-col
                    cols="6"
                    lg="4"
                    :md="this.propDialog ? '4' : '6'"
                    class="pb-0"
                >
                    <div class="text-right mr-5">
                        <span class="caption" style="color: #E91E63">
                            Hoogste piek (levering):
                            <b>{{ highestPeak }}</b> m3
                        </span>
                        <br />
                        <span class="caption" style="color: #2071B5">
                            Totaal verbruik: <b>{{ totalUsage }}</b> m3
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row class="px-5">
                <v-col cols="12">
                    <VueApexCharts
                        ref="chart"
                        type="line"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                    ></VueApexCharts>
                </v-col>
                <v-col cols="12">
                    <p
                        class="text-center mt-n9 grey--text text--darken-1 caption pt-2"
                    >
                        <v-icon class="pb-1 mr-1" small>error_outline</v-icon
                        ><span
                            >DISCLAIMER: Deze verbruiken zijn
                            ongecorrigeerd.</span
                        ><br />
                        <span
                            >NIFE is niet verantwoordelijk voor (door partijen
                            geleverde) data en kan hier niet aansprakelijk voor
                            worden gesteld.</span
                        >
                    </p>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    name: "gas-verbruik-dag",
    props: {
        propEan: {
            type: String
        },
        propDialog: {
            type: Boolean,
            default: false
        }
    },
    components: { VueApexCharts },

    apollo: {
        gasMeasurementHourlys: {
            query: gql`
                query gasMeasurementHourlys(
                    $ean: String
                    $datetimeLocalGte: DateTime
                    $datetimeLocalLte: DateTime
                ) {
                    gasMeasurementHourlys(
                        ean: $ean
                        datetimeLocal_Gte: $datetimeLocalGte
                        datetimeLocal_Lte: $datetimeLocalLte
                    ) {
                        edges {
                            node {
                                id
                                datetimeLocal
                                value
                                peakValue
                                isConsumption
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    ean: this.ean,
                    datetimeLocalGte: this.startDate,
                    datetimeLocalLte: this.endDate
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            // update: data => data.gasMeasurementHourlys,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.gasMeasurementHourlys) {
                    // initialize leveringSeries
                    var _leveringSeries = {
                        name: "Levering",
                        type: "column",
                        data: Array.from(
                            {
                                length: this.chartOptions.xaxis.categories
                                    .length
                            },
                            () => 0
                        )
                    };

                    // // initialize leveringSeries
                    // var _terugleveringSeries = {
                    //     name: "Teruglevering",
                    //     type: "column",
                    //     data: Array.from(
                    //         {
                    //             length: this.chartOptions.xaxis.categories
                    //                 .length
                    //         },
                    //         () => 0
                    //     )
                    // };

                    // initlialize peakSeries
                    var _peakSeries = {
                        name: "Piek",
                        type: "line",
                        data: Array.from(
                            {
                                length: this.chartOptions.xaxis.categories
                                    .length
                            },
                            () => 0
                        )
                    };

                    // get real data from result
                    let _sortedData = [...data.gasMeasurementHourlys?.edges];

                    _sortedData.sort((a, b) =>
                        a.node.datetimeLocal.localeCompare(b.datetimeLocal)
                    );

                    _sortedData.forEach(item => {
                        // use hour as series index
                        const _index = parseInt(
                            item.node.datetimeLocal.substring(11, 13)
                        );

                        // replace series value
                        if (item.node.isConsumption) {
                            _leveringSeries["data"][_index] = item.node.value;
                            _peakSeries["data"][_index] = item.node.peakValue;
                        } else {
                            // _terugleveringSeries["data"][_index] =
                            //     item.node.value;
                        }
                    });

                    this.series = [
                        _leveringSeries,
                        // _terugleveringSeries,
                        _peakSeries
                    ];

                    const _highestPeak = Math.max(
                        ..._peakSeries["data"]
                    ).toFixed(0);
                    const _totalUsage = _leveringSeries["data"]
                        .reduce((a, b) => a + b, 0)
                        .toFixed(0);

                    this.highestPeak = helper.numberWithCommas(_highestPeak);
                    this.totalUsage = helper.numberWithCommas(_totalUsage);
                    // update chart
                    // this.$refs?.chart?.updateSeries(this.series);
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            // debounce: 500,
            skip() {
                return !this.ean;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            menu: null,

            date: null,

            series: [],
            highestPeak: 0,
            totalUsage: 0,

            chartOptions: {
                chart: {
                    id: "yearly-usage-chart",
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "category",
                    categories: [
                        0,
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20,
                        21,
                        22,
                        23
                    ]
                    // categories: [
                    //     "Januari",
                    //     "Februari",
                    //     "Maart",
                    //     "April",
                    //     "Mei",
                    //     "Juni",
                    //     "Juli",
                    //     "Augustus",
                    //     "September",
                    //     "Oktober",
                    //     "November",
                    //     "December"
                    // ]
                },
                yaxis: [
                    {
                        seriesName: "Levering",
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#2071B5"
                        },
                        labels: {
                            style: {
                                colors: "#2071B5"
                            },
                            formatter: function(val) {
                                return val.toFixed(1);
                            }
                        },
                        title: {
                            text: "Levering (m3)",
                            style: {
                                color: "#2071B5"
                            }
                        },
                        tooltip: {}
                    },
                    // {
                    //     show: false,
                    //     labels: {
                    //         style: {
                    //             colors: "#2071B5"
                    //         },
                    //         formatter: function(val) {
                    //             return val.toFixed(1);
                    //         }
                    //     }
                    // },
                    {
                        seriesName: "Piek",
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#E91E63"
                        },
                        labels: {
                            style: {
                                colors: "#E91E63"
                            },
                            formatter: function(val) {
                                return val.toFixed(1);
                            }
                        },
                        title: {
                            text: "Piek (m3)",
                            style: {
                                color: "#E91E63"
                            }
                        },
                        tooltip: {}
                    }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [1, 2],
                    dashArray: [0, 0]
                },
                markers: {
                    size: [0, 0, 0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#2071B5", "#E91E63"],
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                    offsetX: 0,
                    offsetY: 5
                },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function(value) {
                            var _hour = (value - 1).toString().padStart(2, "0");
                            return `${_hour}:00`;
                        }
                    },
                    y: {
                        show: true,
                        formatter: function(value) {
                            return `${value.toFixed(1)} m3`;
                        }
                        // title: {
                        //     formatter: seriesName => seriesName
                        // }
                    },
                    marker: {
                        show: true
                    }
                }
            }
        };
    },

    computed: {
        ean() {
            return this.$route.params.ean || this.propEan;
        },
        minDate() {
            return "2020-01-01";
        },
        maxDate() {
            return moment().format("YYYY-MM-DD");
        },
        dateText() {
            return this.formatDate(this.date);
        },
        startDate() {
            let _date = null;
            if (this.date) {
                _date = `${this.date}T00:00:00`;
            } else {
                let _dt = new Date();
                _date = `${_dt.toISOString().substring(0, 10)}T00:00:00`;
            }

            return _date;
        },
        endDate() {
            let _date = null;
            if (this.date) {
                _date = `${this.date}T23:59:59`;
            } else {
                let _dt = new Date();
                _date = `${_dt.toISOString().substring(0, 10)}T23:59:59`;
            }
            return _date;
        }
    },
    watch: {
        date(val) {
            localStorage.measurementFilterDate = val;
        },
        $route(to) {
            if (to.query.year && to.query.month && to.query.day) {
                let _dt = `${
                    to.query.year
                }-${to.query.month
                    .toString()
                    .padStart(2, "0")}-${to.query.day
                    .toString()
                    .padStart(2, "0")}`; // Ensure two-digit day representation
                this.date = _dt;
                this.$router.replace({
                    query: {}
                });
            } else {
                this.initializeDate();
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        if (
            this.$route.query.year &&
            this.$route.query.month &&
            this.$route.query.day
        ) {
            let _dt = `${
                this.$route.query.year
            }-${this.$route.query.month
                .toString()
                .padStart(2, "0")}-${this.$route.query.day
                .toString()
                .padStart(2, "0")}`;
            this.date = _dt;
            this.$router.replace({
                query: {}
            });
        } else {
            this.initializeDate();
        }
    },
    methods: {
        initializeDate() {
            // set date filter
            let _yesterday = new Date();
            _yesterday.setDate(_yesterday.getDate() - 1);

            let _dt = _yesterday.toISOString().substring(0, 10);

            if (localStorage.measurementFilterDate) {
                const _local_dt = moment(localStorage.measurementFilterDate);
                if (_local_dt.isValid()) {
                    _dt = _local_dt.format("YYYY-MM-DD");
                } else {
                    _dt = _yesterday;
                }
            }

            this.date = _dt;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}-${month}-${year}`;
        },
        addDays(days) {
            this.date = moment(this.date, "YYYY-MM-DD")
                .add(days, "days")
                .format("YYYY-MM-DD");
        }
    }
};
</script>
