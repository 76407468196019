<template>
    <!-- Edit profile -->
    <v-dialog v-model="dialog" persistent max-width="960px" scrollable>
        <v-card :loading="$apollo.queries.tenantStaffGroup.loading">
            <v-overlay
                absolute
                :value="$apollo.queries.tenantStaffGroup.loading"
            >
                <div>
                    Een ogenblik geduld aub...
                </div>
            </v-overlay>

            <v-card-title class="primary white--text">
                {{ formTitle }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-alert v-if="error" tile type="error" class="mb-0">{{
                error
            }}</v-alert>

            <v-card-text class="pt-5">
                <v-form v-model="valid" ref="form">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                v-model="editedItem.name"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="Groep naam*"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <!-- permissions -->
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="allPermissionsCheckbox"
                                :indeterminate="
                                    allPermissionsCheckboxIndeterminate
                                "
                                label="Rechten"
                                color="primary"
                                class="ml-3 my-0 text-subtitle-2"
                            ></v-checkbox>
                            <v-card
                                class="overflow-y-auto px-3"
                                max-height="300"
                                elevation="0"
                                outlined
                            >
                                <v-card flat height="300">
                                    <v-checkbox
                                        v-model="selectedPermissions"
                                        v-for="permission in permissionList"
                                        :key="permission.codename"
                                        :label="permission.name"
                                        :value="permission.codename"
                                        hide-details
                                    ></v-checkbox>
                                </v-card>
                            </v-card>
                        </v-col>

                        <!-- accounts -->
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="allAccountsCheckbox"
                                :indeterminate="
                                    allAccountsCheckboxIndeterminate
                                "
                                label="Locaties"
                                color="primary"
                                class="ml-3 my-0 text-subtitle-2"
                            ></v-checkbox>
                            <v-card
                                class="overflow-y-auto px-3"
                                max-height="300"
                                elevation="0"
                                outlined
                                :loading="$apollo.queries.allAccounts.loading"
                            >
                                <v-card flat height="300">
                                    <v-checkbox
                                        v-model="selectedAccounts"
                                        v-for="account in allAccounts.edges"
                                        :key="account.node.id"
                                        :label="account.node.dynamicsNifenummer+': '+account.node.fullAddress"
                                        :value="account.node.id"
                                        hide-details
                                    ></v-checkbox>
                                </v-card>
                            </v-card>
                        </v-col>

                        <!-- staffs -->
                        <v-col cols="12" md="4">
                            <v-checkbox
                                v-model="allStaffsCheckbox"
                                :indeterminate="allStaffsCheckboxIndeterminate"
                                color="primary"
                                class="ml-3 my-0 text-subtitle-2"
                            >
                                <template v-slot:label>
                                    <div>Gebruikers</div>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon right v-bind="attrs" v-on="on"
                                                >help_outline</v-icon
                                            >
                                        </template>
                                        <span
                                            >Beheerder heeft alle rechten, dus
                                            voeg geen beheerder toe aan een
                                            groep.</span
                                        >
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                            <v-card
                                class="overflow-y-auto px-3"
                                max-height="300"
                                elevation="0"
                                outlined
                                :loading="$apollo.queries.allStaffs.loading"
                            >
                                <v-card flat height="300">
                                    <v-checkbox
                                        v-model="selectedStaffs"
                                        v-for="staff in allStaffs.edges"
                                        :key="staff.node.id"
                                        :value="staff.node.id"
                                        :disabled="
                                            staff.node.isAdmin &&
                                                !selectedStaffs.includes(
                                                    staff.node.id
                                                )
                                        "
                                        hide-details
                                    >
                                        <template v-slot:label>
                                            <v-list-item class="pa-0">
                                                <v-list-item-content
                                                    class="pa-0"
                                                >
                                                    <v-list-item-title>
                                                        {{
                                                            staff.node.user
                                                                .firstName
                                                        }}
                                                        {{
                                                            staff.node.user
                                                                .lastName
                                                        }}
                                                        <v-chip
                                                            v-if="
                                                                staff.node
                                                                    .isAdmin
                                                            "
                                                            dark
                                                            small
                                                            color="pink"
                                                            >Beheerder</v-chip
                                                        >
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{
                                                            staff.node.user
                                                                .email
                                                        }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-checkbox>
                                </v-card>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="isSaving"
                    text
                    rounded
                    @click="closeGroupDialog"
                    >Annuleren</v-btn
                >
                <v-btn
                    outlined
                    rounded
                    :disabled="!valid"
                    :loading="isSaving"
                    color="primary"
                    @click="saveGroup"
                    >Opslaan</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "groep-form",
    props: ["dialog", "object"],

    apollo: {
        tenantStaffGroup: {
            query: gql`
                query tenantStaffGroup($tenantStaffGroupId: ID!) {
                    tenantStaffGroup(id: $tenantStaffGroupId) {
                        id
                        name
                        permissions
                        accounts {
                            edges {
                                node {
                                    id
                                    account {
                                        id
                                    }
                                }
                            }
                        }
                        staffs {
                            edges {
                                node {
                                    id
                                    isAdmin
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    tenantStaffGroupId: this.object.id
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantStaffGroup,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data) {
                    this.selectedPermissions =
                        data.tenantStaffGroup.permissions;

                    this.selectedAccounts = [
                        ...data.tenantStaffGroup.accounts.edges.map(item => {
                            return item.node.account.id;
                        })
                    ];

                    this.selectedStaffs = [
                        ...data.tenantStaffGroup.staffs.edges.map(item => {
                            return item.node.id;
                        })
                    ];
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return !this.dialog || !this.object;
            }
            // pollInterval: 60000 // ms
        },
        allAccounts: {
            query: gql`
                query accounts($orderBy: String) {
                    accounts(orderBy: $orderBy) {
                        totalCount
                        edges {
                            node {
                                id
                                name
                                dynamicsNifenummer
                                fullAddress
                            }
                        }
                    }
                }
            `,
            variables() {
                return { orderBy: "dynamicsNifenummer" };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.accounts,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return !this.dialog;
            }
        },
        allStaffs: {
            query: gql`
                query tenantStaffs {
                    tenantStaffs(isAdmin: false) {
                        totalCount
                        edges {
                            node {
                                id
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                }
                                isAdmin
                            }
                        }
                    }
                }
            `,
            variables() {
                return {};
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.tenantStaffs,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return !this.dialog;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            valid: false,
            error: null,
            isSaving: false,
            tenantStaffGroup: null,

            permissionList: [
                // { codename: "view_account", name: "Overzicht" },
                {
                    codename: "energy.view_energyinvoice",
                    name: "Energiefacturen"
                },
                {
                    codename: "energy.view_serviceinvoice",
                    name: "Dienstfacturen"
                },
                {
                    codename: "energy.view_electricitymeasurement",
                    name: "Verbruik"
                },
                {
                    codename: "energy.view_energycontract",
                    name: "Energiecontracten"
                },
                { codename: "energy.view_contact", name: "Gegevens" }
            ],
            selectedPermissions: [],
            allPermissionsCheckbox: false,

            allAccounts: [],
            selectedAccounts: [],
            allAccountsCheckbox: false,

            allStaffs: [],
            selectedStaffs: [],
            allStaffsCheckbox: false,

            editedItem: {
                id: null,
                name: null
            },
            defaultItem: {
                id: null,
                name: null
            },

            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                minLength: len => v =>
                    (v || "").length >= len ||
                    `Invalid character length, required ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
            }
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        isNewUser() {
            return this.object ? false : true;
        },
        formTitle() {
            return this.isNewUser ? "Voeg groep toe" : "Groep bijwerken";
        },
        allPermissionsCheckboxIndeterminate() {
            return (
                this.selectedPermissions.length > 0 &&
                this.selectedPermissions.length < this.permissionList.length
            );
        },
        allAccountsCheckboxIndeterminate() {
            return (
                this.selectedAccounts.length > 0 &&
                this.selectedAccounts.length < this.allAccounts.totalCount
            );
        },
        allStaffsCheckboxIndeterminate() {
            return (
                this.selectedStaffs.length > 0 &&
                this.selectedStaffs.length < this.allStaffs.totalCount
            );
        }
    },
    watch: {
        // reset form when dialog open or close
        dialog() {
            this.resetForm();
        },
        selectedPermissions(val) {
            if (val.length == this.permissionList.length) {
                this.allPermissionsCheckbox = true;
            } else if (val.length == 0) {
                this.allPermissionsCheckbox = false;
            }
        },
        allPermissionsCheckbox(val) {
            if (val) {
                this.selectAllPermissions();
            } else {
                this.deselectAllPermissions();
            }
        },
        selectedAccounts(val) {
            if (val.length == this.allAccounts.totalCount) {
                this.allAccountsCheckbox = true;
            } else if (val.length == 0) {
                this.allAccountsCheckbox = false;
            }
        },
        allAccountsCheckbox(val) {
            if (val) {
                this.selectAllAccounts();
            } else {
                this.deselectAllAccounts();
            }
        },
        selectedStaffs(val) {
            if (val.length == this.allStaffs.totalCount) {
                this.allStaffsCheckbox = true;
            } else if (val.length == 0) {
                this.allStaffsCheckbox = false;
            }
        },
        allStaffsCheckbox(val) {
            if (val) {
                this.selectAllStaffs();
            } else {
                this.deselectAllStaffs();
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    methods: {
        resetForm() {
            // reset form state
            this.error = null;
            this.isSaving = false;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            // reset selections
            this.selectedPermissions = [];
            this.selectedAccounts = [];
            this.selectedStaffs = [];

            // when editing, set form with object
            if (this.dialog && this.object) {
                this.editedItem.id = this.object.id;
                this.editedItem.name = this.object.name;
            }
            // when new or closing, set form with default
            else {
                this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
            }
        },

        selectAllPermissions() {
            this.selectedPermissions = [];
            this.permissionList.forEach(element => {
                this.selectedPermissions.push(element.codename);
            });
        },

        deselectAllPermissions() {
            this.selectedPermissions = [];
        },

        selectAllAccounts() {
            this.selectedAccounts = [];
            this.allAccounts.edges.forEach(element => {
                this.selectedAccounts.push(element.node.id);
            });
        },

        deselectAllAccounts() {
            this.selectedAccounts = [];
        },

        selectAllStaffs() {
            this.selectedStaffs = [];
            this.allStaffs.edges.forEach(element => {
                this.selectedStaffs.push(element.node.id);
            });
        },

        deselectAllStaffs() {
            this.selectedStaffs = [];
        },

        closeGroupDialog() {
            this.$emit("update:dialog", false);
            this.resetForm();
        },

        saveGroup() {
            if (!this.$refs.form.validate()) {
                return;
            }

            // set form state
            this.error = null;
            this.isSaving = true;

            // prepare api call payload
            var payload = {
                name: this.editedItem.name,
                permissions: this.selectedPermissions,
                staffs: this.selectedStaffs,
                accounts: this.selectedAccounts
            };

            // if editing
            if (this.object) {
                payload = {
                    ...payload,
                    id: this.editedItem.id
                };

                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation updateTenantStaffGroup(
                                $input: UpdateTenantStaffGroupInput!
                            ) {
                                updateTenantStaffGroup(input: $input) {
                                    tenantStaffGroup {
                                        id
                                        name
                                        permissions
                                        staffs {
                                            totalCount
                                        }
                                        accounts {
                                            totalCount
                                        }
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: { ...payload }
                        }
                    })
                    .then(() => {
                        this.closeGroupDialog();
                        this.$emit("done");
                    })
                    .catch(error => {
                        if (error.graphQLErrors) {
                            // this.error = error.graphQLErrors[0].message;
                            console.error(error.graphQLErrors[0].message);
                            this.error =
                                "Er is iets misgegaan, probeer het later opnieuw.";
                        }
                    })
                    .finally(() => {
                        this.isSaving = false;
                    });
            }
            // new
            else {
                payload = {
                    ...payload,
                    tenantNodeId: this.me.tenant.id
                };
                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation createTenantStaffGrouop(
                                $input: CreateTenantStaffGroupInput!
                            ) {
                                createTenantStaffGroup(input: $input) {
                                    tenantStaffGroup {
                                        id
                                        name
                                        permissions
                                        staffs {
                                            totalCount
                                        }
                                        accounts {
                                            totalCount
                                        }
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: { ...payload }
                        }
                    })
                    .then(() => {
                        this.closeGroupDialog();
                        this.$emit("done");
                    })
                    .catch(error => {
                        if (error.graphQLErrors) {
                            // this.error = error.graphQLErrors[0].message;
                            console.error(error.graphQLErrors[0].message);
                            this.error =
                                "Er is iets misgegaan, probeer het later opnieuw.";
                        }
                    })
                    .finally(() => {
                        this.isSaving = false;
                    });
            }
        }
    }
};
</script>