<template>
    <v-card width="100%" elevation="3">
        <!-- NIFE Number -->
        <v-card
            v-if="account.isMainAccount"
            :class="'mt-n5 ml-8 pink'"
            width="240"
        >
            <v-card-title
                class="py-1 white--text justify-center font-weight-medium"
            >
                Correspondentieadres
            </v-card-title>
        </v-card>
        <v-card
            v-else
            :class="
                account.customerTypeCode == '603540000'
                    ? 'mt-n5 ml-8 grey'
                    : 'mt-n5 ml-8 primary'
            "
            width="140"
        >
            <v-card-title
                class="py-1 white--text justify-center font-weight-medium"
            >
                <a
                    text
                    class="white--text"
                    @click="goToEnergyInvoicesByAddress(account.fullAddress)"
                >
                    {{ account.dynamicsNifenummer }}
                </a>
            </v-card-title>
        </v-card>

        <v-card-text class="mt-2 px-5 px-sm-10 py-5">
            <div class="text-subtitle-1">{{ account.name }}</div>
            <p class="text-h6 black--text">
                <v-icon class="mb-1 mr-2">business</v-icon>
                {{ account.fullAddress }}
            </p>

            <!-- electricity ean -->
            <v-row v-if="!account.isMainAccount" class="mt-5 mx-0 px-0">
                <v-col
                    v-for="e in account.eanElectricitySet.edges"
                    :key="e.node.id"
                    cols="12"
                    sm="12"
                    lg="12"
                    xl="12"
                    class="py-0 px-0"
                >
                    <v-list-item class="px-0 mb-5">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row class="align-center">
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="5"
                                        lg="12"
                                        xl="6"
                                    >
                                        <v-icon color="success" class="mr-1"
                                            >bolt</v-icon
                                        >
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-chip
                                                    v-if="
                                                        me.isTenantAdmin ||
                                                            hasPermission(
                                                                'energy.view_energyinvoice'
                                                            )
                                                    "
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="primary"
                                                    @click="
                                                        goToEnergyInvoicesByEAN(
                                                            e.node.ean
                                                        )
                                                    "
                                                    >{{ e.node.ean }}</v-chip
                                                >
                                                <v-chip
                                                    v-else
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >{{ e.node.ean }}</v-chip
                                                >
                                            </template>
                                            <span>EAN</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-chip
                                                    outlined
                                                    class="ml-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ><v-icon left small
                                                        >signal_cellular_alt</v-icon
                                                    >{{
                                                        e.node.meterType
                                                    }}</v-chip
                                                >
                                            </template>
                                            <span>Metertype</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-if="
                                            e.node.kvOrGv == 'GV' &&
                                                e.node.myMeasurementData ==
                                                    'Ja' &&
                                                !!e.node.measurementCompany
                                        "
                                    >
                                        <v-icon
                                            small
                                            left
                                            color="primary"
                                            class="ml-6 mb-1"
                                            >check_circle</v-icon
                                        >
                                        <a
                                            v-if="
                                                me.isTenantAdmin ||
                                                    hasPermission(
                                                        'energy.view_electricitymeasurement'
                                                    )
                                            "
                                            text
                                            @click="
                                                goToElectricityMeasurements(
                                                    e.node.ean
                                                )
                                            "
                                            >Meetdata</a
                                        >
                                        <span v-else>Meetdata</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-else-if="e.node.kvOrGv == 'GV'"
                                    >
                                        <v-icon small left class="ml-6 mb-1"
                                            >info</v-icon
                                        >
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <span v-bind="attrs" v-on="on"
                                                    >Meetdata aanvragen</span
                                                >
                                            </template>
                                            <span
                                                >Meetdata beschikbaar, neem
                                                contact op met NIFE</span
                                            >
                                        </v-tooltip>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-else-if="
                                            e.node.kvOrGv == 'KV' &&
                                                e.node.myMeasurementData ==
                                                    'Ja' &&
                                                !!e.node.measurementCompany
                                        "
                                        class="grey--text text-truncate"
                                    >
                                        <v-icon
                                            small
                                            left
                                            color="primary"
                                            class="ml-6 mb-1"
                                            >check_circle</v-icon
                                        >
                                        <a
                                            v-if="
                                                me.isTenantAdmin ||
                                                    hasPermission(
                                                        'energy.view_electricitymeasurement'
                                                    )
                                            "
                                            text
                                            @click="
                                                goToElectricityMeasurements(
                                                    e.node.ean
                                                )
                                            "
                                            >Meetdata</a
                                        >
                                        <span v-else>Meetdata</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-else
                                    >
                                        <v-icon
                                            small
                                            left
                                            class="ml-6 mb-1 grey--text text--lighten-1"
                                            >cancel</v-icon
                                        >
                                        <span class="grey--text"
                                            >Geen meetdata beschikbaar</span
                                        >
                                    </v-col>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

            <!-- gas ean -->
            <v-row v-if="!account.isMainAccount" class="mb-5 mx-0 px-0">
                <v-col
                    v-for="e in account.eanGasSet.edges"
                    :key="e.node.id"
                    cols="12"
                    sm="12"
                    lg="12"
                    xl="12"
                    class="py-0 px-0"
                >
                    <v-list-item class="px-0 mb-5">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row class="align-center">
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="5"
                                        lg="12"
                                        xl="6"
                                    >
                                        <v-icon color="warning" class="mr-1"
                                            >local_fire_department</v-icon
                                        >
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-chip
                                                    v-if="
                                                        me.isTenantAdmin ||
                                                            hasPermission(
                                                                'energy.view_energyinvoice'
                                                            )
                                                    "
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="primary"
                                                    @click="
                                                        goToEnergyInvoicesByEAN(
                                                            e.node.ean
                                                        )
                                                    "
                                                    >{{ e.node.ean }}</v-chip
                                                >
                                                <v-chip
                                                    v-else
                                                    outlined
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >{{ e.node.ean }}</v-chip
                                                >
                                            </template>
                                            <span>EAN</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-chip
                                                    outlined
                                                    class="ml-1"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ><v-icon left small
                                                        >signal_cellular_alt</v-icon
                                                    >{{
                                                        e.node.meterType
                                                    }}</v-chip
                                                >
                                            </template>
                                            <span>Metertype</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-if="
                                            e.node.kvOrGv == 'GV' &&
                                                e.node.myMeasurementData ==
                                                    'Ja' &&
                                                !!e.node.measurementCompany
                                        "
                                    >
                                        <v-icon
                                            small
                                            left
                                            color="primary"
                                            class="ml-6 mb-1"
                                            >check_circle</v-icon
                                        >
                                        <a
                                            v-if="
                                                me.isTenantAdmin ||
                                                    hasPermission(
                                                        'energy.view_electricitymeasurement'
                                                    )
                                            "
                                            text
                                            @click="
                                                goToGasMeasurements(e.node.ean)
                                            "
                                            >Meetdata</a
                                        >
                                        <span v-else>Meetdata</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-else-if="e.node.kvOrGv == 'GV'"
                                    >
                                        <v-icon small left class="ml-6 mb-1"
                                            >info</v-icon
                                        >
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <span v-bind="attrs" v-on="on"
                                                    >Meetdata aanvragen</span
                                                >
                                            </template>
                                            <span
                                                >Meetdata beschikbaar, neem
                                                contact op met NIFE</span
                                            >
                                        </v-tooltip>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-else-if="
                                            e.node.kvOrGv == 'KV' &&
                                                e.node.myMeasurementData ==
                                                    'Ja' &&
                                                !!e.node.measurementCompany
                                        "
                                        class="grey--text text-truncate"
                                    >
                                        <v-icon
                                            small
                                            left
                                            color="primary"
                                            class="ml-6 mb-1"
                                            >check_circle</v-icon
                                        >
                                        <a
                                            v-if="
                                                me.isTenantAdmin ||
                                                    hasPermission(
                                                        'energy.view_electricitymeasurement'
                                                    )
                                            "
                                            text
                                            @click="
                                                goToElectricityMeasurements(
                                                    e.node.ean
                                                )
                                            "
                                            >Meetdata</a
                                        >
                                        <span v-else>Meetdata</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="7"
                                        lg="12"
                                        xl="6"
                                        v-else
                                    >
                                        <v-icon
                                            small
                                            left
                                            class="ml-6 mb-1 grey--text text--lighten-1"
                                            >cancel</v-icon
                                        >
                                        <span class="grey--text"
                                            >Geen meetdata beschikbaar</span
                                        >
                                    </v-col>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>


<script>
import helper from "@/utils/helper.js";
export default {
    name: "account-overview-card",
    props: ["account"],
    data: () => ({}),
    computed: {
        me() {
            return this.$store.state.user.me || {};
        }
    },
    created() {
        // copy hasPermission from helper
        this.hasPermission = helper.hasPermission;
    },
    methods: {
        goToEnergyInvoicesByEAN: function(ean) {
            this.$router.push({
                name: "energiefacturen",
                query: { ean: ean }
            });
        },
        goToEnergyInvoicesByAddress: function(address) {
            this.$router.push({
                name: "energiefacturen",
                query: { address: address }
            });
        },
        goToElectricityMeasurements: function(ean) {
            this.$router.push({
                name: "elektriciteitverbruikjaar",
                params: { ean: ean }
            });
        },
        goToGasMeasurements: function(ean) {
            this.$router.push({
                name: "gasverbruikjaar",
                params: { ean: ean }
            });
        }
    }
};
</script>



