<template>
    <v-container class="fill-height" fluid>
        <!-- Login Dialog -->
        <v-row align="center" class="justify-center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-card class="elevation-0">
                    <div class="d-flex justify-start pl-5 mb-5">
                        <a href="https://www.nife.nl/">
                            <v-img
                                :src="require('../assets/logo-nife-original.png')"
                                max-height="83px"
                                max-width="352px"
                                contain
                                v-on:click="goToNife"
                            ></v-img>
                        </a>
                    </div>
                    <v-card-title>
                        <h1 class="font-weight-black">
                            Inloggen Mijn NIFE
                        </h1>
                    </v-card-title>
                    <v-card-text class="mt-10 pb-0">
                        <v-form ref="loginForm" @submit.prevent>
                            <v-text-field
                                placeholder="E-mailadres"
                                name="login"
                                type="email"
                                :rules="[rules.required]"
                                :error-messages="error"
                                outlined
                                prepend-inner-icon="person"
                                v-model="email"
                            ></v-text-field>

                            <v-text-field
                                id="password"
                                placeholder="Wachtwoord"
                                name="password"
                                :type="showpassword ? 'text' : 'password'"
                                :rules="[rules.required]"
                                :error-messages="error"
                                outlined
                                v-model="password"
                                prepend-inner-icon="lock"
                                @keydown.enter="login"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="primary"
                                        @click="showpassword = !showpassword"
                                        tabindex="-1"
                                        >{{
                                            showpassword
                                                ? "visibility"
                                                : "visibility_off"
                                        }}</v-icon
                                    >
                                </template>
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <p class="text-center pb-5 px-10">
                        <v-btn
                            color="primary"
                            block
                            rounded
                            :disabled="email.length < 2 || password.length < 2"
                            :loading="isLoggingIn"
                            @click="login"
                            >Inloggen</v-btn
                        >
                        <br />
                        <a @click="forgotPasswordDialog = true">
                            Wachtwoord vergeten
                        </a>
                    </p>
                </v-card>
            </v-col>
        </v-row>

        <!-- 2FA dialog -->
        <v-dialog v-model="tfaDialog" persistent max-width="400px">
            <v-card>
                <v-card-title class="primary white--text">
                    Voer uw 2FA-code in
                    <v-spacer></v-spacer>
                    <v-btn class="mr-n3" dark icon @click="close2FADialog()">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-5 pb-0">
                    <v-form ref="tfaForm" @submit.prevent>
                        <v-text-field
                            v-model="tfaCode"
                            counter="6"
                            :rules="[rules.required, rules.maxLength(6)]"
                            label="2FA-Code"
                            type="number"
                            outlined
                            autofocus
                            class="headline"
                            :error-messages="tfaCodeError"
                            @keydown.enter="loginWith2FA"
                            hint="Google Authenticator Code"
                        ></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        rounded
                        :disabled="tfaCode.length != 6"
                        :loading="isLoggingInWith2FA"
                        color="primary"
                        @click="loginWith2FA()"
                        >Verify</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Forgot Passwrod dialog -->
        <v-dialog v-model="forgotPasswordDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="primary white--text">
                    Wachtwoord vergeten
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-n3"
                        dark
                        icon
                        @click="closeForgotPasswordDialog()"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="!isResetPasswordEmailSent" class="pt-5 pb-0">
                    <p>
                        Voer het e-mailadres in dat aan uw account is gekoppeld.
                    </p>
                    <v-form ref="forgotPasswordForm" @submit.prevent>
                        <v-text-field
                            v-model="forgotPasswordEmail"
                            :rules="[rules.required]"
                            label="E-mail"
                            type="email"
                            outlined
                            autofocus
                            :error-messages="forgotPasswordEmailError"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-text v-else>
                    <h1 class="text-center my-5">Controleer je inbox</h1>
                    <p>
                        We hebben je instructies per e-mail gestuurd voor het
                        instellen van je nieuwe wachtwoord. Je ontvangt de
                        e-mail binnen enkele minuten.
                    </p>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!isResetPasswordEmailSent"
                        outlined
                        rounded
                        :disabled="!forgotPasswordEmail.includes('@')"
                        :loading="isRequestingPasswordReset"
                        color="primary"
                        @click="requestPasswordReset()"
                        >Doorgaan</v-btn
                    >
                    <v-btn
                        v-else
                        outlined
                        rounded
                        color="primary"
                        @click="closeForgotPasswordDialog()"
                        >Sluit</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
// import Config from "@/utils/config.json";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    title: "Inloggen Mijn NIFE",
    data: function() {
        return {
            isLoggingIn: false,
            isLoggingInWith2FA: false,
            showpassword: false,
            tfaDialog: false,

            forgotPasswordDialog: false,
            isRequestingPasswordReset: false,
            forgotPasswordEmail: "",
            forgotPasswordEmailError: "",
            isResetPasswordEmailSent: false,

            email: "",
            password: "",
            tfaCode: "",
            error: "",
            tfaCodeError: "",
            mess: "",
            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                listRequired: v =>
                    (v || "").length >= 1 || `Dit veld is verplicht`,
                minLength: len => v =>
                    (v || "").length >= len || `Require at least ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
                // nameRules: [
                //     v => !!v || "Name is required",
                //     v =>
                //         v.length <= 50 || "Name must be less than 50 characters"
                // ]
            }
        };
    },
    computed: {},

    created() {
        this.pushTo = helper.pushTo;
    },
    methods: {
        goToNife() {
            window.location.href = "https://www.nife.nl/";
        },
        requestPasswordReset() {
            // check if form valid
            if (!this.$refs.forgotPasswordForm.validate()) {
                return;
            }

            this.isRequestingPasswordReset = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation requestPasswordReset(
                            $input: RequestPasswordResetInput!
                        ) {
                            requestPasswordReset(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            email: this.forgotPasswordEmail
                        }
                    }
                })
                .then(() => {
                    this.isResetPasswordEmailSent = true;
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            this.forgotPasswordEmailError = err.message;
                        }
                    }
                })
                .finally(() => {
                    this.isRequestingPasswordReset = false;
                });
        },

        closeForgotPasswordDialog() {
            this.isResetPasswordEmailSent = false;
            this.forgotPasswordEmail = "";
            this.forgotPasswordEmailError = "";
            if (this.$refs.forgotPasswordForm) {
                this.$refs.forgotPasswordForm.resetValidation();
            }
            this.forgotPasswordDialog = false;
        },

        login() {
            this.error = null;

            // check if form valid
            if (!this.$refs.loginForm.validate()) {
                return;
            }

            this.isLoggingIn = true;

            const payload = { email: this.email, password: this.password };

            this.$store
                .dispatch("user/login", payload)
                .then(() => {
                    // // NIFE Staff
                    // if (response.data?.tokenAuth?.user?.isStaff) {
                    //     this.pushTo("accounts");
                    // } else {
                    //     // normal user
                    //     this.pushTo("overzicht");
                    // }
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect || "/");
                    } else {
                        this.pushTo("home");
                    }
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            /*2fa*/
                            if (err.message === "2FA") {
                                this.tfaDialog = true;
                            } else {
                                this.error = err.message;
                            }
                        }
                    }
                })
                .finally(() => {
                    this.isLoggingIn = false;
                });
        },

        close2FADialog() {
            this.tfaCode = "";
            this.$refs.tfaForm.resetValidation();
            this.tfaDialog = false;
        },

        loginWith2FA() {
            // check if form valid
            if (!this.$refs.tfaForm.validate()) {
                return;
            }

            this.isLoggingInWith2FA = true;

            const payload = {
                email: this.email,
                password: this.password,
                tfaCode: this.tfaCode
            };

            this.$store
                .dispatch("user/login", payload)
                .then(() => {
                    // // NIFE Staff
                    // if (response.data?.tokenAuth?.user?.isStaff) {
                    //     this.pushTo("accounts");
                    // } else {
                    //     // normal user
                    //     this.pushTo("overzicht");
                    // }

                    this.pushTo("home");
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            /*2fa*/
                            if (err.message === "INVALID_GACODE") {
                                this.tfaCodeError = "Invalide 2FA-Code";
                            } else {
                                this.tfaCodeError = err.message;
                            }
                        }
                    }
                })
                .finally(() => {
                    this.isLoggingInWith2FA = false;
                });
        }

        // fetchMe() {
        //     this.$store
        //         .dispatch("user/fetchMe")
        //         .then(response => {
        //             console.log(response);
        //             this.pushTo("overzicht");
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // }
    }
};
</script>
