<template>
    <v-container fluid class="pa-0">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-col cols="auto">
                        <h2 class="display-3 font-weight-medium">Overzicht</h2>
                        <!-- <v-progress-linear
                            :active="$apollo.queries.accounts.loading"
                            color="primary"
                            buffer-value="0"
                            stream
                            class="mx-0 mt-2 px-0"
                        ></v-progress-linear> -->
                    </v-col>
                    <v-spacer></v-spacer>
                </v-card-title>
            </v-card>
        </v-container>

        <!-- Overview  body -->
        <v-card flat color="grey lighten-4 pt-10" min-height="73vh" fill-width>
            <v-container>
                <v-card
                    v-if="$apollo.queries.accounts.loading"
                    color="transparent"
                    flat
                    class="mx-auto text-center"
                    height="50vh"
                    width="200"
                >
                    <v-responsive min-height="20vh"></v-responsive>
                    <div class="grey--text text--darken-2">
                        Een ogenblik geduld aub...
                    </div>
                    <v-row class="justify-center">
                        <v-col cols="12">
                            <v-progress-linear
                                color="primary accent-4"
                                indeterminate
                                rounded
                                height="5"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- no data -->
                <v-card
                    v-else-if="!me.isTenantAdmin && accounts.edgeCount == 0"
                    color="transparent"
                    flat
                    class="mx-auto text-center"
                    height="50vh"
                    width="200"
                >
                    <v-responsive min-height="20vh"></v-responsive>
                    <div class="grey--text text--darken-2">
                        Geen gegevens beschikbaar
                    </div>
                </v-card>
                <!-- accounts -->
                <v-row v-else class="pb-5">
                    <!-- main account -->
                    <v-col
                        v-if="mainAccount"
                        cols="12"
                        lg="6"
                        class="mb-0 d-flex align-stretch px-3"
                    >
                        <AccountOverzichtCard
                            :account="mainAccount.node"
                        >
                        </AccountOverzichtCard>
                    </v-col>

                    <v-col cols="12" class="px-0">
                        <!-- sub accounts -->
                        <v-row class="px-0 mx-0">
                            <v-col
                                cols="12"
                                lg="6"
                                class="mt-10 mb-0 d-flex align-stretch px-3"
                                v-for="a in subAccounts"
                                :key="a.node.id"
                            >
                                <!-- <v-lazy transition="fade-transition"> -->
                                <AccountOverzichtCard
                                    v-if="a.node"
                                    :account="a.node"
                                >
                                </AccountOverzichtCard>
                                <!-- </v-lazy> -->
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import AccountOverzichtCard from "../components/overzicht/AccountOverzichtCard.vue";

export default {
    title: "Overzicht",
    components: {
        AccountOverzichtCard
    },

    apollo: {
        accounts: {
            query: gql`
                query accounts($first: Int, $isMainAccount:Boolean, $orderBy: String) {
                    accounts(first: $first, isMainAccount: $isMainAccount, orderBy: $orderBy) {
                        edgeCount
                        edges {
                            node {
                                id
                                name
                                isMainAccount
                                dynamicsNifenummer
                                customerTypeCode
                                fullAddress
                                eanElectricitySet {
                                    edgeCount
                                    edges {
                                        node {
                                            id
                                            ean
                                            kvOrGv
                                            myMeasurementDataCode
                                            myMeasurementData
                                            meterType
                                            measurementCompany
                                        }
                                    }
                                }
                                eanGasSet {
                                    edgeCount
                                    edges {
                                        node {
                                            id
                                            ean
                                            kvOrGv
                                            myMeasurementDataCode
                                            myMeasurementData
                                            meterType
                                            measurementCompany
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: 200,
                    // isMainAccount: false,
                    orderBy: "dynamicsNifenummer"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            update: data => data.accounts,
            skip() {
                return this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            search: null,
            accounts: {}
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        mainAccount() {
            return this.accounts.edges.filter(
                a => a.node.isMainAccount
            )[0];
        },
        subAccounts(){
            return this.accounts.edges.filter(
                a => !a.node.isMainAccount
            );
        },
        sortedAccounts() {
            // sort accounts by NifeNummer
            let _sortedAccounts = {};
            if (this.accounts.edges) {
                _sortedAccounts = [...this.accounts.edges];

                _sortedAccounts.sort((a, b) => {
                    return a.node.dynamicsNifenummer.toLowerCase() ==
                        b.node.dynamicsNifenummer.toLowerCase()
                        ? 0
                        : a.node.dynamicsNifenummer.toLowerCase() >
                          b.node.dynamicsNifenummer.toLowerCase()
                        ? 1
                        : -1;
                });
            }

            return _sortedAccounts;
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {},
    methods: {}
};
</script>