import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
import DefaultHome from "../views/DefaultHome.vue";
import Overzicht from "../views/Overzicht.vue";
import Gegevens from "../views/Gegevens.vue";
import Facturen from "../views/Facturen.vue";
import EnergieFacturen from "../components/facturen/EnergieFacturen.vue";
import DienstFacturen from "../components/facturen/DienstFacturen.vue";
import Verbruik from "../views/Verbruik.vue";
import Contracten from "../views/Contracten.vue";
import ElektraContracten from "../components/contracten/ElektraContracten.vue";
import GasContracten from "../components/contracten/GasContracten.vue";
import AllContracten from "../components/contracten/AllContracten.vue";
import DefaultVerbruikHome from "../components/verbruik/DefaultVerbruikHome.vue";
import ElektriciteitVerbruik from "../components/verbruik/ElektriciteitVerbruik.vue";
import ElektriciteitVerbruikJaar from "../components/verbruik/ElektriciteitVerbruikJaar.vue";
import ElektriciteitVerbruikMaand from "../components/verbruik/ElektriciteitVerbruikMaand.vue";
import ElektriciteitVerbruikDag from "../components/verbruik/ElektriciteitVerbruikDag.vue";
import ElektriciteitVerbruikPeriode from "../components/verbruik/ElektriciteitVerbruikPeriode.vue";
import GasVerbruik from "../components/verbruik/GasVerbruik.vue";
import GasVerbruikJaar from "../components/verbruik/GasVerbruikJaar.vue";
import GasVerbruikMaand from "../components/verbruik/GasVerbruikMaand.vue";
import GasVerbruikDag from "../components/verbruik/GasVerbruikDag.vue";
import GasVerbruikPeriode from "../components/verbruik/GasVerbruikPeriode.vue";
import Instellingen from "../views/Instellingen.vue";
import MijnGegevens from "../components/instellingen/MijnGegevens.vue";
import Groepen from "../components/instellingen/Groepen.vue";
import Gebruikers from "../components/instellingen/Gebruikers.vue";
import Staffs from "../components/instellingen/Staffs.vue";
import Accounts from "../views/Accounts.vue";
import MarktPrijzen from "../views/MarktPrijzen.vue";

import { ACCESS_TOKEN_KEY } from "@/vue-apollo";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/",
        name: "home",
        component: DefaultHome,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/resetpassword/:uidb64/:token",
        name: "resetpassword",
        component: ResetPassword
    },
    {
        path: "/overzicht",
        name: "overzicht",
        component: Overzicht,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/facturen",
        name: "facturen",
        component: Facturen,
        children: [
            {
                path: "energiefacturen",
                name: "energiefacturen",
                component: EnergieFacturen
            },
            {
                path: "dienstfacturen",
                name: "dienstfacturen",
                component: DienstFacturen
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/verbruik",
        component: Verbruik,
        children: [
            {
                path: "",
                name: "verbruik",
                component: DefaultVerbruikHome
            },
            {
                path: "elektriciteit/:ean",
                name: "elektriciteitverbruik",
                component: ElektriciteitVerbruik,
                children: [
                    {
                        path: "jaar",
                        name: "elektriciteitverbruikjaar",
                        component: ElektriciteitVerbruikJaar
                    },
                    {
                        path: "maand",
                        name: "elektriciteitverbruikmaand",
                        component: ElektriciteitVerbruikMaand
                    },
                    {
                        path: "dag",
                        name: "elektriciteitverbruikdag",
                        component: ElektriciteitVerbruikDag
                    },
                    {
                        path: "periode",
                        name: "elektriciteitverbruikperiode",
                        component: ElektriciteitVerbruikPeriode
                    }
                ]
            },
            {
                path: "gas/:ean",
                name: "gasverbruik",
                component: GasVerbruik,
                children: [
                    {
                        path: "jaar",
                        name: "gasverbruikjaar",
                        component: GasVerbruikJaar
                    },
                    {
                        path: "maand",
                        name: "gasverbruikmaand",
                        component: GasVerbruikMaand
                    },
                    {
                        path: "dag",
                        name: "gasverbruikdag",
                        component: GasVerbruikDag
                    },
                    {
                        path: "periode",
                        name: "gasverbruikperiode",
                        component: GasVerbruikPeriode
                    }
                ]
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/contracten",
        name: "contracten",
        component: Contracten,
        children: [
            {
                path: "elektracontracten",
                name: "elektracontracten",
                component: ElektraContracten
            },
            {
                path: "gascontracten",
                name: "gascontracten",
                component: GasContracten
            },
            {
                path: "allcontracten",
                name: "allcontracten",
                component: AllContracten
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/gegevens",
        name: "gegevens",
        component: Gegevens,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/instellingen",
        name: "instellingen",
        component: Instellingen,
        children: [
            {
                path: "mijngegevens",
                name: "mijngegevens",
                component: MijnGegevens
            },
            {
                path: "gebruikers",
                name: "gebruikers",
                component: Gebruikers
            },
            {
                path: "groepen",
                name: "groepen",
                component: Groepen
            },
            {
                path: "staffs",
                name: "staffs",
                component: Staffs
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/accounts",
        name: "accounts",
        component: Accounts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/marktprijzen",
        name: "marktprijzen",
        component: MarktPrijzen,
        meta: {
            requiresAuth: true
        }
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
            next();
        } else {
            next({ name: "login", query: { redirect: to.path } });
        }
    } else {
        next();
    }
});

export default router;
