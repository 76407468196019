<template>
    <v-container fluid class="pa-0 ">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-row class="my-0 px-3 align-end">
                        <v-col cols="auto">
                            <h2 class="display-3 font-weight-medium">
                                Facturen
                            </h2>
                            <!-- <v-progress-linear
                                :active="isLoading"
                                color="primary"
                                buffer-value="0"
                                stream
                                class="mx-0 mt-2 px-0"
                            ></v-progress-linear> -->
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto" class="mr-auto">
                            <v-tabs v-model="tab" class="justify-center">
                                <v-tab
                                    v-show="
                                        me.isTenantAdmin ||
                                            hasPermission(
                                                'energy.view_energyinvoice'
                                            )
                                    "
                                    >Energiefacturen</v-tab
                                >
                                <v-tab
                                    v-show="
                                        me.isTenantAdmin ||
                                            hasPermission(
                                                'energy.view_serviceinvoice'
                                            )
                                    "
                                >
                                    <v-badge
                                        color="pink"
                                        :content="openInvoiceCount"
                                        :value="openInvoiceCount"
                                        >Dienstverlening
                                    </v-badge>
                                </v-tab>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
        </v-container>

        <!-- Overview  body -->
        <v-card flat color="grey lighten-4 pt-15" min-height="73vh" fill-width>
            <router-view @loading="onLoading"></router-view>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    title: "Facturen",
    components: {},

    apollo: {
        openInvoiceCount: {
            query: gql`
                query serviceInvoices($paymentStatusCodeIn: [Int]) {
                    serviceInvoices(
                        paymentStatusCode_In: $paymentStatusCodeIn
                    ) {
                        totalCount
                    }
                }
            `,
            variables() {
                return {
                    paymentStatusCodeIn: [603540001]
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            update: data => data.serviceInvoices?.totalCount || 0
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            tab: null,
            isLoading: false,
            openInvoiceCount: 0
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        }
    },
    watch: {
        tab(val) {
            switch (val) {
                case 0:
                    this.pushTo("energiefacturen");
                    break;
                case 1:
                    this.pushTo("dienstfacturen");
                    break;
                default:
                    this.pushTo("energiefacturen");
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if (this.hasPermission("energy.view_energyinvoice")) {
            this.tab = 0;
        } else if (this.hasPermission("energy.view_serviceinvoice")) {
            this.tab = 1;
        }
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>