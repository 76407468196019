<template>
    <v-container class="py-0">
        <v-card elevation="3">
            <v-tabs v-model="tab" class="justify-center">
                <v-tabs-slider hidden></v-tabs-slider>
                <v-tab disabled>Jaar</v-tab>
                <v-tab disabled>Maand</v-tab>
                <v-tab disabled>Dag</v-tab>
                <v-tab disabled>Periode</v-tab>
            </v-tabs>
        </v-card>
        <v-container fluid class="pa-0">
            <v-card class="mt-8" elevation="3">
                <v-row class="mx-0 px-3 align-center">
                    <v-col cols="6" sm="4" md="3"> </v-col>
                    <v-spacer />
                    <v-col cols="auto"> </v-col>
                </v-row>

                <v-row class="px-5">
                    <v-col cols="12">
                        <VueApexCharts
                            ref="chart"
                            type="line"
                            height="400"
                            :options="chartOptions"
                            :series="series"
                        ></VueApexCharts>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "elektriciteit-verbruik",
    props: [],
    components: { VueApexCharts },

    apollo: {},

    data() {
        return {
            tab: 0,
            series: [],
            chartOptions: {
                chart: {
                    id: "yearly-usage-chart",
                    height: 300,
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "category",
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "Mei",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Okt",
                        "Nov",
                        "Dec"
                    ]
                    // categories: [
                    //     "Januari",
                    //     "Februari",
                    //     "Maart",
                    //     "April",
                    //     "Mei",
                    //     "Juni",
                    //     "Juli",
                    //     "Augustus",
                    //     "September",
                    //     "Oktober",
                    //     "November",
                    //     "December"
                    // ]
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#BDBDBD"
                        },
                        labels: {
                            style: {
                                colors: "#BDBDBD"
                            }
                            // formatter: function(val) {
                            //     return val.toFixed(0);
                            // }
                        },
                        title: {
                            text: "Levering / Teruglevering",
                            style: {
                                color: "#BDBDBD"
                            }
                        },
                        tooltip: {
                            enabled: false
                        }
                    },
                    {
                        show: false
                    },
                    {
                        seriesName: "Piek",
                        opposite: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#BDBDBD"
                        },
                        labels: {
                            style: {
                                colors: "#BDBDBD"
                            }
                        },
                        title: {
                            text: "Piek",
                            style: {
                                color: "#BDBDBD"
                            }
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [1, 1, 2],
                    dashArray: [0, 0, 2]
                },
                markers: {
                    size: [0, 0, 0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#BDBDBD", "#00E396", "#BDBDBD"],
                legend: {
                    position: "bottom",
                    horizontalAlign: "center",
                    offsetX: 0,
                    offsetY: 5
                },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                }
            }
        };
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
};
</script>