<template>
    <v-dialog v-model="dialog" persistent max-width="960px">
        <!-- elektriciteit -->
        <v-card v-if="measurementType == 'elektriciteit'">
            <v-card-title class="primary white--text">
                <v-icon color="success" class="mr-1">bolt</v-icon>
                {{ ean }}
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>

            <v-toolbar flat height="48">
                <v-tabs v-model="tab" class="justify-center">
                    <v-tab>Jaar</v-tab>
                    <v-tab>Maand</v-tab>
                    <v-tab>Dag</v-tab>
                    <v-tab>Periode</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    rounded
                    outlined
                    small
                    @click="downloadDialog = !downloadDialog"
                    >Downloaden</v-btn
                >
            </v-toolbar>

            <DownloadElektriciteit
                :dialog.sync="downloadDialog"
                :ean="ean"
            ></DownloadElektriciteit>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card class="pa-3">
                        <ElektriciteitVerbruikJaar
                            :propEan="ean"
                            :propDialog="true"
                        ></ElektriciteitVerbruikJaar>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="pa-3">
                        <ElektriciteitVerbruikMaand
                            :propEan="ean"
                            :propDialog="true"
                        ></ElektriciteitVerbruikMaand>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="pa-3">
                        <ElektriciteitVerbruikDag
                            :propEan="ean"
                            :propDialog="true"
                        ></ElektriciteitVerbruikDag>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="pa-3">
                        <ElektriciteitVerbruikPeriode
                            :propEan="ean"
                            :propDialog="true"
                        ></ElektriciteitVerbruikPeriode>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <!-- gas -->
        <v-card v-if="measurementType == 'gas'">
            <v-card-title class="primary white--text">
                <v-icon color="warning" class="mr-1"
                    >local_fire_department</v-icon
                >
                {{ ean }}
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>

            <v-toolbar flat height="48">
                <v-tabs v-model="tab" class="justify-center">
                    <v-tab>Jaar</v-tab>
                    <v-tab>Maand</v-tab>
                    <v-tab>Dag</v-tab>
                    <v-tab>Periode</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    rounded
                    outlined
                    small
                    @click="downloadDialog = !downloadDialog"
                    >Downloaden</v-btn
                >
            </v-toolbar>

            <DownloadGas :dialog.sync="downloadDialog" :ean="ean"></DownloadGas>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card class="pa-3">
                        <GasVerbruikJaar
                            :propEan="ean"
                            :propDialog="true"
                        ></GasVerbruikJaar>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="pa-3">
                        <GasVerbruikMaand
                            :propEan="ean"
                            :propDialog="true"
                        ></GasVerbruikMaand>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="pa-3">
                        <GasVerbruikDag
                            :propEan="ean"
                            :propDialog="true"
                        ></GasVerbruikDag>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="pa-3">
                        <GasVerbruikPeriode
                            :propEan="ean"
                            :propDialog="true"
                        ></GasVerbruikPeriode>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import ElektriciteitVerbruikJaar from "./ElektriciteitVerbruikJaar.vue";
import ElektriciteitVerbruikMaand from "./ElektriciteitVerbruikMaand.vue";
import ElektriciteitVerbruikDag from "./ElektriciteitVerbruikDag.vue";
import ElektriciteitVerbruikPeriode from "./ElektriciteitVerbruikPeriode.vue";
import DownloadElektriciteit from "./DownloadElektriciteit.vue";
import GasVerbruikJaar from "./GasVerbruikJaar.vue";
import GasVerbruikMaand from "./GasVerbruikMaand.vue";
import GasVerbruikDag from "./GasVerbruikDag.vue";
import GasVerbruikPeriode from "./GasVerbruikPeriode.vue";
import DownloadGas from "./DownloadGas.vue";

export default {
    name: "verbruik-dialog",
    props: ["dialog", "ean", "measurementType"],
    components: {
        ElektriciteitVerbruikJaar,
        ElektriciteitVerbruikMaand,
        ElektriciteitVerbruikDag,
        ElektriciteitVerbruikPeriode,
        DownloadElektriciteit,
        GasVerbruikJaar,
        GasVerbruikMaand,
        GasVerbruikDag,
        GasVerbruikPeriode,
        DownloadGas
    },

    apollo: {},

    data: function() {
        return {
            downloadDialog: false,
            tab: null
        };
    },
    computed: {},
    watch: {
        $route(to) {
            if (to.query.year && to.query.month) {
                this.tab = 1;
            }
            if (to.query.year && to.query.month && to.query.day) {
                this.tab = 2;
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        closeDialog() {
            this.$emit("update:ean", null);
            this.$emit("update:dialog", false);
        }
    }
};
</script>
