import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2071B5",
                secondary: "#673AB7",
                success: "#66BB6A",
                info: "#29B6F6",
                accent: "#5C6BC0",
                warning: "#E79723",
                error: "#EF5350",
                background: "#F5F5F5"
            }
        }
    },
    icons: {
        iconfont: "md"
    }
});

// {"primary": "#55BCEB" "#616bbc", "secondary": "#673AB7", "success": "#66BB6A", "info": "#29B6F6", "accent": "#5C6BC0", "warning": "#e79723", "error": "#EF5350" }
