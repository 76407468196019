<template>
    <v-snackbar v-model="show" :color="color" bottom :timeout="2000">
        <v-icon dark class="mr-2">{{ icon }}</v-icon>
        {{ message }}

        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="show = false">
                <v-icon>close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            icon: "",
            message: "",
            color: ""
        };
    },
    computed: {
        snackbar: function() {
            return this.$store.state.snackbar.snackbar;
        }
    },
    watch: {
        snackbar(val) {
            if (val) {
                if (val.icon) {
                    this.icon = val.icon;
                } else {
                    if (this.color == "success") {
                        this.icon = "check";
                    } else if (this.color == "error") {
                        this.icon = "warning";
                    } else if (this.color == "info") {
                        this.icon = "info";
                    }
                }

                this.message = val.message;
                this.color = val.color;
                this.show = true;
            }
        }
    }
};
</script>