<template>
    <v-container>
        <!-- div for help to place filter card -->
        <div class="mt-n16 white--text">.</div>

        <!-- filter -->
        <v-card class="mt-0 py-5" elevation="3">
            <v-row align="center" class="px-5">
                <v-col cols="auto">
                    <v-text-field
                        v-model="filter.email"
                        label="E-mailadres"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="auto">
                    <v-text-field
                        v-model="filter.firstName"
                        label="Voornaam"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="auto">
                    <v-text-field
                        v-model="filter.lastName"
                        label="Achternaam"
                        clearable
                        hide-details
                        dense
                        class="subtitle-2"
                    ></v-text-field>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn rounded color="primary" @click="addUser"
                        >Toevoegen</v-btn
                    >
                </v-col>
            </v-row>
        </v-card>

        <!-- data table card -->
        <v-card class="mt-3" elevation="3">
            <v-card-text class="pb-3">
                <v-data-table
                    :headers="headers"
                    :loading="$apollo.queries.staffUsers.loading"
                    :items="staffUsers.edges"
                    :expanded.sync="expanded"
                    item-key="node.id"
                    sort-by="node.email"
                    :sort-desc="false"
                    loading-text="Een ogenblik geduld aub..."
                    hide-default-footer
                    disable-pagination
                >
                    <!-- data table progress bar -->
                    <template v-slot:progress class="px-0 mx-0">
                        <v-progress-linear
                            absolute
                            height="2"
                            indeterminate
                        ></v-progress-linear>
                    </template>

                    <!-- no data availabel -->
                    <template slot="no-data">
                        <div>Geen gegevens beschikbaar</div>
                    </template>

                    <!-- email-->
                    <template v-slot:item.node.email="{ item }">
                        {{ item.node.email }}
                        <v-chip
                            small
                            v-if="item.node.id == me.id"
                            color="primary"
                            dark
                            >ik</v-chip
                        >
                    </template>

                    <!-- is administrator -->
                    <template v-slot:item.node.isSuperuser="{ item }">
                        <v-icon v-if="item.node.isSuperuser" color="success"
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- enabled 2FA -->
                    <template v-slot:item.node.enabled2fa="{ item }">
                        <v-icon v-if="item.node.enabled2fa" color="success"
                            >check_circle</v-icon
                        >
                    </template>

                    <!-- formatted lastLogin date -->
                    <template v-slot:item.node.lastLogin="{ item }">
                        <span v-if="item.node.lastLogin">{{
                            item.node.lastLogin | moment("DD-MM-YYYY")
                        }}</span>
                    </template>

                    <!-- acions -->
                    <template v-slot:item.action="{ item }">
                        <v-btn
                            :disabled="!me.isSuperuser"
                            icon
                            outlined
                            small
                            color="primary"
                            class="mr-2"
                            @click="editUser(item.node)"
                        >
                            <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn
                            :disabled="
                                !me.isSuperuser || item.node.id == me.id
                            "
                            icon
                            outlined
                            small
                            color="error"
                            @click="deletingUser(item.node)"
                        >
                            <v-icon small>delete</v-icon>
                        </v-btn>
                        <!-- <v-icon small @click="deletingPlant(item)">delete</v-icon> -->
                    </template>
                    <!-- expanded area -->
                    <!-- <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="px-5 py-5">
                            <v-subheader>Groepen</v-subheader>
                            <v-row
                                v-for="group in item.node.allGroups.edges"
                                :key="group.node.id"
                            >
                                <v-col cols="6" sm="8" md="9">{{
                                    group.node.name
                                }}</v-col>
                            </v-row>
                        </td>
                    </template> -->
                </v-data-table>
            </v-card-text>

            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.energyInvoices.loading"
                    text
                    class="mb-5"
                    @click="loadMore"
                >
                    Meer
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- Delete Tenant Staff Dialog -->
        <DeleteDialog
            :dialog.sync="deleteStaffUserDialog"
            :item="deletedStaffUser"
            v-on:confirmed="onDeleteConfirmed"
        >
            <template v-slot:title
                >Verwijderen -
                {{
                    deletedStaffUser ? deletedStaffUser.email : ""
                }}</template
            >
            <template v-slot:content
                >Weet u zeker dat u deze gebruiker wilt verwijderen?</template
            >
        </DeleteDialog>

        <StaffForm
            :dialog.sync="staffUserDialog"
            :object.sync="editedStaffUser"
            v-on:done="refetchDate"
        ></StaffForm>
    </v-container>
</template>


<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import StaffForm from "./forms/StaffForm.vue";

export default {
    title: "Staffs",
    components: { DeleteDialog, StaffForm },

    apollo: {
        staffUsers: {
            query: gql`
                query staffUsers(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $isStaff: Boolean
                    $email: String
                    $firstName: String
                    $lastName: String
                    $orderBy: String
                ) {
                    users(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        isStaff: $isStaff
                        email_Icontains: $email
                        firstName_Icontains: $firstName
                        lastName_Icontains: $lastName
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                firstName
                                lastName
                                email
                                isStaff
                                isSuperuser
                                lastLogin
                                require2fa
                                enabled2fa
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.filter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.users,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return !this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            staffUserDialog: false,
            deleteStaffUserDialog: false,
            editedStaffUser: null,
            deletedStaffUser: null,

            // graphql query filter
            filter: {
                first: 25,
                after: null,
                isStaff: true,
                email: null,
                firstName: null,
                lastName: null,
                orderBy: "email"
            },
            expanded: [],
            staffUsers: {},

            // infinite loading trigger
            shouldLoadMore: false,

            headers: [
                {
                    text: "E-mailadres",
                    align: "left",
                    value: "node.email",
                    sortable: true
                },
                {
                    text: "Voornaam",
                    align: "left",
                    value: "node.firstName",
                    sortable: true
                },
                {
                    text: "Achternaam",
                    value: "node.lastName",
                    sortable: true
                },
                {
                    text: "Beheerder",
                    value: "node.isSuperuser",
                    sortable: true
                },
                {
                    text: "2FA",
                    value: "node.enabled2fa",
                    sortable: true
                },
                {
                    text: "Laatst ingelogd",
                    value: "node.lastLogin",
                    sortable: true
                },
                {
                    text: "",
                    value: "action",
                    width: "120px",
                    align: "center",
                    sortable: false
                }
                // { text: "", value: "data-table-expand" }
            ]
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        // infinite loading
        hasMoreData() {
            return this.staffUsers?.pageInfo?.hasNextPage;
        }
    },
    watch: {
        filter: {
            deep: true,

            handler() {
                this.refetchDate();
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {},
    methods: {
        // infinite loading trigger
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (this.shouldLoadMore && this.staffUsers?.pageInfo?.hasNextPage) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.staffUsers.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.staffUsers?.pageInfo?.hasNextPage) {
                this.$apollo.queries.staffUsers.fetchMore({
                    variables: {
                        after: this.staffUsers.pageInfo.endCursor
                    }
                });
            }
        },

        addUser() {
            this.editedStaffUser = null;
            this.staffUserDialog = true;
        },
        editUser(user) {
            this.editedStaffUser = JSON.parse(JSON.stringify(user));
            this.staffUserDialog = true;
        },
        deletingUser(item) {
            this.deletedStaffUser = item;
            this.deleteStaffUserDialog = true;
        },
        onDeleteConfirmed(item) {
            if (item) {
                const payload = {
                    id: item.id
                };

                this.$apollo
                    .mutate({
                        // Query
                        mutation: gql`
                            mutation deleteUser($input: DeleteUserInput!) {
                                deleteUser(input: $input) {
                                    user {
                                        id
                                        firstName
                                        lastName
                                        email
                                        isStaff
                                        isSuperuser
                                        lastLogin
                                    }
                                }
                            }
                        `,
                        // Parameters
                        variables: {
                            input: {
                                ...payload
                            }
                        }
                    })
                    .then(() => {
                        this.deleteStaffUserDialog = false;
                        this.refetchDate();

                        // show snackbar
                        const payload = {
                            color: "success",
                            message: "De gebruiker is verwijderd!"
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    })
                    .catch(error => {
                        // Error
                        console.error(error);
                    })
                    .finally(() => {});
            }
        }
    }
};
</script>