<template>
    <!-- Enable 2FA dialog -->
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card>
            <v-card-title class="primary white--text justify-center">
                <v-icon color="white" class="mr-2">verified_user</v-icon>
                2FA inschakelen
            </v-card-title>

            <v-card-text v-if="isGeneratingSecretKey">
                <v-card
                    color="transparent"
                    flat
                    class="mx-auto text-center"
                    height="20vh"
                    width="200"
                >
                    <v-responsive min-height="10vh"></v-responsive>
                    <div class="grey--text text--darken-2">
                        Een ogenblik geduld aub...
                    </div>
                    <v-row class="justify-center">
                        <v-col cols="12">
                            <v-progress-linear
                                color="primary accent-4"
                                indeterminate
                                rounded
                                height="5"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card-text>

            <v-card-text v-else class="pt-5 pb-3">
                <p class="text-center">
                    Volg deze stappen om 2FA in te stellen voor mijn.NIFE.nl
                </p>

                <p class="subtitle-2 text-center mt-5">
                    Stap 1. Scan deze QR-code met "Google Authenticator"!
                </p>

                <v-row class="d-flex justify-space-around">
                    <v-card v-if="secretKey2FA" class="pa-3 elevation-0">
                        <qrcode-vue
                            :value="tfaQrCodeValue"
                            size="300"
                            level="H"
                        />
                    </v-card>
                </v-row>

                <p class="subtitle-2 text-center mt-5">
                    Stap 2. Controleer de Google-authenticatiecode!
                </p>

                <v-form v-model="valid" ref="enable2FAForm">
                    <v-row class="justify-space-around">
                        <v-col class="col-6">
                            <v-text-field
                                v-model="tfaCode"
                                counter="6"
                                :rules="[
                                    rules.required,
                                    rules.minLength(6),
                                    rules.maxLength(6)
                                ]"
                                label="2FA-Code"
                                type="number"
                                outlined
                                class="headline"
                                :error-messages="tfaCodeError"
                                hint="Google Authenticator Code"
                                autocomplete="off"
                                @keydown.enter="enable2FA()"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="pb-5">
                <v-spacer></v-spacer>
                <v-btn text rounded @click="closeEnable2FADialog"
                    >Annuleren</v-btn
                >
                <v-btn
                    outlined
                    rounded
                    color="primary"
                    :disabled="!valid"
                    :loading="isVerifying2FA"
                    @click="enable2FA()"
                    >Inschakelen</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>

            <!-- <v-progress-linear
                :active="isGeneratingSecretKey"
                color="primary"
                buffer-value="0"
                indeterminate
                absolute
                bottom
            ></v-progress-linear> -->
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import QrcodeVue from "qrcode.vue";

export default {
    name: "enable-2fa-dialog",
    props: ["dialog"],
    components: {
        QrcodeVue
    },

    apollo: {},

    data: function() {
        return {
            valid: false,
            applicationName: "Mijn Nife",
            disable2FADialog: false,
            isGeneratingSecretKey: false,
            isEnabling2FA: false,
            isVerifying2FA: false,
            isDisabling2FA: false,
            secretKey2FA: "",
            tfaCode: "",
            tfaCodeError: "",
            tfaCodeIsVerified: false,
            password: "",
            showpassword: false,
            disable2FAError: "",

            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                listRequired: v =>
                    (v || "").length >= 1 || `Dit veld is verplicht`,
                minLength: len => v =>
                    (v || "").length >= len || `Require at least ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
                // nameRules: [
                //     v => !!v || "Name is required",
                //     v =>
                //         v.length <= 50 || "Name must be less than 50 characters"
                // ]
            }
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        tfaQrCodeValue() {
            const applicationName = this.applicationName;

            if (!this.me) {
                return "";
            }

            return (
                "otpauth://totp/" +
                applicationName +
                " (" +
                this.me.email +
                ")?secret=" +
                this.secretKey2FA
            );
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                this.generate2faSecretKey();
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        // this.fetchMe();
    },
    methods: {
        // User Profile
        // fetchMe() {
        //     this.$store
        //         .dispatch("user/fetchMe")
        //         .then(() => {})
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },

        generate2faSecretKey() {
            this.isGeneratingSecretKey = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation generate2faSecretKey {
                            generate2faSecretKey(input: {}) {
                                secretKey
                            }
                        }
                    `,
                    // Parameters
                    variables: {}
                })
                .then(response => {
                    // // Result
                    this.secretKey2FA =
                        response.data?.generate2faSecretKey?.secretKey;
                })
                .catch(error => {
                    // Error
                    console.error(error);
                })
                .finally(() => {
                    this.isGeneratingSecretKey = false;
                });
        },

        enable2FA() {
            this.isEnabling2FA = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation enable2fa($input: Enabel2FAInput!) {
                            enable2fa(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            secretKey: this.secretKey2FA,
                            tfaCode: this.tfaCode
                        }
                    }
                })
                .then(response => {
                    // // Result

                    const success = response.data.enable2fa.success;

                    // this.tfaCodeError = JSON.stringify( response.data.verify2fa.success)
                    if (success) {
                        this.tfaCodeIsVerified = true;
                        // this.tfaCodeError = "";

                        // show snackbar
                        const payload = {
                            color: "success",
                            message: "Gefeliciteerd! 2FA is geverifieerd!"
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);

                        this.$emit("callback");
                        this.closeEnable2FADialog();
                    } else {
                        // this.tfaCodeError = "Invalide Ga-code";

                        // show snackbar
                        const payload = {
                            color: "error",
                            message: "2FA-code is ongeldig!"
                        };
                        this.$store.dispatch("snackbar/showMessage", payload);
                    }
                })
                .catch(error => {
                    // Error
                    console.error(error);
                })
                .finally(() => {
                    this.isEnabling2FA = false;
                });
        },

        // verify2FA() {
        //     if (!this.$refs.enable2FAForm.validate()) {
        //         return;
        //     }

        //     this.isVerifying2FA = true;

        //     this.$apollo
        //         .mutate({
        //             // Query
        //             mutation: gql`
        //                 mutation verify2fa($input: Verify2FAInput!) {
        //                     verify2fa(input: $input) {
        //                         success
        //                     }
        //                 }
        //             `,
        //             // Parameters
        //             variables: {
        //                 input: {
        //                     tfaCode: this.tfaCode
        //                 }
        //             }
        //         })
        //         .then(response => {
        //             // // Result
        //             // TODO: show success for 2 seconds, then close dialog
        //             const success = response.data.verify2fa.success;

        //             // this.tfaCodeError = JSON.stringify( response.data.verify2fa.success)
        //             if (success) {
        //                 this.tfaCodeIsVerified = true;
        //                 // this.tfaCodeError = "";

        //                 // show snackbar
        //                 const payload = {
        //                     color: "info",
        //                     message: "Congratulations! 2FA is verified!"
        //                 };
        //                 this.$store.dispatch("snackbar/showMessage", payload);

        //                 this.closeEnable2FADialog();
        //             } else {
        //                 // this.tfaCodeError = "Invalide Ga-code";

        //                 // show snackbar
        //                 const payload = {
        //                     color: "error",
        //                     message: "2FA-Code is invalid!"
        //                 };
        //                 this.$store.dispatch("snackbar/showMessage", payload);
        //             }
        //         })
        //         .catch(error => {
        //             // Error
        //             console.error(error);
        //         })
        //         .finally(() => {
        //             this.isVerifying2FA = false;

        //             // setTimeout(() => {
        //             //     //your code to be executed after 1 second
        //             //     this.isLoadingPlannedTaskPhases = false;
        //             // }, 1000);
        //         });
        // },

        closeEnable2FADialog() {
            this.secretKey2FA = "";
            this.tfaCode = "";
            this.$refs.enable2FAForm.resetValidation();
            this.$emit("update:dialog", false);
        }
    }
};
</script>