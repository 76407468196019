import Vue from "vue";
import Vuex from "vuex";
import snackbar from "./modules/snackbar";
import user from "./modules/user";
// import mapGetters from "vuex"

Vue.use(Vuex);

// const getters = {
//   ...mapGetters("user", "isLoggedIn")
// }

export default new Vuex.Store({
    // getters: getters,
    modules: {
        snackbar,
        user
    }
});
