var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-progress-linear',{attrs:{"color":"white","indeterminate":"","absolute":"","top":"","active":_vm.$apollo.queries.eanElectricity.loading}}),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Verbruiksgegevens downloaden "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("close")])],1)],1),(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-text',{staticClass:"pt-7"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v("bolt")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"outlined":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.ean))])]}}])},[_c('span',[_vm._v("EAN")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("signal_cellular_alt")]),_vm._v(_vm._s(_vm.eanElectricity.meterType))],1)]}}])},[_c('span',[_vm._v("Metertype")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"outlined":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("schedule")]),_vm._v(_vm._s(_vm.eanElectricity.frequency)+" minuten")],1)]}}])},[_c('span',[_vm._v("Frequentie")])])],1)],1),_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"subtitle-2",attrs:{"items":_vm.frequencyList,"label":"Frequentie","hide-details":"","outlined":"","color":"primary","disabled":_vm.$apollo.queries.eanElectricity.loading},model:{value:(_vm.selectedFrequency),callback:function ($$v) {_vm.selectedFrequency=$$v},expression:"selectedFrequency"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"subtitle-2",attrs:{"items":_vm.relativeDateRangeList,"label":"Relatieve datum","hide-details":"","outlined":"","color":"primary","disabled":_vm.$apollo.queries.eanElectricity.loading},model:{value:(_vm.selectedRelativeDateRange),callback:function ($$v) {_vm.selectedRelativeDateRange=$$v},expression:"selectedRelativeDateRange"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"close-on-click":false,"return-value":_vm.savedDateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.savedDateRange=$event},"update:return-value":function($event){_vm.savedDateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"subtitle-2",attrs:{"value":_vm.dateRangeText,"label":"Datumfilter","readonly":"","hide-details":"","outlined":"","disabled":_vm.$apollo.queries.eanElectricity
                                            .loading},on:{"click:clear":function($event){return _vm.onClearDates()}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"range":"","no-title":"","scrollable":"","show-week":"","allowed-dates":_vm.allowedDates,"min":_vm.minDate,"max":_vm.maxDate,"color":"primary"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","rounded":"","color":"primary"},on:{"click":_vm.cancelDatePicker}},[_vm._v(" Annuleren ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.dateRange.length < 2},on:{"click":_vm.saveDatePicker}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"text-center mt-2",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","rounded":"","loading":_vm.isDownloading,"disabled":_vm.dateRange.length < 2},on:{"click":_vm.download}},[_vm._v("Downloaden")])],1),_c('v-col',{staticClass:"mx-0 px-0 pt-0 text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"mx-0 px-0 caption"},[_c('v-icon',{staticClass:"mb-0",attrs:{"left":"","small":""}},[_vm._v("error_outline")]),_vm._v(" Houd rekening met langere downloadtijd bij opvragen van veel data. ")],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }