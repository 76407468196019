<template>
    <v-container class="py-0">
        <v-card elevation="3">
            <v-toolbar flat height="48">
                <v-tabs v-model="tab" class="justify-center">
                    <v-tab>Jaar</v-tab>
                    <v-tab>Maand</v-tab>
                    <v-tab>Dag</v-tab>
                    <v-tab>Periode</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    rounded
                    outlined
                    small
                    @click="downloadDialog = !downloadDialog"
                    >Downloaden</v-btn
                >
            </v-toolbar>
        </v-card>

        <DownloadElektriciteit
            :dialog.sync="downloadDialog"
            :ean="ean"
        ></DownloadElektriciteit>

        <router-view></router-view>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import DownloadElektriciteit from "./DownloadElektriciteit.vue";

export default {
    name: "elektriciteit-verbruik",
    props: [],
    components: { DownloadElektriciteit },

    apollo: {},

    data() {
        return {
            downloadDialog: false,
            tab: null
        };
    },

    computed: {
        ean() {
            return this.$route.params.ean;
        }
    },
    watch: {
        tab(val) {
            switch (val) {
                case 0:
                    this.pushTo("elektriciteitverbruikjaar");
                    break;
                case 1:
                    this.pushTo("elektriciteitverbruikmaand");
                    break;
                case 2:
                    this.pushTo("elektriciteitverbruikdag");
                    break;
                case 3:
                    this.pushTo("elektriciteitverbruikperiode");
                    break;
                default:
                    this.pushTo("elektriciteitverbruikjaar");
            }
            localStorage.measurementFilterTab = val;
        },
        ean() {
            switch (this.tab) {
                case 0:
                    this.pushTo("elektriciteitverbruikjaar");
                    break;
                case 1:
                    this.pushTo("elektriciteitverbruikmaand");
                    break;
                case 2:
                    this.pushTo("elektriciteitverbruikdag");
                    break;
                case 3:
                    this.pushTo("elektriciteitverbruikperiode");
                    break;
                default:
                    this.pushTo("elektriciteitverbruikjaar");
            }
        },
        $route(to) {
            if (to.query.year && to.query.month) {
                this.tab = 1;
            }
            if (to.query.year && to.query.month && to.query.day) {
                this.tab = 2;
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        if (localStorage.measurementFilterTab) {
            this.tab = parseInt(localStorage.measurementFilterTab);
        }
    },
    methods: {}
};
</script>
