<template>
    <v-container fluid class="pa-0 ">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-row class="my-0 px-3 align-end">
                        <v-col cols="auto">
                            <h2 class="display-3 font-weight-medium">
                                Contracten
                            </h2>
                            <!-- <v-progress-linear
                                :active="isLoading"
                                color="primary"
                                buffer-value="0"
                                stream
                                class="mx-0 mt-2 px-0"
                            ></v-progress-linear> -->
                        </v-col>
                        <v-spacer />
                        <!-- <v-col cols="auto" class="mr-auto">
                            <v-tabs v-model="tab" class="justify-center">
                                <v-tab>Elektra</v-tab>
                                <v-tab>Gas</v-tab>
                            </v-tabs>
                        </v-col> -->
                    </v-row>
                </v-card-title>
            </v-card>
        </v-container>

        <!-- Overview  body -->
        <v-card flat color="grey lighten-4 pt-15" min-height="73vh" fill-width>
            <router-view @loading="onLoading"></router-view>
        </v-card>
    </v-container>
</template>

<script>
// import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    title: "Contract",
    components: {},

    apollo: {},

    data() {
        return {
            tab: 0,
            isLoading: false
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        }
    },
    watch: {
        tab(val) {
            switch (val) {
                case 0:
                    this.pushTo("elektracontracten");
                    break;
                case 1:
                    this.pushTo("gascontracten");
                    break;
                default:
                    this.pushTo("allcontracten");
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.pushTo("allcontracten");
    },
    methods: {
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>
