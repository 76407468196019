var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","close-on-content-click":false,"origin":"center center","nudge-bottom":10},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0",attrs:{"icon":""}},on),[_c('v-badge',{attrs:{"content":_vm.unreadNofiticationCount,"value":_vm.unreadNofiticationCount,"color":"warning","overlap":""}},[_c('v-icon',[_vm._v("notifications")])],1)],1)]}}]),model:{value:(_vm.isNotificationCenterOpen),callback:function ($$v) {_vm.isNotificationCenterOpen=$$v},expression:"isNotificationCenterOpen"}},[_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"450px"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.isUnreadNotifiocationFilterEnabled
                                ? 'primary'
                                : 'grey',"disabled":_vm.$apollo.queries.notifications.loading},on:{"click":function($event){return _vm.filterNotifications()}}},on),[_c('v-icon',[_vm._v("filter_list")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isUnreadNotifiocationFilterEnabled ? "Show all" : "Show unread"))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.unreadNofiticationCount == 0 ||
                                _vm.isMarkingAllNotificationsAsRead},on:{"click":function($event){return _vm.markAllNotificationsAsRead()}}},on),[_c('v-icon',[_vm._v("drafts")])],1)]}}])},[_c('span',[_vm._v("Mark all read")])])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-virtual-scroll',{attrs:{"items":_vm.notifications ? _vm.notifications.edges : [],"item-height":80,"height":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var index = ref.index;
                                var item = ref.item;
return [_c('v-list-item',{attrs:{"three-line":"","dense":""}},[_c('v-list-item-icon',{staticClass:"ma-4"},[_c('v-icon',{attrs:{"small":"","color":item.node.isRead
                                        ? 'grey'
                                        : item.node.color || 'primary'}},[_vm._v(" "+_vm._s(item.node.icon || "fiber_manual_record")+" ")])],1),_c('v-list-item-content',{staticClass:"row-pointer",on:{"click":function($event){return _vm.goToNotificationUrl(item.node.url)}}},[_c('v-list-item-title',{staticClass:"subtitle-2",domProps:{"textContent":_vm._s(item.node.subject)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("moment")(item.node.created,"from")))]),_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.node.content)}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":item.node.isRead},on:{"click":function($event){return _vm.markNotificationAsRead(
                                                item.node.id
                                            )}}},on),[_c('v-icon',[_vm._v("drafts")])],1)]}}],null,true)},[_c('span',[_vm._v("Mark read")])])],1)],1),(index == _vm.notifications.edges.length - 1)?_c('v-card',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.loadMoreNotifications),expression:"loadMoreNotifications"}],staticClass:"elevation-0",attrs:{"tile":""}},[(_vm.$apollo.queries.notifications.loading)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1):_vm._e()]}}])})],1),_c('v-progress-linear',{attrs:{"active":_vm.$apollo.queries.notifications.loading,"indeterminate":_vm.$apollo.queries.notifications.loading,"absolute":"","bottom":"","color":"primary","height":"3"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }