<template>
    <v-container fluid class="pa-0 ">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-col cols="auto">
                        <h2 class="display-3 font-weight-medium">Contactgegevens</h2>
                        <!-- <v-progress-linear
                            :active="$apollo.queries.myDetails.loading"
                            color="primary"
                            buffer-value="0"
                            stream
                            class="mx-0 mt-2 px-0"
                        ></v-progress-linear> -->
                    </v-col>
                </v-card-title>
            </v-card>
        </v-container>

        <!-- Gegenvens  body -->
        <v-card flat color="grey lighten-4 pt-0" min-height="73vh" fill-width>
            <v-container>
                <v-card
                    color="transparent"
                    flat
                    class="mx-auto text-center"
                    height="50vh"
                    width="200"
                    v-if="$apollo.queries.myDetails.loading"
                >
                    <v-responsive min-height="20vh"></v-responsive>
                    <div class="grey--text text--darken-2">
                        Een ogenblik geduld aub...
                    </div>
                    <v-row class="justify-center">
                        <v-col cols="12">
                            <v-progress-linear
                                color="primary accent-4"
                                indeterminate
                                rounded
                                height="5"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- no data -->
                <v-card
                    v-else-if="!me.isTenantAdmin && myDetails.accounts.edgeCount == 0"
                    color="transparent"
                    flat
                    class="mx-auto text-center"
                    height="50vh"
                    width="200"
                >
                    <v-responsive min-height="20vh"></v-responsive>
                    <div class="grey--text text--darken-2">
                        Geen gegevens beschikbaar
                    </div>
                </v-card>
                <!-- accounts -->
                <v-row v-else class="pb-5">
                    <!-- main account -->
                    <!-- <v-col
                        v-if="me.isTenantAdmin"
                        cols="12"
                        class="mt-0 mb-0 d-flex align-stretch "
                    >
                        <AccountGegevensCard
                            v-if="myDetails.myTenant.account"
                            :account="myDetails.myTenant.account"
                        ></AccountGegevensCard>
                    </v-col> -->

                    <!-- all accounts -->
                    <v-col
                        cols="12"
                        class="mt-10 mb-0 d-flex align-stretch "
                        v-for="a in myDetails.accounts.edges"
                        :key="a.node.id"
                    >
                        <!-- <v-lazy transition="fade-transition"> -->
                        <AccountGegevensCard
                            v-if="a.node"
                            :account="a.node"
                        ></AccountGegevensCard>
                        <!-- </v-lazy> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import AccountGegevensCard from "../components/gegevens/AccountGegevensCard.vue";

export default {
    title: "Gegevens",
    components: { AccountGegevensCard },

    apollo: {
        myDetails: {
            query: gql`
                fragment contactFields on ContactNode {
                    id
                    fullName
                    email
                    telephone
                }
                query myTenant( $isMainAccount:Boolean, $orderBy: String) {
                    myTenant {
                        id
                        account {
                            id
                            isMainAccount
                            name
                            dynamicsNifenummer
                            customerTypeCode
                            fullAddress
                            generalEmail
                            generalTelephone
                            energyInvoiceContact {
                                ...contactFields
                            }
                            serviceInvoiceContact {
                                ...contactFields
                            }
                            purchaseEnergyContact {
                                ...contactFields
                            }
                            energyInvoiceContact2 {
                                ...contactFields
                            }
                            serviceInvoiceContact2 {
                                ...contactFields
                            }
                            purchaseEnergyContact2 {
                                ...contactFields
                            }
                        }
                    }
                    accounts(first: 200,isMainAccount: $isMainAccount, orderBy: $orderBy) {
                        edgeCount
                        edges {
                            node {
                                id
                                isMainAccount
                                name
                                dynamicsNifenummer
                                customerTypeCode
                                fullAddress
                                generalEmail
                                generalTelephone
                                energyInvoiceContact {
                                    ...contactFields
                                }
                                serviceInvoiceContact {
                                    ...contactFields
                                }
                                purchaseEnergyContact {
                                    ...contactFields
                                }
                                energyInvoiceContact2 {
                                    ...contactFields
                                }
                                serviceInvoiceContact2 {
                                    ...contactFields
                                }
                                purchaseEnergyContact2 {
                                    ...contactFields
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    // isMainAccount: false,
                    orderBy: "dynamicsNifenummer"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            update: data => data,
            skip() {
                return this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
        // subAccounts: {
        //     query: gql`
        //         fragment contactFields on ContactNode {
        //             id
        //             firstName
        //             lastName
        //             fullName
        //             email
        //             telephone
        //         }
        //         query accounts($orderBy: String) {
        //             accounts(orderBy: $orderBy) {
        //                 edges {
        //                     node {
        //                         id
        //                         isMainAccount
        //                         name
        //                         dynamicsNifenummer
        //                         fullAddress
        //                         generalEmail
        //                         generalTelephone
        //                         energyInvoiceContact {
        //                             ...contactFields
        //                         }
        //                         serviceInvoiceContact {
        //                             ...contactFields
        //                         }
        //                         purchaseEnergyContact {
        //                             ...contactFields
        //                         }
        //                         energyInvoiceContact2 {
        //                             ...contactFields
        //                         }
        //                         serviceInvoiceContact2 {
        //                             ...contactFields
        //                         }
        //                         purchaseEnergyContact2 {
        //                             ...contactFields
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     `,
        //     variables() {
        //         return {
        //             orderBy: "dynamicsNifenummer"
        //         };
        //     },
        //     // Additional options here
        //     fetchPolicy: "cache-and-network",
        //     update: data => data.accounts
        //     // skip: true,
        //     // pollInterval: 60000 // ms
        // }
    },

    data() {
        return {
            myDetails: {
                myTenant: {},
                accounts: {}
            }
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {},
    methods: {}
};
</script>