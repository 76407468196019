<template>
    <v-container fluid class="pa-0 "> </v-container>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
    title: "MjinNife",
    components: {},

    apollo: {},

    data() {
        return {
            tab: 0,
            isLoading: false
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        }
    },
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {
        this.fetchMe();
    },
    methods: {
        fetchMe() {
            this.$store
                .dispatch("user/fetchMe")
                .then(() => {
                    try {
                        // google analytics
                        this.$gtag.set("user_properties", {
                            user_email: this.$store.state.user.me.email,
                            user_tenant: this.$store.state.user.me.tenant
                                ? this.$store.state.user.me.tenant.name
                                : null
                        });
                    } catch (err) {
                        console.log(err);
                    }

                    // NIFE Staff
                    if (this.me.isStaff) {
                        this.pushTo("accounts");
                    } else {
                        // normal user
                        this.pushTo("overzicht");
                    }
                })
                .catch(error => {
                    console.error("fetchMe catched error: ", error);
                });
        },
        onLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>