<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col v-if="isVerifyingToken" cols="12">
                <div class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mx-2"
                    ></v-progress-circular>
                </div>
            </v-col>
            <v-col v-else cols="12" sm="8" md="6" lg="4">
                <v-card v-if="!isTokenValid" class="elevation-0 pa-10">
                    <div class="mb-5">
                        <a href="https://www.nife.nl/" target="blank">
                            <v-img
                                :src="
                                    require('../assets/logo-nife-original.png')
                                "
                                max-height="83px"
                                max-width="352px"
                                contain
                            ></v-img>
                        </a>
                    </div>

                    <h3>
                        De link voor het opnieuw instellen van het wachtwoord
                        was ongeldig, mogelijk omdat deze al is gebruikt. Vraag
                        een nieuw wachtwoord reset aan.
                        <br />
                        <br />
                        <a href="/#/login">Inloggen</a>
                    </h3>
                </v-card>

                <v-card v-else-if="isPasswordReset" class="elevation-0 pa-10">
                    <div class="mb-5">
                        <a href="https://www.nife.nl/" target="blank">
                            <v-img
                                :src="require('../assets/logo-nife-original.png')"
                                max-height="83px"
                                max-width="352px"
                                contain
                            ></v-img>
                        </a>
                    </div>
                    <h3>
                        Wachtwoord is gereset!
                        <br />
                        <br />
                        <a href="/#/login">Inloggen</a>
                    </h3>
                </v-card>

                <v-card v-else flat>
                    <div class="pl-5 mb-5">
                        <a href="https://www.nife.nl/" target="blank">
                            <v-img
                                :src="require('../assets/logo-nife-original.png')"
                                max-height="83px"
                                max-width="352px"
                                contain
                            ></v-img>
                        </a>
                    </div>

                    <v-card-title>
                        <h1 class="font-weight-black">
                            Wachtwoord opnieuw <br />instellen
                        </h1>
                    </v-card-title>
                    <v-card-text class="mt-10 pb-0">
                        <v-form ref="resetPasswordForm">
                            <v-text-field
                                id="newPassword"
                                label="Nieuw Wachtwoord"
                                :type="showNewPassword ? 'text' : 'password'"
                                :rules="[
                                    rules.required,
                                    rules.minLength(8),
                                    rules.maxLength(40)
                                ]"
                                outlined
                                v-model="newPassword"
                                :error-messages="resetPasswordError"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="primary"
                                        @click="
                                            showNewPassword = !showNewPassword
                                        "
                                        tabindex="-1"
                                        >{{
                                            showNewPassword
                                                ? "visibility"
                                                : "visibility_off"
                                        }}</v-icon
                                    >
                                </template>
                            </v-text-field>
                            <v-text-field
                                id="newPasswordConfirm"
                                label="Herhaal wachtwoord"
                                :type="showNewPassword ? 'text' : 'password'"
                                :rules="[
                                    rules.required,
                                    rules.minLength(8),
                                    rules.maxLength(40)
                                ]"
                                outlined
                                v-model="newPasswordConfirm"
                                :error-messages="resetPasswordError"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="primary"
                                        @click="
                                            showNewPassword = !showNewPassword
                                        "
                                        tabindex="-1"
                                        >{{
                                            showNewPassword
                                                ? "visibility"
                                                : "visibility_off"
                                        }}</v-icon
                                    >
                                </template></v-text-field
                            >
                        </v-form>
                    </v-card-text>

                    <v-card-actions class="pb-5">
                        <v-spacer></v-spacer>
                        <v-btn
                            rounded
                            :disabled="
                                newPassword.length == 0 ||
                                    newPassword !== newPasswordConfirm
                            "
                            :loading="isResettingPassword"
                            color="primary"
                            width="400"
                            @click="resetPassword()"
                            >Opslaan</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
export default {
    title: "Wachtwoord opnieuw instellen",
    data: function() {
        return {
            isVerifyingToken: true,
            isTokenValid: false,
            isPasswordReset: false,

            isResettingPassword: false,
            newPassword: "",
            newPasswordConfirm: "",
            showNewPassword: "",
            resetPasswordError: "",

            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                listRequired: v =>
                    (v || "").length >= 1 || `Dit veld is verplicht`,
                minLength: len => v =>
                    (v || "").length >= len || `Require at least ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
                // nameRules: [
                //     v => !!v || "Name is required",
                //     v =>
                //         v.length <= 50 || "Name must be less than 50 characters"
                // ]
            }
        };
    },
    computed: {
        uidb64() {
            return this.$route.params.uidb64;
        },
        token() {
            return this.$route.params.token;
        }
    },
    created() {
        this.verifyResetPasswordToken();
    },
    methods: {
        verifyResetPasswordToken() {
            this.isVerifyingToken = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation verifyResetPasswordToken(
                            $input: VerifyResetPasswordTokenInput!
                        ) {
                            verifyResetPasswordToken(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            uidb64: this.uidb64,
                            token: this.token
                        }
                    }
                })
                .then(response => {
                    this.isTokenValid =
                        response.data.verifyResetPasswordToken.success;
                    // console.log(this.isTokenValid);
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            this.requestPasswordResetError = err.message;
                        }
                    }
                })
                .finally(() => {
                    this.isVerifyingToken = false;
                });
        },

        logout() {
            this.$store
                .dispatch("user/logout")
                .then(() => {
                    this.drawer = false;
                    this.pushTo("login");
                })
                .catch(error => {
                    console.log("Logout failed", error);
                });
        },

        resetPassword() {
            if (!this.$refs.resetPasswordForm.validate()) {
                return;
            }

            this.isResettingPassword = true;

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation resetPassword($input: ResetPasswordInput!) {
                            resetPassword(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: {
                            uidb64: this.uidb64,
                            token: this.token,
                            password: this.newPassword
                        }
                    }
                })
                .then(response => {
                    // console.log(response);
                    this.isPasswordReset = response.data.resetPassword.success;
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        this.requestPasswordResetError =
                            error.graphQLErrors[0].message;
                    }
                })
                .finally(() => {
                    this.isResettingPassword = false;
                });
        }
    }
};
</script>
