<template>
    <v-app id="app">
        <!-- Notification -->
        <Snackbar></Snackbar>

        <v-app-bar
            v-if="isLoggedIn && isLoggedInAsOtherUser"
            app
            color="primary"
            dark
            dense
        >
            <v-container>
                <v-row class="align-center mx-0">
                    <span> Ingelogd als </span>
                    <v-chip color="white" outlined class="ml-2">
                        <strong class="text-truncate" max-width="50">
                            {{ me.firstName }} {{ me.lastName }}</strong
                        >
                        <span class="ml-2 d-none d-md-block">
                            ({{ me.email }})</span
                        >
                    </v-chip>
                    <v-spacer />
                    <v-btn
                        dark
                        color="pink"
                        class="ml-2"
                        @click="logoutAsOtherUser"
                        >Exit</v-btn
                    >
                </v-row>
            </v-container>
        </v-app-bar>

        <!-- body -->
        <v-main>
            <!-- require 2FA warning -->
            <v-alert
                v-if="me.require2fa && !me.enabled2fa"
                class="mb-0"
                color="red lighten-1"
                dark
                tile
                dense
                transition="scale-transition"
            >
                <v-container class="py-0 px-6">
                    <v-icon left>warning</v-icon>
                    2FA is vereist, ga naar "INSTELLINGEN" om het in te
                    schakelen.
                </v-container>
            </v-alert>

            <!-- navigation bar -->
            <v-container v-show="isLoggedIn" flat color="white" class="py-0">
                <v-row class="ma-0 align-center hidden-lg-and-up">
                    <v-col cols="auto" class="py-1">
                        <a href="/" target="_blank">
                            <!--https://portal.nife.nl/themes/nife_portal/logo.svg -->
                            <v-img
                                :src="
                                    require('@/assets/logo-nife-lg-notext.png')
                                "
                                max-width="100px"
                                max-height="41px"
                                contain
                                class="mt-3"
                            ></v-img>
                        </a>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto" class="pt-5">
                        <v-tooltip bottom v-if="!me.isStaff">
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="grey darken-1"
                                    class="text-capitalize"
                                    @click="pushTo('instellingen')"
                                    ><v-icon>settings</v-icon></v-btn
                                >
                            </template>
                            <span>Instellingen</span>
                        </v-tooltip>
                        <!-- Notifications -->
                        <Notifications v-if="isLoggedIn"></Notifications>
                        <v-tooltip bottom>
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="grey darken-1"
                                    class="text-capitalize"
                                    @click="logout"
                                    ><v-icon>logout</v-icon></v-btn
                                >
                            </template>
                            <span>Uitloggen</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <v-row class="ma-0 align-center">
                    <v-col cols="auto" class="py-1 hidden-md-and-down">
                        <a href="/">
                            <!--https://portal.nife.nl/themes/nife_portal/logo.svg -->
                            <v-img
                                :src="
                                    require('@/assets/logo-nife-lg-notext.png')
                                "
                                max-width="100px"
                                max-height="41px"
                                contain
                                class="mt-3"
                            ></v-img>
                        </a>
                    </v-col>

                    <v-col cols="auto">
                        <!-- <v-sheet class="mx-auto" max-width="800"> -->
                        <v-tabs
                            v-if="me.isStaff"
                            v-model="staffTab"
                            center-active
                            class="justify-center mt-3"
                            hide-details
                            show-arrows
                        >
                            <v-tab @click="pushTo('accounts')"
                                ><v-icon left>home</v-icon>Accounts</v-tab
                            >
                            <v-tab @click="pushTo('instellingen')"
                                >Instellingen</v-tab
                            >
                        </v-tabs>

                        <v-tabs
                            v-if="me.isTenantStaff"
                            v-model="userTab"
                            center-active
                            class="justify-center mt-3"
                            hide-details
                            show-arrows
                        >
                            <v-tab @click="pushTo('overzicht')"
                                ><v-icon left>home</v-icon>Overzicht</v-tab
                            >
                            <v-tab
                                v-show="
                                    me.isTenantAdmin ||
                                        hasPermission(
                                            'energy.view_energyinvoice'
                                        ) ||
                                        hasPermission(
                                            'energy.view_serviceinvoice'
                                        )
                                "
                                @click="pushTo('energiefacturen')"
                                >Facturen</v-tab
                            >
                            <v-tab
                                v-show="
                                    me.isTenantAdmin ||
                                        hasPermission(
                                            'energy.view_electricitymeasurement'
                                        )
                                "
                                @click="pushTo('verbruik')"
                                >Verbruik</v-tab
                            >
                            <v-tab
                                v-show="
                                    me.isTenantAdmin ||
                                        hasPermission(
                                            'energy.view_energycontract'
                                        )
                                "
                                @click="pushTo('allcontracten')"
                                >Contracten</v-tab
                            >
                            <v-tab
                                v-show="
                                    me.isTenantAdmin ||
                                        hasPermission('energy.view_contact')
                                "
                                @click="pushTo('gegevens')"
                                >Contactgegevens</v-tab
                            >
                            <v-tab @click="pushTo('marktprijzen')"
                                >MarktPrijzen</v-tab
                            >
                            <!-- hidden setting tab -->
                            <v-tab class="pa-0 ma-0" style="min-width:0px" />
                            <!-- <v-tab @click="pushTo('instellingen')"
                                >Instellingen</v-tab
                            > -->
                        </v-tabs>
                        <!-- </v-sheet> -->
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto" class="pt-5 px-2 hidden-md-and-down">
                        <v-tooltip bottom v-if="!me.isStaff">
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="grey darken-1"
                                    class="text-capitalize"
                                    @click="pushTo('instellingen')"
                                    ><v-icon>settings</v-icon></v-btn
                                >
                            </template>
                            <span>Instellingen</span>
                        </v-tooltip>
                        <!-- Notifications -->
                        <Notifications v-if="isLoggedIn"></Notifications>
                        <v-tooltip bottom>
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="grey darken-1"
                                    class="text-capitalize"
                                    @click="logout"
                                    ><v-icon>logout</v-icon></v-btn
                                >
                            </template>
                            <span>Uitloggen</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-container>

            <v-divider
                class="mt-3"
                v-show="isLoggedIn"
                flat
                color="white"
            ></v-divider>

            <!-- Router view -->
            <router-view></router-view>

            <!-- FEEDBACK BUTTON -->
            <v-btn
                v-show="isLoggedIn && me.isTenantStaff"
                dark
                fixed
                bottom
                left
                tile
                color="grey"
                class="py-1"
                :style="{
                    top: '50%',
                    left: '-52px',
                    transform: 'rotate(90deg)'
                }"
                @click="feedbackFormDialog = !feedbackFormDialog"
            >
                <v-icon left>feedback</v-icon> Feedback
            </v-btn>

            <!-- HELP BUTTON -->
            <v-btn
                v-show="isLoggedIn && me.isTenantStaff"
                dark
                fixed
                bottom
                right
                rounded
                color="warning"
                class="py-1"
                :style="{
                    bottom: '40px',
                    right: '10px'
                }"
                @click="tutorialsDialog = !tutorialsDialog"
            >
                <v-icon left>help</v-icon> Help
            </v-btn>

            <!-- TODO: HELP BUTTON -->
            <!-- <v-btn
                v-show="isLoggedIn && me.isTenantStaff"
                rounded
                dark
                fixed
                bottom
                right
                color="primary"
                class="mb-7"
            >
                <v-icon left>help_outline</v-icon> Help
            </v-btn> -->
        </v-main>

        <FeedbackForm :dialog.sync="feedbackFormDialog"></FeedbackForm>

        <Tutorials
            v-if="tutorialsDialog"
            :dialog.sync="tutorialsDialog"
        ></Tutorials>

        <!-- footer -->
        <v-footer padless>
            <v-card class="flex" flat tile>
                <v-card-text class="py-2 grey--text text-center">
                    <strong>Disclaimer:</strong> Aan geen van de op deze portal
                    getoonde gegevens kunnen rechten worden ontleend. Wij
                    behouden ons het recht voor om deze gegevens te wijzigen
                    zonder voorafgaande mededeling.
                    <!-- {{ new Date().getFullYear() }} — <strong>Nife</strong> — v1.0.1 -->
                </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
// import gql from "graphql-tag";
import Config from "@/utils/config.json";
import helper from "@/utils/helper.js";
import Snackbar from "@/components/base/Snackbar.vue";
import Notifications from "@/components/base/Notifications.vue";
import FeedbackForm from "@/components/base/FeedbackForm.vue";
import Tutorials from "@/components/support/Tutorials.vue";

export default {
    name: "App",
    components: {
        Snackbar,
        Notifications,
        FeedbackForm,
        Tutorials
    },

    apollo: {},

    data() {
        return {
            userTab: null,
            staffTab: null,
            drawer: false,
            feedbackFormDialog: false,
            tutorialsDialog: false,
            isLoggingOutAsOtherUser: false
        };
    },

    computed: {
        APPLICATION_NAME() {
            return Config.APPLICATION_NAME;
        },
        isLoggedIn() {
            return this.$store.state.user.loggedIn;
        },
        isLoggedInAsOtherUser() {
            return this.$store.state.user.loggedInAsOtherUser;
        },
        today() {
            var _today = new Date();
            _today.setHours(23, 59, 59, 59); // remove time

            return _today;
        },
        me() {
            return this.$store.state.user.me || {};
        },
        routeName() {
            return this.$route.name;
        }
    },
    watch: {
        isLoggedIn(val) {
            if (val) {
                // this.userTab = 0;
                // this.staffTab = 0;
                // fetch me
                this.fetchMe();
            }
        },
        isLoggedInAsOtherUser(val) {
            if (val) {
                // this.userTab = 0;
                this.fetchMe();
            }
        },
        routeName() {
            this.updateTab();
        }
        // userTab(val) {
        //     if (this.isLoggedIn && !this.me.isStaff) {
        //         switch (val) {
        //             case 0:
        //                 this.pushTo("overzicht");
        //                 break;
        //             case 1:
        //                 this.pushTo("facturen");
        //                 break;
        //             case 2:
        //                 this.pushTo("verbruik");
        //                 break;
        //             case 3:
        //                 this.pushTo("contracten");
        //                 break;
        //             case 4:
        //                 this.pushTo("gegevens");
        //                 break;
        //             case 5:
        //                 this.pushTo("instellingen");
        //                 break;
        //             default:
        //                 this.pushTo("overzicht");
        //         }
        //     }
        // },
        // staffTab(val) {
        //     if (this.isLoggedIn && this.me.isStaff) {
        //         switch (val) {
        //             case 0:
        //                 this.pushTo("accounts");
        //                 break;
        //             case 1:
        //                 this.pushTo("instellingen");
        //                 break;
        //             default:
        //                 this.pushTo("accounts");
        //         }
        //     }
        // }
    },

    created() {
        // copy hasPermission from helper
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
        this.updateTab();
    },
    mounted() {
        if (this.isLoggedIn) {
            // fetch me
            this.fetchMe();
        } else {
            // this.fetchPublicSystemProfile();
        }

        this.updateTab();
    },
    methods: {
        updateTab() {
            this.$nextTick(() => {
                const userPages = [
                    "overzicht",
                    "facturen",
                    "energiefacturen",
                    "dienstfacturen",
                    "verbruik",
                    "elektriciteitverbruikjaar",
                    "elektriciteitverbruikmaand",
                    "elektriciteitverbruikdag",
                    "gasverbruikjaar",
                    "gasverbruikmaand",
                    "gasverbruikdag",
                    "contracten",
                    "elektracontracten",
                    "gascontracten",
                    "allcontracten",
                    "gegevens",
                    "gebruikers",
                    "groepen"
                ];

                if (this.me.isStaff && userPages.indexOf(this.routeName) > -1) {
                    this.pushTo("accounts");
                }

                switch (this.$route.name) {
                    case "overzicht":
                        this.userTab = 0;
                        this.staffTab = 0;
                        break;
                    case "facturen":
                        this.userTab = 1;
                        this.staffTab = 0;
                        break;
                    case "energiefacturen":
                        this.userTab = 1;
                        this.staffTab = 0;
                        break;
                    case "dienstfacturen":
                        this.userTab = 1;
                        this.staffTab = 0;
                        break;
                    case "verbruik":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "elektriciteitverbruikjaar":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "elektriciteitverbruikmaand":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "elektriciteitverbruikdag":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "gasverbruikjaar":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "gasverbruikmaand":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "gasverbruikdag":
                        this.userTab = 2;
                        this.staffTab = 0;
                        break;
                    case "contracten":
                        this.userTab = 3;
                        this.staffTab = 0;
                        break;
                    case "elektracontracten":
                        this.userTab = 3;
                        this.staffTab = 0;
                        break;
                    case "gascontracten":
                        this.userTab = 3;
                        this.staffTab = 0;
                        break;
                    case "allcontracten":
                        this.userTab = 3;
                        this.staffTab = 0;
                        break;
                    case "gegevens":
                        this.userTab = 4;
                        this.staffTab = 0;
                        break;
                    case "marktprijzen":
                        this.userTab = 5;
                        this.staffTab = 0;
                        break;
                    case "instellingen":
                        this.userTab = 6;
                        this.staffTab = 1;
                        break;
                    case "mijngegevens":
                        this.userTab = 6;
                        this.staffTab = 1;
                        break;
                    case "gebruikers":
                        this.userTab = 6;
                        this.staffTab = 1;
                        break;
                    case "groepen":
                        this.userTab = 6;
                        this.staffTab = 1;
                        break;
                    case "accounts":
                        this.userTab = 0;
                        this.staffTab = 0;
                        break;
                    case "staffs":
                        this.userTab = 6;
                        this.staffTab = 1;
                        break;
                    default:
                        break;
                }
            });
        },
        fetchMe() {
            this.$store
                .dispatch("user/fetchMe")
                .then(() => {
                    try {
                        // google analytics
                        this.$gtag.set("user_properties", {
                            user_email: this.$store.state.user.me.email,
                            user_tenant: this.$store.state.user.me.tenant
                                ? this.$store.state.user.me.tenant.name
                                : null
                        });
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch(error => {
                    console.log("fetchMe catched error: ", error);
                });
        },

        logoutAsOtherUser() {
            this.isLoggingOutAsOtherUser = true;

            this.$store
                .dispatch("user/logoutAsOtherUser")
                .then(() => {
                    if (this.$route.name != "home") {
                        this.$router.push(this.$route.query.redirect || "/");
                    } else {
                        this.fetchMe();
                    }

                    // clean localStorage
                    localStorage.removeItem("measurementFilterTab");
                    localStorage.removeItem("measurementFilterYear");
                    localStorage.removeItem("measurementFilterMonth");
                    localStorage.removeItem("measurementFilterDate");

                    this.updateTab();
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        for (let err of error.graphQLErrors) {
                            // show snackbar
                            const payload = {
                                color: "error",
                                message: err.message
                            };
                            this.$store.dispatch(
                                "snackbar/showMessage",
                                payload
                            );
                        }
                    }
                })
                .finally(() => {
                    this.isLoggingOutAsOtherUser = false;
                });
        },

        logout() {
            this.$store
                .dispatch("user/logout")
                .then(() => {
                    this.drawer = false;
                    this.pushTo("login");
                })
                .catch(error => {
                    console.log("Logout failed", error);
                });
        }
    }
};
</script>

<style>
#app {
    background-color: var(--v-background-base);
}
</style>
