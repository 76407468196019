import store from "../store/index.js";

export default {
    typeOf(obj) {
        return {}.toString
            .call(obj)
            .split(" ")[1]
            .slice(0, -1)
            .toLowerCase();
    },

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    isEmpty(input) {
        // array
        if (this.typeOf(input) === "array") {
            return input.length === 0;
        }

        // int
        if (this.typeOf(input) === "number") {
            if (input === 0) {
                return false;
            }

            return !input;
        }

        // undefined, null, string, object
        return !input || Object.keys(input).length === 0;
    },

    hasPermission(codename) {
        if (!store.state.user.me) {
            return false;
        }

        const _permissions = store.state.user.me.allPermissions || [];

        let _check_permission = _permissions.includes(codename);

        return _check_permission ? true : false;
        // return codename;
    },

    pushTo(routeName) {
        if (this.$router.currentRoute.name !== routeName) {
            this.$router.push({
                name: routeName,
                query: this.$router.currentRoute.query
            });
        }
    },

    convertToCSV(objArray) {
        var array =
            typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        var str = "";

        for (var i = 0; i < array.length; i++) {
            var line = "";
            for (var index in array[i]) {
                if (line != "") line += ";";

                line += array[i][index];
            }

            str += line + "\r\n";
        }

        return str;
    },

    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);

        csv = "sep=;\r\n" + csv;

        //TODO: add sep=, in first line

        var exportedFilenmae = fileTitle + ".csv" || "export.csv";

        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
};
