<template>
    <!-- Edit profile -->
    <v-dialog v-model="dialog" persistent width="600px" scrollable>
        <v-card>
            <v-card-title class="primary white--text">
                {{ formTitle }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-alert v-if="error" tile type="error" class="mb-0">{{
                error
            }}</v-alert>

            <v-card-text class="pt-10 pb-3">
                <v-form v-model="valid" ref="form">
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="content"
                                outlined
                                name="input"
                                auto-grow
                                rows="4"
                                row-height="40"
                                :rules="[rules.required, rules.maxLength(5000)]"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn :disabled="isSaving" text rounded @click="closeDialog"
                    >Annuleren</v-btn
                >
                <v-btn
                    outlined
                    rounded
                    :disabled="!valid"
                    :loading="isSaving"
                    color="primary"
                    @click="send"
                    >Versturen</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "feedback-form",
    props: ["dialog"],
    data() {
        return {
            valid: false,
            error: null,
            isSaving: false,

            content: null,
            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                minLength: len => v =>
                    (v || "").length >= len ||
                    `Invalid character length, required ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
            }
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        formTitle() {
            return "Feedback";
        }
    },
    watch: {
        // reset form when dialog open or close
        dialog() {
            this.resetForm();
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    methods: {
        resetForm() {
            // reset form state
            this.error = null;
            this.isSaving = false;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }

            this.content = null;
        },

        closeDialog() {
            this.$emit("update:dialog", false);
            this.resetForm();
        },

        send() {
            if (!this.$refs.form.validate()) {
                return;
            }

            // set form state
            this.error = null;
            this.isSaving = true;

            // prepare api call payload
            var payload = { content: this.content };

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation sendFeedback($input: SendFeedbackInput!) {
                            sendFeedback(input: $input) {
                                success
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    this.closeDialog();
                    this.$emit("callback");
                    this.$emit("done");

                    // show snackbar
                    const payload = {
                        color: "success",
                        message: "Bedankt! We hebben je feedback ontvangen!"
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        // this.error = error.graphQLErrors[0].message;
                        console.error(error.graphQLErrors[0].message);
                        // this.error =
                        //     "Er is iets misgegaan, probeer het later opnieuw.";
                        this.error = error.graphQLErrors[0].message;
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                });
        }
    }
};
</script>