<template>
    <v-dialog v-model="dialog" persistent max-width="500px" scrollable>
        <v-card elevation="3">
            <v-progress-linear
                color="white"
                indeterminate
                absolute
                top
                :active="$apollo.queries.eanGas.loading"
            ></v-progress-linear>

            <v-card-title class="primary white--text">
                Verbruiksgegevens downloaden
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog"
                    ><v-icon>close</v-icon></v-btn
                >
            </v-card-title>
            <v-alert v-if="error" tile type="error" class="mb-0">{{
                error
            }}</v-alert>

            <v-card-text class="pt-7">
                <v-row class="align-center">
                    <v-col cols="12">
                        <v-icon color="warning" class="mr-1"
                            >local_fire_department</v-icon
                        >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip outlined v-bind="attrs" v-on="on">{{
                                    ean
                                }}</v-chip>
                            </template>
                            <span>EAN</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    outlined
                                    class="ml-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    ><v-icon left small
                                        >signal_cellular_alt</v-icon
                                    >{{ eanGas.meterType }}</v-chip
                                >
                            </template>
                            <span>Metertype</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    outlined
                                    class="ml-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    ><v-icon left small>schedule</v-icon
                                    >{{ eanGas.frequency }} minuten</v-chip
                                >
                            </template>
                            <span>Frequentie</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="12">
                        <p>
                            dummy text
                        </p>
                    </v-col>
                </v-row> -->
                <v-form v-model="valid" ref="form" class="mt-5">
                    <v-row class="mt-2">
                        <v-col cols="12">
                            <v-select
                                v-model="selectedFrequency"
                                :items="frequencyList"
                                label="Frequentie"
                                hide-details
                                outlined
                                color="primary"
                                class="subtitle-2"
                                :disabled="$apollo.queries.eanGas.loading"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="selectedRelativeDateRange"
                                :items="relativeDateRangeList"
                                label="Relatieve datum"
                                hide-details
                                outlined
                                color="primary"
                                class="subtitle-2"
                            ></v-select
                        ></v-col>
                        <v-col cols="12">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :close-on-click="false"
                                :return-value.sync="savedDateRange"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="dateRangeText"
                                        label="Datumfilter"
                                        readonly
                                        hide-details
                                        outlined
                                        class="subtitle-2"
                                        @click:clear="onClearDates()"
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateRange"
                                    :first-day-of-week="1"
                                    range
                                    no-title
                                    scrollable
                                    show-week
                                    :allowed-dates="allowedDates"
                                    :min="minDate"
                                    :max="maxDate"
                                    color="primary"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        rounded
                                        color="primary"
                                        @click="cancelDatePicker"
                                    >
                                        Annuleren
                                    </v-btn>
                                    <v-btn
                                        outlined
                                        rounded
                                        color="primary"
                                        :disabled="dateRange.length < 2"
                                        @click="saveDatePicker"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" class="text-center mt-2">
                            <v-btn
                                color="primary"
                                outlined
                                rounded
                                :loading="isDownloading"
                                :disabled="dateRange.length < 2"
                                @click="download"
                                >Downloaden</v-btn
                            >
                        </v-col>
                        <v-col cols="12" class="mx-0 px-0 pt-0 text-center">
                            <span class="mx-0 px-0 caption">
                                <v-icon left small class="mb-0"
                                    >error_outline</v-icon
                                >
                                Houd rekening met langere downloadtijd bij
                                opvragen van veel data.
                            </span>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import moment from "moment";

export default {
    name: "download-gas",
    props: ["dialog", "ean"],
    components: {},

    apollo: {
        eanGas: {
            query: gql`
                query eanGases($eanIexact: String, $first: Int) {
                    eanGases(ean_Iexact: $eanIexact, first: $first) {
                        edges {
                            node {
                                id
                                meterTypeCode
                                meterType
                                measurementCompany
                                frequency
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: 1,
                    eanIexact: this.ean
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.eanGases?.edges?.[0]?.node,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            result({ data, loading }) {
                //
                if (!loading && data.eanGases?.edges?.[0]) {
                    var eanGas = data.eanGases?.edges?.[0]?.node;

                    // config frequency list filter
                    if (eanGas) {
                        if (eanGas.frequency == 5) {
                            this.frequencyList = this.defaultFrequencyList.slice(
                                0,
                                5
                            );
                        } else if (eanGas.frequency <= 15) {
                            this.frequencyList = this.defaultFrequencyList.slice(
                                0,
                                4
                            );
                        } else if (eanGas.frequency <= 60) {
                            this.frequencyList = this.defaultFrequencyList.slice(
                                0,
                                3
                            );
                        } else {
                            this.frequencyList = this.defaultFrequencyList.slice(
                                0,
                                3
                            );
                        }
                    }

                    // set yesterday as default filter
                    this.selectedFrequency = this.frequencyList[0];
                }
            },
            debounce: 500,
            skip() {
                return !this.dialog;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            menu: false,
            dateRange: [],
            savedDateRange: [],
            minDate: null,
            maxDate: null,

            valid: false,
            error: null,
            isDownloading: false,
            eanGas: {},

            skipWatchSelectedRelativeDateRange: false,

            selectedFrequency: null,
            defaultFrequencyList: [
                "Maand",
                "Dag",
                "Uur",
                "Kwartier",
                "5 Minuten"
            ],
            frequencyList: [],

            selectedRelativeDateRange: null,
            relativeDateRangeListChoices: [
                // month:
                [
                    "Afgelopen 3 maanden",
                    "Afgelopen 6 maanden",
                    "Afgelopen 12 maanden",
                    "Afgelopen 24 maanden",
                    "Aangepaste selectie"
                ],
                // day:
                [
                    "Afgelopen 30 dagen",
                    "Afgelopen 60 dagen",
                    "Afgelopen 90 dagen",
                    "Afgelopen 120 dagen",
                    "Afgelopen 240 dagen",
                    "Afgelopen 360 dagen",
                    "Aangepaste selectie"
                ],
                // hour:
                [
                    "Afgelopen 3 dagen",
                    "Afgelopen 7 dagen",
                    "Afgelopen 10 dagen",
                    "Afgelopen 30 dagen",
                    "Aangepaste selectie"
                ],
                // quarter:
                [
                    "Afgelopen 3 dagen",
                    "Afgelopen 7 dagen",
                    "Afgelopen 10 dagen",
                    "Aangepaste selectie"
                ],
                // 5 minuten:
                [
                    "Afgelopen 3 dagen",
                    "Afgelopen 7 dagen",
                    "Afgelopen 10 dagen",
                    "Aangepaste selectie"
                ]
            ],

            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                minLength: len => v =>
                    (v || "").length >= len ||
                    `Invalid character length, required ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
            }
        };
    },

    computed: {
        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },
        relativeDateRangeList() {
            var _frequencyIndex = this.defaultFrequencyList.indexOf(
                this.selectedFrequency
            );

            return this.relativeDateRangeListChoices[_frequencyIndex];
        }
    },
    watch: {
        dialog() {
            this.resetForm();
        },

        selectedFrequency(val) {
            switch (this.frequencyList.indexOf(val)) {
                case 0:
                    // month
                    this.selectedRelativeDateRange = this.relativeDateRangeList[0];

                    this.minDate = "2020-01-01";
                    this.maxDate = moment().format("YYYY-MM-DD");
                    break;
                case 1:
                    // day
                    this.selectedRelativeDateRange = this.relativeDateRangeList[0];

                    this.minDate = "2020-01-01";
                    this.maxDate = moment().format("YYYY-MM-DD");
                    break;
                case 2:
                    // hourly
                    this.selectedRelativeDateRange = this.relativeDateRangeList[0];

                    this.minDate = "2020-01-01";
                    this.maxDate = moment().format("YYYY-MM-DD");
                    break;
                case 3:
                    // quarterly
                    this.selectedRelativeDateRange = this.relativeDateRangeList[0];

                    this.minDate = moment()
                        .add(-2, "year")
                        .format("YYYY-MM-DD");
                    this.maxDate = moment().format("YYYY-MM-DD");
                    break;
                case 4:
                    // 5 min
                    this.selectedRelativeDateRange = this.relativeDateRangeList[0];

                    this.minDate = moment()
                        .add(-1, "year")
                        .format("YYYY-MM-DD");
                    this.maxDate = moment().format("YYYY-MM-DD");
                    break;
                default:
            }
        },

        selectedRelativeDateRange(val) {
            if (this.skipWatchSelectedRelativeDateRange) {
                this.skipWatchSelectedRelativeDateRange = false;
                return;
            }

            const _frequencyIndex = this.frequencyList.indexOf(
                this.selectedFrequency
            );

            const _selectedRelativeDateRangeIndex = this.relativeDateRangeList.indexOf(
                val
            );

            let _startDate = null;
            let _endDate = null;

            switch (_frequencyIndex) {
                case 0:
                    // month
                    switch (_selectedRelativeDateRangeIndex) {
                        // "Afgelopen 3 maand",
                        case 0:
                            _endDate = moment()
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-3, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 6 maanden",
                        case 1:
                            _endDate = moment()
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-6, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 12 maanden",
                        case 2:
                            _endDate = moment()
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-12, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 24 maanden",
                        case 3:
                            _endDate = moment()
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-24, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Aangepaste datumfilter"
                        case 4:
                            this.dateRange = [];
                            this.savedDateRange = [];
                            break;
                        default:
                            this.dateRange = [];
                            this.savedDateRange = [];
                    }

                    break;
                case 1:
                    // day
                    switch (_selectedRelativeDateRangeIndex) {
                        // "Afgelopen 30 dagen",
                        case 0:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-31, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 60 dagen",
                        case 1:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-61, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 90 dagen",
                        case 2:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-91, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 120 dagen",
                        case 3:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-121, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 240 dagen",
                        case 4:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-241, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 360 dagen",
                        case 5:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-361, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Aangepaste datumfilter"
                        case 6:
                            this.dateRange = [];
                            this.savedDateRange = [];
                            break;
                        default:
                            this.dateRange = [];
                            this.savedDateRange = [];
                    }
                    break;
                case 2:
                    // hourly
                    switch (_selectedRelativeDateRangeIndex) {
                        // "Afgelopen 3 dagen",
                        case 0:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-4, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 7 dagen",
                        case 1:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-8, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 10 dagen",
                        case 2:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-11, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 30 dagen",
                        case 3:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-31, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Aangepaste datumfilter"
                        case 4:
                            this.dateRange = [];
                            this.savedDateRange = [];
                            break;
                        default:
                            this.dateRange = [];
                            this.savedDateRange = [];
                    }
                    break;
                case 3:
                    // quarterly
                    switch (_selectedRelativeDateRangeIndex) {
                        // "Afgelopen 3 dagen",
                        case 0:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-4, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 7 dagen",
                        case 1:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-8, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 10 dagen",
                        case 2:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-11, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Aangepaste datumfilter"
                        case 3:
                            this.dateRange = [];
                            this.savedDateRange = [];
                            break;
                        default:
                            this.dateRange = [];
                            this.savedDateRange = [];
                    }
                    break;
                case 4:
                    // 5 min
                    switch (_selectedRelativeDateRangeIndex) {
                        // "Afgelopen 3 dagen",
                        case 0:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-4, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 7 dagen",
                        case 1:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-8, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Afgelopen 10 dagen",
                        case 2:
                            _endDate = moment().format("YYYY-MM-DD");
                            _startDate = moment()
                                .add(-11, "day")
                                .format("YYYY-MM-DD");
                            this.dateRange = [_startDate, _endDate];
                            this.savedDateRange = [_startDate, _endDate];
                            break;
                        // "Aangepaste datumfilter"
                        case 3:
                            this.dateRange = [];
                            this.savedDateRange = [];
                            break;
                        default:
                            this.dateRange = [];
                            this.savedDateRange = [];
                    }
                    break;
                default:
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
        this.convertToCSV = helper.convertToCSV;
        this.exportCSVFile = helper.exportCSVFile;
    },
    mounted() {
        this.selectedFrequency = this.frequencyList[0];
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}-${month}-${year}`;
        },
        allowedDates(val) {
            switch (this.frequencyList.indexOf(this.selectedFrequency)) {
                case 0:
                    // month, only show 1st of month
                    return parseInt(val.split("-")[2]) == 1;
                case 1:
                    // day
                    return true;
                case 2:
                    // hourly
                    return true;
                case 3:
                    // quarterly
                    return true;
                case 4:
                    // 5 min
                    return true;
                default:
                    return true;
            }
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.dateRange.length == 1) {
                this.dateRange = this.savedDateRange;
            }
        },
        saveDatePicker() {
            this.$refs.menu.save(this.dateRange.sort());
            this.skipWatchSelectedRelativeDateRange = true;
            this.selectedRelativeDateRange = this.relativeDateRangeList[
                this.relativeDateRangeList.length - 1
            ];
            // this.menu = false;
            // this.savedDateRange = this.dateRange.sort();

            // console.log("this.dateRange: ", this.dateRange);
            // console.log("this.savedDateRange: ", this.savedDateRange);
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
        },
        resetForm() {
            // reset form state
            this.error = null;
            this.isDownloading = false;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            // reset data
            this.eanGas = {};

            //
        },
        closeDialog() {
            this.$emit("update:dialog", false);
            this.resetForm();
        },

        download() {
            switch (this.frequencyList.indexOf(this.selectedFrequency)) {
                case 0:
                    // month
                    this.getMonthlyMeasurements();
                    break;
                case 1:
                    // day
                    this.getDailyMeasurements();
                    break;
                case 2:
                    // hourly
                    this.getHourlyMeasurements();
                    break;
                case 3:
                    // quarterly
                    this.getQuarterlyMeasurements();
                    break;
                case 4:
                    // 5 minutes
                    this.getMeasurements();
                    break;
                default:
            }
        },

        getMeasurements() {
            // console.log(salesOrderItem);

            this.isDownloading = true;

            this.$apollo
                .query({
                    // Query
                    query: gql`
                        query gasMeasurements(
                            $first: Int
                            $ean: String
                            $datetimeUtcGte: DateTime
                            $datetimeUtcLte: DateTime
                            $orderBy: String
                            $frequency: Int
                        ) {
                            gasMeasurements(
                                first: $first
                                ean: $ean
                                datetimeUtc_Gte: $datetimeUtcGte
                                datetimeUtc_Lte: $datetimeUtcLte
                                orderBy: $orderBy
                                frequency: $frequency
                            ) {
                                edges {
                                    node {
                                        id
                                        value
                                        datetimeUtc
                                        isConsumption
                                    }
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        first: 50000,
                        ean: this.ean,
                        frequency: 5,
                        datetimeUtcGte: this.savedDateRange[0] + "T00:00:00",
                        datetimeUtcLte: this.savedDateRange[1] + "T23:59:59",
                        orderBy: "datetime_utc"
                    }
                })
                .then(response => {
                    // Result
                    var fileTitle = this.ean + "_5minuten"; // or 'my-unique-title'

                    var headers = {
                        datetimeUtc: "Tijd (UTC)", // remove commas to avoid errors
                        // isConsumption: "Soort leveren",
                        value: "Meetwaarde(M3)"
                    };

                    var itemsFormatted = [];

                    response.data?.gasMeasurements?.edges?.forEach(element => {
                        itemsFormatted.push({
                            datetimeUtc: moment
                                .utc(element.node.datetimeUtc)
                                .format("DD-MM-YYYY HH:mm:ss"), // .replace(/,/g, '') remove commas to avoid errors
                            // isConsumption: element.node.isConsumption
                            //     ? "leveren"
                            //     : "terugleveren",
                            value: parseFloat(element.node.value.toFixed(1))
                                .toString()
                                .replace(/\./g, ",")
                        });
                    });

                    this.exportCSVFile(headers, itemsFormatted, fileTitle);

                    // show snackbar
                    const payload = {
                        color: "success",
                        icon: "check",
                        message: `Gegevensexport gelukt!`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    // We restore the initial user input
                    // this.newTag = newTag;

                    const payload = {
                        color: "error",
                        message: `${error}`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        },

        getQuarterlyMeasurements() {
            // console.log(salesOrderItem);

            this.isDownloading = true;

            this.$apollo
                .query({
                    // Query
                    query: gql`
                        query gasMeasurementQuarterlys(
                            $first: Int
                            $ean: String
                            $datetimeLocalGte: DateTime
                            $datetimeLocalLte: DateTime
                            $orderBy: String
                        ) {
                            gasMeasurementQuarterlys(
                                first: $first
                                ean: $ean
                                datetimeLocal_Gte: $datetimeLocalGte
                                datetimeLocal_Lte: $datetimeLocalLte
                                orderBy: $orderBy
                            ) {
                                edges {
                                    node {
                                        id
                                        value
                                        peakValue
                                        datetimeLocal
                                    }
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        first: 50000,
                        ean: this.ean,
                        datetimeLocalGte: this.savedDateRange[0] + "T00:00:00",
                        datetimeLocalLte: this.savedDateRange[1] + "T23:59:59",
                        orderBy: "datetime_local"
                    }
                })
                .then(response => {
                    // Result
                    var fileTitle = this.ean + "_kwartier"; // or 'my-unique-title'

                    var headers = {
                        datetimeLocal: "Tijd", // remove commas to avoid errors
                        // isConsumption: "Soort leveren",
                        value: "Meetwaarde(M3)",
                        peakValue: "Piekwaarde(M3)"
                    };

                    var itemsFormatted = [];

                    response.data?.gasMeasurementQuarterlys?.edges?.forEach(
                        element => {
                            itemsFormatted.push({
                                datetimeLocal: moment
                                    .utc(element.node.datetimeLocal)
                                    .format("DD-MM-YYYY HH:mm:ss"), // .replace(/,/g, '') remove commas to avoid errors
                                // isConsumption: element.node.isConsumption
                                //     ? "leveren"
                                //     : "terugleveren",
                                value: parseFloat(element.node.value.toFixed(1))
                                    .toString()
                                    .replace(/\./g, ","),
                                peakValue: parseFloat(
                                    element.node.peakValue.toFixed(1)
                                )
                                    .toString()
                                    .replace(/\./g, ",")
                            });
                        }
                    );

                    this.exportCSVFile(headers, itemsFormatted, fileTitle);

                    // show snackbar
                    const payload = {
                        color: "success",
                        icon: "check",
                        message: `Gegevensexport gelukt!`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    // We restore the initial user input
                    // this.newTag = newTag;

                    const payload = {
                        color: "error",
                        message: `${error}`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        },

        getHourlyMeasurements() {
            // console.log(salesOrderItem);

            this.isDownloading = true;

            this.$apollo
                .query({
                    // Query
                    query: gql`
                        query gasMeasurementHourlys(
                            $first: Int
                            $ean: String
                            $datetimeLocalGte: DateTime
                            $datetimeLocalLte: DateTime
                            $orderBy: String
                        ) {
                            gasMeasurementHourlys(
                                first: $first
                                ean: $ean
                                datetimeLocal_Gte: $datetimeLocalGte
                                datetimeLocal_Lte: $datetimeLocalLte
                                orderBy: $orderBy
                            ) {
                                edges {
                                    node {
                                        id
                                        value
                                        peakValue
                                        datetimeLocal
                                    }
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        first: 50000,
                        ean: this.ean,
                        datetimeLocalGte: this.savedDateRange[0] + "T00:00:00",
                        datetimeLocalLte: this.savedDateRange[1] + "T23:59:59",
                        orderBy: "datetime_local"
                    }
                })
                .then(response => {
                    // Result
                    var fileTitle = this.ean + "_uur"; // or 'my-unique-title'

                    var headers = {
                        datetimeLocal: "Tijd", // remove commas to avoid errors
                        // isConsumption: "Soort leveren",
                        value: "Meetwaarde(M3)",
                        peakValue: "Piekwaarde(M3)"
                    };

                    var itemsFormatted = [];

                    response.data?.gasMeasurementHourlys?.edges?.forEach(
                        element => {
                            itemsFormatted.push({
                                datetimeLocal: moment
                                    .utc(element.node.datetimeLocal)
                                    .format("DD-MM-YYYY HH:mm:ss"), // .replace(/,/g, '') remove commas to avoid errors
                                // isConsumption: element.node.isConsumption
                                //     ? "leveren"
                                //     : "terugleveren",
                                value: parseFloat(element.node.value.toFixed(1))
                                    .toString()
                                    .replace(/\./g, ","),
                                peakValue: parseFloat(
                                    element.node.peakValue.toFixed(1)
                                )
                                    .toString()
                                    .replace(/\./g, ",")
                            });
                        }
                    );

                    this.exportCSVFile(headers, itemsFormatted, fileTitle);

                    // show snackbar
                    const payload = {
                        color: "success",
                        icon: "check",
                        message: `Gegevensexport gelukt!`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    // We restore the initial user input
                    // this.newTag = newTag;

                    const payload = {
                        color: "error",
                        message: `${error}`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        },

        getDailyMeasurements() {
            // console.log(salesOrderItem);

            this.isDownloading = true;

            this.$apollo
                .query({
                    // Query
                    query: gql`
                        query gasMeasurementDailys(
                            $first: Int
                            $ean: String
                            $datetimeLocalGte: DateTime
                            $datetimeLocalLte: DateTime
                            $orderBy: String
                        ) {
                            gasMeasurementDailys(
                                first: $first
                                ean: $ean
                                datetimeLocal_Gte: $datetimeLocalGte
                                datetimeLocal_Lte: $datetimeLocalLte
                                orderBy: $orderBy
                            ) {
                                edges {
                                    node {
                                        id
                                        value
                                        peakValue
                                        datetimeLocal
                                    }
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        first: 50000,
                        ean: this.ean,
                        datetimeLocalGte: this.savedDateRange[0] + "T00:00:00",
                        datetimeLocalLte: this.savedDateRange[1] + "T23:59:59",
                        orderBy: "datetime_local"
                    }
                })
                .then(response => {
                    // Result
                    var fileTitle = this.ean + "_dag"; // or 'my-unique-title'

                    var headers = {
                        datetimeLocal: "Datum", // remove commas to avoid errors
                        // isConsumption: "Soort leveren",
                        value: "Meetwaarde(M3)",
                        peakValue: "Piekwaarde(M3)"
                    };

                    var itemsFormatted = [];

                    response.data?.gasMeasurementDailys?.edges?.forEach(
                        element => {
                            itemsFormatted.push({
                                datetimeLocal: moment
                                    .utc(element.node.datetimeLocal)
                                    .format("DD-MM-YYYY"), // .replace(/,/g, '') remove commas to avoid errors
                                // isConsumption: element.node.isConsumption
                                //     ? "leveren"
                                //     : "terugleveren",
                                value: parseFloat(element.node.value.toFixed(1))
                                    .toString()
                                    .replace(/\./g, ","),
                                peakValue: parseFloat(
                                    element.node.peakValue.toFixed(1)
                                )
                                    .toString()
                                    .replace(/\./g, ",")
                            });
                        }
                    );

                    this.exportCSVFile(headers, itemsFormatted, fileTitle);

                    // show snackbar
                    const payload = {
                        color: "success",
                        icon: "check",
                        message: `Gegevensexport gelukt!`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    // We restore the initial user input
                    // this.newTag = newTag;

                    const payload = {
                        color: "error",
                        message: `${error}`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        },

        getMonthlyMeasurements() {
            // console.log(salesOrderItem);

            this.isDownloading = true;

            this.$apollo
                .query({
                    // Query
                    query: gql`
                        query gasMeasurementMonthlys(
                            $first: Int
                            $ean: String
                            $datetimeLocalGte: DateTime
                            $datetimeLocalLte: DateTime
                            $orderBy: String
                        ) {
                            gasMeasurementMonthlys(
                                first: $first
                                ean: $ean
                                datetimeLocal_Gte: $datetimeLocalGte
                                datetimeLocal_Lte: $datetimeLocalLte
                                orderBy: $orderBy
                            ) {
                                edges {
                                    node {
                                        id
                                        value
                                        peakValue
                                        datetimeLocal
                                    }
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        first: 50000,
                        ean: this.ean,
                        datetimeLocalGte: this.savedDateRange[0] + "T00:00:00",
                        datetimeLocalLte: this.savedDateRange[1] + "T23:59:59",
                        orderBy: "datetime_local"
                    }
                })
                .then(response => {
                    // Result
                    var fileTitle = this.ean + "_maand"; // or 'my-unique-title'

                    var headers = {
                        datetimeLocal: "Maand", // remove commas to avoid errors
                        // isConsumption: "Soort leveren",
                        value: "Meetwaarde(M3)",
                        peakValue: "Piekwaarde(M3)"
                    };

                    var itemsFormatted = [];

                    response.data?.gasMeasurementMonthlys?.edges?.forEach(
                        element => {
                            itemsFormatted.push({
                                datetimeLocal: moment
                                    .utc(element.node.datetimeLocal)
                                    .format("MM-YYYY"), // .replace(/,/g, '') remove commas to avoid errors
                                // isConsumption: element.node.isConsumption
                                //     ? "leveren"
                                //     : "terugleveren",
                                value: parseFloat(element.node.value.toFixed(1))
                                    .toString()
                                    .replace(/\./g, ","),
                                peakValue: parseFloat(
                                    element.node.peakValue.toFixed(1)
                                )
                                    .toString()
                                    .replace(/\./g, ",")
                            });
                        }
                    );

                    this.exportCSVFile(headers, itemsFormatted, fileTitle);

                    // show snackbar
                    const payload = {
                        color: "success",
                        icon: "check",
                        message: `Gegevensexport gelukt!`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .catch(error => {
                    // Error
                    console.error(error);
                    // We restore the initial user input
                    // this.newTag = newTag;

                    const payload = {
                        color: "error",
                        message: `${error}`
                    };
                    this.$store.dispatch("snackbar/showMessage", payload);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        }
    }
};
</script>