var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Wachtwoord wijzigen ")]),_c('v-card-text',{staticClass:"pt-10 pb-0"},[_c('v-form',{ref:"changePasswordForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"id":"oldPassword","label":"Huidig wachtwoord","type":_vm.showOldPassword ? 'text' : 'password',"rules":[_vm.rules.required],"error-messages":_vm.changePasswordError,"outlined":"","append-icon":_vm.showOldPassword ? 'visibility' : 'visibility_off'},on:{"click:append":function($event){_vm.showOldPassword = !_vm.showOldPassword}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('v-text-field',{attrs:{"id":"newPassword","label":"Nieuw wachtwoord","type":_vm.showNewPassword ? 'text' : 'password',"rules":[
                        _vm.rules.required,
                        _vm.rules.minLength(8),
                        _vm.rules.maxLength(40)
                    ],"outlined":"","append-icon":_vm.showNewPassword ? 'visibility' : 'visibility_off'},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{attrs:{"id":"newPassword2","label":"Bevestig nieuw wachtwoord","type":_vm.showNewPassword ? 'text' : 'password',"rules":[
                        _vm.rules.required,
                        _vm.rules.minLength(8),
                        _vm.rules.maxLength(40)
                    ],"outlined":"","append-icon":_vm.showNewPassword ? 'visibility' : 'visibility_off'},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changePassword()}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})],1)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.closeChangePasswordDialog()}}},[_vm._v("Annuleren")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","disabled":_vm.newPassword.length == 0 ||
                        _vm.newPassword !== _vm.newPasswordConfirm,"loading":_vm.isChangingPassword,"color":"primary"},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v("Wijzigen")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }