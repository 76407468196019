<template>
    <v-container fluid class="pa-0 ">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-col cols="auto">
                        <h2 class="display-3 font-weight-medium">Verbruik</h2>
                        <!-- <v-progress-linear
                            :active="$apollo.queries.accounts.loading"
                            color="primary"
                            buffer-value="0"
                            stream
                            class="mx-0 mt-2 px-0"
                        ></v-progress-linear> -->
                    </v-col>
                </v-card-title>
            </v-card>
        </v-container>

        <!-- Overview  body -->
        <v-card flat color="grey lighten-4 pt-5" min-height="73vh" fill-width>
            <v-container>
                <v-row>
                    <!-- EAN list -->
                    <v-col cols="12" sm="4">
                        <!-- loading progress bar -->
                        <v-card
                            max-height="530"
                            elevation="3"
                            v-if="$apollo.queries.accounts.loading"
                        >
                            <v-card
                                color="transparent"
                                flat
                                class="mx-auto text-center"
                                fill-height
                                width="200"
                                height="200"
                            >
                                <v-responsive height="80"></v-responsive>
                                <div class="grey--text text--darken-2">
                                    Een ogenblik geduld aub...
                                </div>
                                <v-row class="justify-center">
                                    <v-col cols="12">
                                        <v-progress-linear
                                            color="primary accent-4"
                                            indeterminate
                                            rounded
                                            height="5"
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>

                        <!-- ean list -->
                        <v-card
                            v-else
                            class="overflow-y-auto"
                            max-height="530"
                            elevation="3"
                        >
                            <v-card min-height="200" elevation="3" class="pt-0">
                                <!-- no data -->
                                <v-card
                                    v-if="accounts.edgeCount == 0 || totalEANCount == 0"
                                    color="transparent"
                                    flat
                                    class="mx-auto text-center"
                                    height="200"
                                    width="200"
                                >
                                    <v-responsive height="80"></v-responsive>
                                    <div class="grey--text text--darken-2">
                                        Geen gegevens beschikbaar
                                    </div>
                                </v-card>

                                <v-list v-else class="pt-0">
                                    <v-list-item
                                        v-for="account in accounts.edges"
                                        :key="account.node.id"
                                        v-show="account.node.eanElectricitySet.edgeCount > 0 || account.node.eanGasSet.edgeCount > 0"
                                    >
                                        <v-list-item-content class="mt-2">
                                            <!-- account -list -->
                                            <v-list-item-title class="mb-3">
                                                <div
                                                    class="text-subtitle-2 font-weight-light ml-1"
                                                >
                                                    {{
                                                        account.node
                                                            .dynamicsNifenummer
                                                    }}
                                                </div>
                                                <v-icon
                                                    class="grey--text mb-1 mr-1"
                                                    >business</v-icon
                                                >

                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <span
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >{{
                                                                account.node
                                                                    .fullAddress
                                                            }}</span
                                                        >
                                                    </template>
                                                    <span>{{
                                                        account.node.fullAddress
                                                    }}</span>
                                                </v-tooltip>
                                            </v-list-item-title>
                                            <!-- electricity ean list -->
                                            <v-list-item-subtitle
                                                v-for="ean in account.node
                                                    .eanElectricitySet.edges"
                                                :key="ean.node.id"
                                                class="pl-5 my-1"
                                            >
                                                <v-icon
                                                    color="success"
                                                    class="mr-1"
                                                    >bolt</v-icon
                                                >
                                                <!-- if ean is being presented, show primary color -->
                                                <v-chip
                                                    v-if="
                                                        ean.node.ean == pathEan
                                                    "
                                                    class="primary"
                                                    dark
                                                >
                                                    {{ ean.node.ean }}
                                                </v-chip>
                                                <!-- else, if ean meet data is enabled,show clickable route link -->
                                                <router-link
                                                    v-else-if="
                                                        ean.node
                                                            .myMeasurementDataCode ==
                                                            '603540000' &&
                                                            !!ean.node
                                                                .measurementCompany
                                                    "
                                                    :to="{
                                                        name:
                                                            'elektriciteitverbruik',
                                                        params: {
                                                            ean: ean.node.ean
                                                        }
                                                    }"
                                                    class="text-decoration-none"
                                                >
                                                    <v-chip
                                                        outlined
                                                        color="primary"
                                                    >
                                                        {{ ean.node.ean }}
                                                    </v-chip>
                                                </router-link>
                                                <!-- else show disabled button -->
                                                <v-chip
                                                    v-else
                                                    outlined
                                                    disabled
                                                >
                                                    {{ ean.node.ean }}
                                                </v-chip>
                                            </v-list-item-subtitle>
                                            <!-- gas eans list-->
                                            <v-list-item-subtitle
                                                v-for="ean in account.node
                                                    .eanGasSet.edges"
                                                :key="ean.node.id"
                                                class="pl-5 my-1"
                                            >
                                                <v-icon
                                                    color="warning"
                                                    class="mr-1"
                                                    >local_fire_department</v-icon
                                                >
                                                <!-- if ean is being presented, show primary color -->
                                                <v-chip
                                                    v-if="
                                                        ean.node.ean == pathEan
                                                    "
                                                    class="primary"
                                                    dark
                                                >
                                                    {{ ean.node.ean }}
                                                </v-chip>
                                                <!-- else, if ean meet data is enabled, show clickable route link -->
                                                <router-link
                                                    v-else-if="
                                                        ean.node
                                                            .myMeasurementDataCode ==
                                                            '603540000' &&
                                                            !!ean.node
                                                                .measurementCompany
                                                    "
                                                    :to="{
                                                        name: 'gasverbruik',
                                                        params: {
                                                            ean: ean.node.ean
                                                        }
                                                    }"
                                                    class="text-decoration-none"
                                                >
                                                    <v-chip
                                                        outlined
                                                        color="primary"
                                                    >
                                                        {{ ean.node.ean }}
                                                    </v-chip>
                                                </router-link>
                                                <!-- else show disabled button-->
                                                <v-chip
                                                    v-else
                                                    outlined
                                                    disabled
                                                >
                                                    {{ ean.node.ean }}
                                                </v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="8" class="px-0">
                        <router-view></router-view>
                    </v-col>
                </v-row>
                <!-- 
                <v-card>
                    <v-card-title>
                        Eletricity Measurements
                    </v-card-title>
                    <v-card-text>
                        {{ accounts }}
                    </v-card-text>
                </v-card> -->
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    title: "Verbruik",
    components: {},

    apollo: {
        accounts: {
            query: gql`
                query accounts(
                    $first: Int
                    $isMainAccount: Boolean
                    $kvOrGv: String
                    $myMeasurementData: String
                    $measurementCompany_Isnull: Boolean
                    $orderBy: String
                ) {
                    accounts(
                        first: $first
                        isMainAccount: $isMainAccount
                        orderBy: $orderBy
                    ) {
                        edgeCount
                        edges {
                            node {
                                id
                                name
                                isMainAccount
                                dynamicsNifenummer
                                fullAddress
                                eanElectricitySet(kvOrGv: $kvOrGv, myMeasurementData_Iexact: $myMeasurementData, measurementCompany_Isnull: $measurementCompany_Isnull) {
                                    edgeCount
                                    edges {
                                        node {
                                            id
                                            ean
                                            myMeasurementDataCode
                                            myMeasurementData
                                            measurementCompany
                                        }
                                    }
                                }
                                eanGasSet(kvOrGv: $kvOrGv, myMeasurementData_Iexact: $myMeasurementData, measurementCompany_Isnull: $measurementCompany_Isnull) {
                                    edgeCount
                                    edges {
                                        node {
                                            id
                                            ean
                                            myMeasurementDataCode
                                            myMeasurementData
                                            measurementCompany
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    first: 200,
                    isMainAccount: false,
                    // kvOrGv: "GV",   // don't show KV eans
                    myMeasurementData: "Ja",
                    measurementCompany_Isnull: false,
                    orderBy: "dynamicsNifenummer"
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            update: data => data.accounts,
            skip() {
                return this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            tab: null,
            energyType: null,
            selectedEAN: null,
            accounts: {}
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        pathEan() {
            return this.$route.params.ean;
        },
        totalEANCount() {
            const totalCount = this.accounts?.edges?.reduce(
                (acc, cur) => acc + cur.node.eanElectricitySet.edgeCount + cur.node.eanGasSet.edgeCount,
                0
            );
            return totalCount;
        },
    },
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
        this.pushTo = helper.pushTo;
    },
    mounted() {},
    methods: {}
};
</script>