<template>
    <v-container fluid class="pa-0">
        <v-card class="mt-0 pt-3" elevation="3" height="360">
            <v-progress-linear
                :active="$apollo.queries.lastTtfGasEndexMarketPrices.loading"
                color="primary"
                buffer-value="0"
                indeterminate
                absolute
                top
                height="2"
                class="mx-0 px-0"
            ></v-progress-linear>
            <v-card-title class="justify-center">
                <h3>
                    TTF Gas (Endex)
                </h3>
                <v-btn
                    absolute
                    right
                    icon
                    color="grey lighten-1"
                    class="mt-n10 mr-n3"
                    @click="dialog = !dialog"
                    ><v-icon>open_in_full</v-icon></v-btn
                >
            </v-card-title>

            <v-card-text>
                <VueApexCharts
                    ref="chart"
                    type="line"
                    height="240"
                    :options="chartOptions"
                    :series="series"
                ></VueApexCharts>

                <p v-if="priceDate" class="text-center mt-n3 text-subtitle-2">
                    Datum {{ priceDate | moment("DD-MM-YYYY") }}
                </p>
            </v-card-text>
        </v-card>

        <!-- large chart -->
        <v-dialog v-model="dialog" width="80%">
            <v-card class="overflow-hidden">
                <v-progress-linear
                    :active="$apollo.queries.longMarketPrices.loading"
                    color="primary"
                    buffer-value="0"
                    indeterminate
                    absolute
                    top
                    height="3"
                    class="mx-0 px-0"
                ></v-progress-linear>
                <v-card-title class="justify-center">
                    <h3>
                        TTF Gas (Endex)
                    </h3>
                    <v-btn
                        absolute
                        right
                        icon
                        color="grey"
                        @click="dialog = !dialog"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-tabs
                        v-model="selectedYearTab"
                        center-active
                        class="justify-center mb-3"
                        hide-details
                        show-arrows
                    >
                        <v-tab
                            v-for="year in chartOptions.xaxis.categories"
                            :key="year"
                        >
                            {{ year }}</v-tab
                        >
                    </v-tabs>

                    <VueApexCharts
                        ref="chart"
                        type="line"
                        height="500"
                        :options="longChartOptions"
                        :series="longSeries"
                    ></VueApexCharts>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    name: "ttfgasendex-chart",
    props: [],
    components: { VueApexCharts },

    apollo: {
        lastTtfGasEndexMarketPrices: {
            query: gql`
                query lastTtfGasEndexMarketPrices {
                    lastTtfGasEndexMarketPrices {
                        id
                        subType
                        graphLine
                        priceDate
                        priceValue
                    }
                }
            `,
            variables() {
                return {};
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            // update: data => data.electricityMeasurementHourlys,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.lastTtfGasEndexMarketPrices) {
                    var _seires1Data = [];
                    // var _seires2Data = [];

                    data?.lastTtfGasEndexMarketPrices.forEach(element => {
                        // update xaxis categories
                        if (
                            this.chartOptions.xaxis.categories.indexOf(
                                element.subType
                            ) < 0
                        ) {
                            this.chartOptions.xaxis.categories.push(
                                element.subType
                            );
                        }

                        // update series
                        // if (element.graphLine == "Dal") {
                        _seires1Data.push({
                            x: element.subType,
                            y: element.priceValue
                        });
                        // } else {
                        //     _seires2Data.push({
                        //         x: element.subType,
                        //         y: element.priceValue
                        //     });
                        // }

                        // update price date
                        this.priceDate = element.priceDate;
                    });

                    this.chartOptions.xaxis.categories.sort();

                    var _series1 = {
                        name: "TTF Gas (Endex)",
                        type: "line",
                        data: _seires1Data
                    };

                    // var _series2 = {
                    //     name: "Piek",
                    //     type: "line",
                    //     data: _seires2Data
                    // };
                    this.series = [_series1];
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return false;
            },
            debounce: 500
            // pollInterval: 60000 // ms
        },
        longMarketPrices: {
            query: gql`
                query marketPrices(
                    $priceTypeIexact: String
                    $priceDateGte: Date
                    $priceDateLte: Date
                    $orderBy: String
                    $subTypeIexact: String
                    $graphLineIexact: String
                ) {
                    marketPrices(
                        priceType_Iexact: $priceTypeIexact
                        priceDate_Gte: $priceDateGte
                        priceDate_Lte: $priceDateLte
                        orderBy: $orderBy
                        subType_Iexact: $subTypeIexact
                        graphLine_Iexact: $graphLineIexact
                    ) {
                        edges {
                            node {
                                id
                                subType
                                graphLine
                                priceDate
                                priceValue
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    priceTypeIexact: "TTF Gas (Endex)",
                    priceDateGte: this.longStartDate,
                    priceDateLte: this.longEndDate,
                    orderBy: "priceDate",
                    subTypeIexact: this.chartOptions.xaxis.categories[
                        this.selectedYearTab
                    ],
                    graphLineIexact: null
                };
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.marketPrices,
            result({ data, loading }) {
                // map result to chart series
                if (!loading && data?.marketPrices) {
                    var _seires1Data = [];
                    // var _seires2Data = [];

                    data?.marketPrices.edges.forEach(element => {
                        // if (element.node.graphLine == "Dal") {
                        _seires1Data.push({
                            x: moment(element.node.priceDate).format(
                                "YYYY-MM-DD"
                            ),
                            y: element.node.priceValue
                        });
                        // } else {
                        //     _seires2Data.push({
                        //         x: moment(element.node.priceDate).format(
                        //             "YYYY-MM-DD"
                        //         ),
                        //         y: element.node.priceValue
                        //     });
                        // }
                    });

                    var _series1 = {
                        name: "TTF Gas (Endex)",
                        type: "line",
                        data: _seires1Data
                    };

                    // var _series2 = {
                    //     name: "Piel",
                    //     type: "line",
                    //     data: _seires2Data
                    // };
                    this.longSeries = [_series1];
                }
            },
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            skip() {
                return !this.dialog;
            },
            debounce: 500
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            dialog: false,

            series: [],
            longSeries: [],
            priceDate: null,
            selectedYearTab: 0,

            longStartDate: null,
            longEndDate: null,

            chartOptions: {
                chart: {
                    id: "chart",
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "category",
                    categories: []
                },
                yaxis: [
                    {
                        seriesName: "Dal",
                        showAlways: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#2071B5"
                        },
                        labels: {
                            style: {
                                colors: "#2071B5"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "€cent / m3",
                            style: {
                                color: "#2071B5"
                            }
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                    // {
                    //     show: false,
                    //     seriesName: "WTI Cushing Spot"
                    // }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2, 2],
                    dashArray: [0, 0]
                },
                markers: {
                    size: [0, 0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#E91E63"],
                legend: {
                    show: false
                },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                },
                tooltip: {
                    x: {
                        show: true
                        // formatter: function(value) {
                        //     return value;
                        // }
                    },
                    y: {
                        show: true,
                        formatter: function(value) {
                            return `${value.toFixed(2)} €cent / m3`;
                        }
                        // title: {
                        //     formatter: seriesName => seriesName
                        // }
                    },
                    marker: {
                        show: true
                    }
                }
            },

            longChartOptions: {
                chart: {
                    id: "chart",
                    type: "line",
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    selection: {
                        enabled: false,
                        type: "xy"
                    },
                    brush: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        // dataPointSelection: (event, chartContext, config) => {
                        //     console.log(event, chartContext, config);
                        //     this.$emit(
                        //         "week-selected",
                        //         config.dataPointIndex + 1
                        //     );
                        // }
                    }
                },
                xaxis: {
                    // tickPlacement: 'on',
                    type: "datetime"
                },
                yaxis: [
                    {
                        seriesName: "Dal",
                        showAlways: true,
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: false
                            // color: "#2071B5"
                        },
                        labels: {
                            style: {
                                colors: "#2071B5"
                            },
                            formatter: function(val) {
                                return val.toFixed(0);
                            }
                        },
                        title: {
                            text: "€cent / m3`",
                            style: {
                                color: "#2071B5"
                            }
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                    // {
                    //     show: false,
                    //     seriesName: "WTI Cushing Spot"
                    // }
                    // max: 2000 //default value, will be updated by greenhouse total space
                ],
                fill: {
                    opacity: 1
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [2, 2],
                    dashArray: [0, 0]
                },
                markers: {
                    size: [0, 0],
                    offsetX: 0,
                    hover: {
                        size: undefined,
                        sizeOffset: 0
                    }
                },
                colors: ["#E91E63"],
                legend: {
                    show: false
                },
                states: {
                    active: {
                        filter: {
                            type: "none" /* none, lighten, darken */
                        }
                    }
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function(value) {
                            return moment(value).format("DD-MM-YYYY");
                        }
                    },
                    y: {
                        show: true,
                        formatter: function(value) {
                            return `${value.toFixed(2)} €cent / m3`;
                        }
                        // title: {
                        //     formatter: seriesName => seriesName
                        // }
                    },
                    marker: {
                        show: true
                    }
                }
            }
        };
    },

    computed: {},
    watch: {},
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        this.initializeDate();
    },
    methods: {
        initializeDate() {
            // // set date filter
            // let _yesterday = new Date();
            // _yesterday.setDate(_yesterday.getDate() - 1);
            // let _dt = _yesterday.toISOString().substring(0, 10);
            // this.startDate = moment()
            //     .add(-2, "month")
            //     .format("YYYY-MM-DD");
            // this.endDate = moment().format("YYYY-MM-DD");
            this.longStartDate = moment()
                .add(-2, "year")
                .format("YYYY-01-01");
            this.longEndDate = moment().format("YYYY-MM-DD");
        }
    }
};
</script>

