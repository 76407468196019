<template>
    <v-container fluid class="pa-0">
        <!-- EAN header -->
        <v-list-item class="px-0">
            <v-list-item-title>
                <v-row class="align-center">
                    <v-col cols="12" sm="5">
                        <v-icon color="success" class="mr-1">bolt</v-icon>
                        <v-tooltip top>
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-chip outlined v-bind="attrs" v-on="on">{{
                                    e.node.ean
                                }}</v-chip>
                            </template>
                            <span>EAN</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template
                                v-slot:activator="{
                                    on,
                                    attrs
                                }"
                            >
                                <v-chip
                                    outlined
                                    class="ml-1"
                                    v-bind="attrs"
                                    v-on="on"
                                    ><v-icon left small
                                        >signal_cellular_alt</v-icon
                                    >{{ e.node.meterType }}</v-chip
                                >
                            </template>
                            <span>Metertype</span>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="7"
                        v-if="
                            e.node.kvOrGv == 'GV' &&
                                e.node.myMeasurementData == 'Ja' &&
                                !!e.node.measurementCompany
                        "
                    >
                        <v-icon small left color="primary" class="ml-6 mb-1"
                            >check_circle</v-icon
                        >
                        <a
                            text
                            @click="
                                showEanMeasurement('elektriciteit', e.node.ean)
                            "
                            >Meetdata</a
                        >
                    </v-col>
                    <v-col cols="12" sm="7" v-else-if="e.node.kvOrGv == 'GV'">
                        <v-icon small left class="ml-6 mb-1 primary--text"
                            >info</v-icon
                        >
                        <a
                            text
                            @click="
                                showEanMeasurement('elektriciteit', e.node.ean)
                            "
                            >Meetdata aanvragen</a
                        >
                    </v-col>
                    <v-col
                        cols="12"
                        sm="7"
                        v-else-if="
                            e.node.kvOrGv == 'KV' &&
                                e.node.myMeasurementData == 'Ja' &&
                                !!e.node.measurementCompany
                        "
                        class="grey--text text-truncate"
                    >
                        <v-icon small left color="primary" class="ml-6 mb-1"
                            >check_circle</v-icon
                        >
                        <a
                            text
                            @click="
                                showEanMeasurement('elektriciteit', e.node.ean)
                            "
                            >Meetdata</a
                        >
                    </v-col>
                    <v-col
                        cols="12"
                        sm="7"
                        v-else-if="e.node.dynamicsSlimmemeter == 'Nee'"
                    >
                        <v-icon
                            small
                            left
                            class="ml-6 mb-1 grey--text text--lighten-1"
                            >cancel</v-icon
                        >
                        <span class="grey--text">Geen slimme meter</span>
                    </v-col>
                    <v-col cols="12" sm="7" v-else>
                        <v-icon
                            small
                            left
                            class="ml-6 mb-1 grey--text text--lighten-1"
                            >cancel</v-icon
                        >
                        <span class="grey--text"
                            >Geen meetdata beschikbaar</span
                        >
                    </v-col>
                    <v-btn
                        absolute
                        right
                        icon
                        class="mr-n4"
                        @click="showEanStatusCard = !showEanStatusCard"
                    >
                        <v-icon>{{
                            showEanStatusCard ? "expand_less" : "expand_more"
                        }}</v-icon>
                    </v-btn>
                </v-row>
            </v-list-item-title>
        </v-list-item>

        <!-- EAN status -->
        <v-card
            v-show="showEanStatusCard"
            elevation="5"
            rounded="xl"
            color="background"
            class="mt-2 mb-3"
        >
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Slimme meter (CRM)
                        </h5>

                        <div class="black--text" v-if="e.node.kvOrGv == 'GV'">
                            GV
                        </div>
                        <div class="black--text" v-else>
                            {{ e.node.dynamicsSlimmemeter || "-" }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Meetbedrijf (CRM)
                        </h5>

                        <div class="black--text">
                            {{ e.node.dynamicsMeetbedrijf || "-" }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Meetbedrijf einddatum (CRM)
                        </h5>

                        <div class="black--text">
                            {{
                                formatDate(e.node.dynamicsMeetbedrijfeinddatum)
                            }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Meetbedrijf nieuw (CRM)
                        </h5>

                        <div class="black--text">
                            {{ e.node.dynamicsMeetbedrijfnieuw || "-" }}
                        </div>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Ingangsdatum nieuw (CRM)
                        </h5>

                        <div class="black--text">
                            {{ formatDate(e.node.dynamicsIngangsdatumnieuw) }}
                        </div>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Status (<span class="text-capitalize">{{
                                e.node.measurementCompany || "-"
                            }}</span
                            >)
                        </h5>

                        <div class="black--text">
                            {{ e.node.measurementApiDataStatus || "-" }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Synchronisatie datum (<span
                                class="text-capitalize"
                                >{{ e.node.measurementCompany || "-" }}</span
                            >)
                        </h5>

                        <div class="black--text">
                            {{ formatDate(e.node.measurementApiLastSyncDate) }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Synchronisatie rijen (<span
                                class="text-capitalize"
                                >{{ e.node.measurementCompany || "-" }}</span
                            >)
                        </h5>

                        <div class="black--text">
                            {{ e.node.measurementApiLastSyncRows || "-" }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3">
                        <h5>
                            Synchronisatie fout (<span
                                class="text-capitalize"
                                >{{ e.node.measurementCompany || "-" }}</span
                            >)
                        </h5>

                        <div class="black--text">
                            {{ e.node.measurementApiLastSyncError || "-" }}
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>


<script>
import moment from "moment";

export default {
    name: "elektraeanstatuscard",
    props: {
        e: {
            type: Object,
            default: null
        }
    },
    components: {},

    data() {
        return {
            showEanStatusCard: false
        };
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        formatDate(date) {
            if (date) {
                return moment(date).format("DD-MM-YYYY");
            } else {
                return "-";
            }
        },

        showEanMeasurement(measurementType, ean) {
            this.$emit("showEanMeasurement", measurementType, ean);
        }
    }
};
</script>