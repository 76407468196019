<template>
    <v-container>
        <!-- div for help to place filter card -->
        <div class="mt-n16 white--text">.</div>

        <!-- filter -->
        <v-card class="mt-0 py-5" elevation="3">
            <v-row align="start" class="px-5">
                <v-col cols="11">
                    <v-row>
                        <v-col
                            v-for="f in selectedFilters"
                            :key="f"
                            :class="getFilterChoiceClassByCode(f)"
                        >
                            <v-text-field
                                v-show="f === 'invoiceNumber'"
                                v-model="filter.invoiceNumber"
                                label="Factuurnummer"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>

                            <v-text-field
                                v-show="f === 'address'"
                                v-model="filter.account_FullAddress"
                                label="Adres"
                                placeholder="Straat / Postcode"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>

                            <v-text-field
                                v-show="f === 'nifeNumber'"
                                v-model="filter.accountDynamicsNifenummer"
                                label="Klantnummer"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>

                            <v-text-field
                                v-show="f === 'ean'"
                                v-model="filter.ean"
                                label="EAN"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>

                            <v-text-field
                                v-show="f === 'supplier'"
                                v-model="filter.supplier"
                                label="Leverancier"
                                clearable
                                hide-details
                                dense
                                class="subtitle-2"
                            ></v-text-field>

                            <v-autocomplete
                                v-show="f === 'sendStatus'"
                                v-model="filter.sendStatusIn"
                                :items="sendStatusChoices"
                                label="Verzendstatus"
                                chips
                                small-chips
                                multiple
                                clearable
                                hide-details
                                dense
                                color="primary"
                                class="subtitle-2"
                            ></v-autocomplete>

                            <v-autocomplete
                                v-show="f === 'invoiceStatusPortal'"
                                v-model="filter.invoiceStatusPortalIn"
                                :items="invoiceStatusPortalChoices"
                                label="Status"
                                chips
                                small-chips
                                clearable
                                multiple
                                hide-details
                                dense
                                color="primary"
                                class="subtitle-2"
                            ></v-autocomplete>

                            <v-autocomplete
                                v-show="f === 'invoiceType'"
                                v-model="filter.invoiceTypeIn"
                                :items="invoiceTypeChoices"
                                label="Type factuur"
                                chips
                                small-chips
                                clearable
                                multiple
                                hide-details
                                dense
                                color="primary"
                                class="subtitle-2"
                            ></v-autocomplete>

                            <v-select
                                v-show="f === 'relativeDateRange'"
                                v-model="relativeDateRange"
                                :items="relativeDateRangeChoices"
                                label="Relatieve datum"
                                clearable
                                hide-details
                                dense
                                color="primary"
                                class="subtitle-2"
                            ></v-select>

                            <v-menu
                                v-if="f === 'invoiceDate'"
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="savedDateRange"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="!!relativeDateRange"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="dateRangeText"
                                        label="Factuurdatum"
                                        readonly
                                        clearable
                                        hide-details
                                        dense
                                        class="subtitle-2"
                                        @click:clear="onClearDates()"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="!!relativeDateRange"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateRange"
                                    :first-day-of-week="1"
                                    range
                                    no-title
                                    scrollable
                                    color="primary"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        rounded
                                        color="primary"
                                        @click="cancelDatePicker"
                                    >
                                        Annuleren
                                    </v-btn>
                                    <v-btn
                                        outlined
                                        rounded
                                        color="primary"
                                        :disabled="dateRange.length < 2"
                                        @click="
                                            $refs.menu[0].save(dateRange.sort())
                                        "
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- filters menu -->
                <v-col cols="1" class="py-2 px-0 text-right">
                    <v-menu
                        rounded
                        bottom
                        left
                        key="filterMenu"
                        :close-on-content-click="false"
                        offset-y
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <v-btn class="ma-1" v-bind="attrs" v-on="on" icon>
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                v-for="item in filterChoices"
                                :key="item.code"
                                class="px-0"
                            >
                                <v-list-item-title class="py-2">
                                    <v-checkbox
                                        v-model="selectedFilters"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                        hide-details
                                        class="mx-3 my-0"
                                    ></v-checkbox>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card>

        <!-- data table card -->
        <v-card class="mt-3" elevation="3">
            <v-card-text class="pb-3">
                <v-data-table
                    :headers="headers"
                    :loading="$apollo.queries.energyInvoices.loading"
                    :items="energyInvoices.edges"
                    :expanded.sync="expanded"
                    single-expand
                    show-expand
                    item-key="node.id"
                    sort-by="node.invoiceDate"
                    :sort-desc="true"
                    loading-text="Een ogenblik geduld aub..."
                    hide-default-footer
                    disable-pagination
                    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                    <!-- data table progress bar -->
                    <template v-slot:progress class="px-0 mx-0">
                        <v-progress-linear
                            absolute
                            height="2"
                            indeterminate
                        ></v-progress-linear>
                    </template>

                    <!-- no data availabel -->
                    <template slot="no-data">
                        <div>Geen gegevens beschikbaar</div>
                    </template>

                    <!-- Address -->
                    <template v-slot:item.node.account.fullAddress="{ item }">
                        <span class="hidden-lg-and-up">{{
                            item.node.account.fullAddress | truncate(30)
                        }}</span>
                        <span class="hidden-md-and-down">{{
                            item.node.account.fullAddress
                        }}</span>
                    </template>

                    <!-- formatted invoice date -->
                    <template v-slot:item.node.invoiceDate="{ item }">
                        <span v-if="item.node.invoiceDate">{{
                            item.node.invoiceDate | moment("DD-MM-YYYY")
                        }}</span>
                    </template>

                    <!-- invoice status -->
                    <template v-slot:item.node.invoiceStatusPortal="{ item }">
                        <v-chip
                            label
                            small
                            :color="
                                item.node.invoiceStatusPortal == 'Akkoord'
                                    ? 'success'
                                    : 'primary'
                            "
                            >{{ item.node.invoiceStatusPortal }}</v-chip
                        >
                    </template>

                    <!-- send status -->
                    <template v-slot:item.node.sendStatus="{ item }">
                        <v-chip
                            label
                            small
                            :color="
                                item.node.sendStatus == 'Verzonden'
                                    ? 'success'
                                    : 'primary'
                            "
                            >{{ item.node.sendStatus }}</v-chip
                        >
                    </template>

                    <!-- download button -->
                    <template
                        v-slot:item.node.annotation.blobStoragePath="{ item }"
                    >
                        <v-btn
                            v-if="
                                item.node.annotation &&
                                    item.node.annotation.blobStoragePath
                            "
                            color="grey darken-2"
                            class="ma-2 white--text"
                            icon
                            download
                            target="_blank"
                            :href="item.node.annotation.blobStoragePath"
                            @click="updateLastDownload(item.node)"
                        >
                            <v-icon>
                                file_download
                            </v-icon>
                        </v-btn>
                    </template>

                    <!-- downloaded date -->
                    <template v-slot:item.node.lastDownloadedDate="{ item }">
                        <span v-if="item.node.lastDownloadedDate">
                            {{
                                item.node.lastDownloadedDate
                                    | moment("DD-MM-YYYY HH:mm")
                            }}
                        </span>
                    </template>

                    <!-- expanded area -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="px-5 py-5">
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Klantnummer</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.account.dynamicsNifenummer }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>EAN</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.ean }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Adres</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">
                                    {{ item.node.account.fullAddress }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Periode</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10">{{
                                    item.node.period
                                }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Verzonden op</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10"
                                    ><span v-if="item.node.sendDate">
                                        {{
                                            item.node.sendDate
                                                | moment("DD-MM-YYYY HH:mm")
                                        }}</span
                                    ></v-col
                                >
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="4" md="2"
                                    ><strong>Opmerking</strong></v-col
                                >
                                <v-col cols="6" sm="8" md="10"
                                    ><span>
                                        {{ item.node.invoiceControlText }}</span
                                    ></v-col
                                >
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>

            <!-- infinit loading trigger -->
            <v-card-actions class="justify-center">
                <v-btn
                    v-if="hasMoreData"
                    v-intersect="onLoadMoreTriggerIntersect"
                    :disabled="!hasMoreData"
                    :loading="$apollo.queries.energyInvoices.loading"
                    text
                    class="mb-5"
                    @click="loadMore"
                >
                    Meer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>


<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import moment from "moment";

export default {
    title: "Energiefacturen",
    components: {},

    apollo: {
        energyInvoices: {
            query: gql`
                query energyInvoices(
                    $first: Int
                    $last: Int
                    $before: String
                    $after: String
                    $invoiceNumber: String
                    $ean: String
                    $accountDynamicsNifenummer: String
                    $account_FullAddress: String
                    $supplier: String
                    $invoiceStatusPortalIn: [String]
                    $sendStatusIn: [String]
                    $invoiceTypeIn: [String]
                    $invoiceDateGte: DateTime
                    $invoiceDateLte: DateTime
                    $orderBy: String
                ) {
                    energyInvoices(
                        first: $first
                        last: $last
                        before: $before
                        after: $after
                        invoiceNumber_Icontains: $invoiceNumber
                        ean_Icontains: $ean
                        account_DynamicsNifenummer_Icontains: $accountDynamicsNifenummer
                        account_FullAddress_Icontains: $account_FullAddress
                        supplierAccount_Name_Icontains: $supplier
                        invoiceStatusPortal_In: $invoiceStatusPortalIn
                        sendStatus_In: $sendStatusIn
                        invoiceType_In: $invoiceTypeIn
                        invoiceDate_Gte: $invoiceDateGte
                        invoiceDate_Lte: $invoiceDateLte
                        orderBy: $orderBy
                    ) {
                        pageInfo {
                            startCursor
                            endCursor
                            hasPreviousPage
                            hasNextPage
                        }
                        edges {
                            node {
                                id
                                invoiceDate
                                invoiceNumber
                                ean
                                invoiceStatusPortal
                                sendStatus
                                sendDate
                                invoiceType
                                invoiceControlText
                                period
                                account {
                                    id
                                    dynamicsNifenummer
                                    fullAddress
                                }
                                supplierAccount {
                                    id
                                    name
                                }
                                annotation {
                                    id
                                    fileName
                                    blobStoragePath
                                }
                                lastDownloadedDate
                            }
                        }
                    }
                }
            `,
            variables() {
                return this.filter;
            },
            // Additional options here
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first", // this setting can avoid query again after fetchMore
            update: data => data.energyInvoices,
            watchLoading(isLoading) {
                this.$emit("loading", isLoading);
            },
            debounce: 500,
            skip() {
                return this.me.isStaff;
            }
            // pollInterval: 60000 // ms
        }
    },

    data() {
        return {
            menu: false,
            dateRange: [],
            savedDateRange: [],

            // dynamic filters
            selectedFilters: [
                "invoiceNumber",
                "address",
                "supplier",
                "relativeDateRange"
            ],
            filterChoices: [
                {
                    code: "invoiceNumber",
                    name: "Factuurnummer",
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "address",
                    name: "Adres",
                    class: "col-auto col-md-4 col-lg-3"
                },
                // {
                //     code: "nifeNumber",
                //     name: "Klantnummer",
                //     class: "col-auto col-md-3 col-lg-2"
                // },
                {
                    code: "ean",
                    name: "EAN",
                    class: "col-auto col-md-3 col-lg-3"
                },
                {
                    code: "supplier",
                    name: "Leverancier",
                    class: "col-auto col-md-3 col-lg-2"
                },
                {
                    code: "invoiceStatusPortal",
                    name: "Status",
                    class: "col-auto col-md-auto"
                },
                {
                    code: "sendStatus",
                    name: "Verzendstatus",
                    class: "col-auto col-md-auto"
                },
                {
                    code: "invoiceType",
                    name: "Type factuur",
                    class: "col-auto col-md-auto"
                },
                {
                    code: "relativeDateRange",
                    name: "Relatief datum",
                    class: "col-auto col-md-auto"
                },
                {
                    code: "invoiceDate",
                    name: "Factuurdatum",
                    class: "col-12 col-sm-6 col-md-5 col-lg-4 col-xl-2"
                }
            ],

            invoiceStatusPortalChoices: [
                "Akkoord",
                "In behandeling",
                "Niet akkoord"
            ],
            sendStatusChoices: [
                "Nog niet verzonden",
                "Ter informatie",
                "Verzonden"
            ],
            invoiceTypeChoices: [
                "Aanvullend",
                "Betalingsverzoek",
                "Correctie",
                "Credit",
                "Divers",
                "Eindafrekening",
                "Energieheffing",
                "Exit en Connection",
                "Groene stroom",
                "Jaarrekening",
                "Kwartaal",
                "Maand",
                "Rentenota",
                "Storno",
                "Verzamel",
                "Voorschot",
                "Warmte en CO2"
            ],
            relativeDateRange: null,
            relativeDateRangeChoices: [
                // "Vorige maand",
                // "Laatste kwartaal",
                // "Afgelopen half jaar",
                // "Vorig jaar"
                "Afgelopen 1 maand",
                "Afgelopen 3 maanden",
                "Afgelopen 6 maanden",
                "Afgelopen 12 maanden"
            ],

            // graphql query filter
            filter: {
                first: 25,
                after: null,
                invoiceNumber: null,
                ean: null,
                accountDynamicsNifenummer: null,
                account_FullAddress: null,
                supplier: null,
                invoiceStatusPortalIn: [],
                sendStatusIn: [],
                invoiceTypeIn: [],
                invoiceDateGte: null,
                invoiceDateLte: null,
                orderBy: "-invoiceDate"
            },

            expanded: [],
            energyInvoices: {},

            // infinite loading trigger
            shouldLoadMore: false,

            headers: [
                {
                    text: "Factuurdatum",
                    value: "node.invoiceDate",
                    sortable: true
                },
                {
                    text: "Factuurnummer",
                    align: "left",
                    value: "node.invoiceNumber",
                    sortable: true
                },
                {
                    text: "Leverancier",
                    value: "node.supplierAccount.name",
                    sortable: true
                },
                {
                    text: "Status",
                    value: "node.invoiceStatusPortal",
                    sortable: true
                },
                {
                    text: "Verzendstatus",
                    value: "node.sendStatus",
                    sortable: true
                },
                {
                    text: "Type factuur",
                    value: "node.invoiceType",
                    sortable: true
                },
                // {
                //     text: "EAN",
                //     align: "left",
                //     value: "node.ean",
                //     sortable: true
                // },
                {
                    text: "Adres",
                    align: "left",
                    value: "node.account.fullAddress",
                    sortable: true
                },
                {
                    text: "Geopend op",
                    align: "left",
                    value: "node.lastDownloadedDate",
                    sortable: false
                },
                {
                    text: "PDF",
                    align: "center",
                    value: "node.annotation.blobStoragePath",
                    sortable: false
                },
                { text: "", value: "data-table-expand" }
            ]
        };
    },

    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        defaultStartDate() {
            // by default, get last 12 months' invoices
            let d = new Date();
            d.setDate(1);
            d.setMonth(d.getMonth() - 12);

            return d;
        },

        dateRangeText() {
            return this.dateRange
                .map(d => {
                    return this.formatDate(d);
                })
                .join(" ~ ");
        },

        // infinite loading
        hasMoreData() {
            return this.energyInvoices?.pageInfo?.hasNextPage;
        }
    },
    watch: {
        filter: {
            deep: true,

            handler() {
                this.refetchDate();
            }
        },
        // dynamic filters
        selectedFilters: {
            deep: true,

            handler(val) {
                this.updateMyPreferences({ energyInvoiceFilters: val });
            }
        },
        savedDateRange(val) {
            if (val.length == 1) {
                this.filter.invoiceDateGte = val[0] + "T00:00:00";
                this.filter.invoiceDateLte = val[0] + "23:59:59";
            } else if (val.length == 2) {
                this.filter.invoiceDateGte = val[0] + "T00:00:00";
                this.filter.invoiceDateLte = val[1] + "T23:59:59";
            } else {
                this.filter.invoiceDateGte = null;
                this.filter.invoiceDateLte = null;
            }
        },
        relativeDateRange(val) {
            const _index = this.relativeDateRangeChoices.indexOf(val);
            let _startDate = null;
            let _endDate = null;
            switch (_index) {
                case 0:
                    // last month
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .date(0)
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    // console.log("_startDate: ", _startDate);
                    // console.log("_endDate:", _endDate);
                    this.dateRange = [_startDate, _endDate];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case 1:
                    // last quarter
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(3, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    // console.log("_startDate: ", _startDate);
                    // console.log("_endDate:", _endDate);
                    this.dateRange = [_startDate, _endDate];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case 2:
                    // last half year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(6, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    // console.log("_startDate: ", _startDate);
                    // console.log("_endDate:", _endDate);
                    this.dateRange = [_startDate, _endDate];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                case 3:
                    // last 1 year
                    _endDate = moment().format("YYYY-MM-DD");
                    _startDate = moment()
                        .subtract(12, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    // console.log("_startDate: ", _startDate);
                    // console.log("_endDate:", _endDate);
                    this.dateRange = [_startDate, _endDate];
                    this.savedDateRange = [_startDate, _endDate];
                    break;
                default:
                    this.dateRange = [];
                    this.savedDateRange = [];
            }
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {
        // dynamic filters
        if (this.me.preferences) {
            try {
                const _preferences = JSON.parse(this.me.preferences);
                if (_preferences.energyInvoiceFilters) {
                    // filter out old choice
                    this.selectedFilters = _preferences.energyInvoiceFilters.filter(
                        item => {
                            return this.filterChoices.some(
                                elem => elem.code === item
                            );
                        }
                    );
                }
            } catch (e) {
                console.error(e);
            }
        }

        // get EAN from route
        if (this.$route.query.ean) {
            this.filter.ean = this.$route.query.ean;
            if (this.selectedFilters.indexOf("ean") == -1) {
                this.selectedFilters.push("ean");
            }
        }

        // get Address from route
        if (this.$route.query.address) {
            this.filter.account_FullAddress = this.$route.query.address;
            if (this.selectedFilters.indexOf("address") == -1) {
                this.selectedFilters.push("address");
            }
        }
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        cancelDatePicker() {
            this.menu = false;
            if (this.savedDateRange.length == 0) {
                this.dateRange = [];
            }
        },
        onClearDates() {
            this.dateRange = [];
            this.savedDateRange = [];
            this.relativeDateRange = null;
        },
        // infinite loading trigger
        onLoadMoreTriggerIntersect(entries) {
            this.shouldLoadMore = entries[0].isIntersecting;

            if (
                this.shouldLoadMore &&
                this.energyInvoices?.pageInfo?.hasNextPage
            ) {
                this.loadMore();
            }
        },
        refetchDate() {
            // use timeout to debouce
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$apollo.queries.energyInvoices.refetch(this.filter);
            }, 800); // delay
        },
        loadMore() {
            if (this.energyInvoices?.pageInfo?.hasNextPage) {
                this.$apollo.queries.energyInvoices.fetchMore({
                    variables: {
                        after: this.energyInvoices.pageInfo.endCursor
                    }
                });
            }
        },
        // dynamic filter
        getFilterChoiceClassByCode(code) {
            return this.filterChoices.find(item => {
                return item.code === code;
            })?.class;
        },
        // update filter preferences
        updateMyPreferences(value) {
            let _preferences = JSON.parse(this.me.preferences);
            _preferences = Object.assign(_preferences || {}, value);

            this.$store
                .dispatch("user/updateMyPreferences", {
                    preferences: JSON.stringify(_preferences)
                })
                .then(() => {
                    this.$store
                        .dispatch("user/fetchMe")
                        .then(() => {})
                        .catch(error => {
                            console.log("fetchMe catched error: ", error);
                        });
                })
                .catch(error => {
                    console.log("updateMyPreferences catched error: ", error);
                });
        },
        updateLastDownload(energyInvoice) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation updateEnergyInvoiceLastDownload(
                            $input: UpdateEnergyInvoiceLastDownloadInput!
                        ) {
                            updateEnergyInvoiceLastDownload(input: $input) {
                                energyInvoice {
                                    id
                                    lastDownloadedDate
                                }
                            }
                        }
                    `,
                    variables: {
                        input: {
                            energyInvoiceNodeId: energyInvoice.id
                        }
                    }
                })
                .then(() => {})
                .catch(error => {
                    console.log("updateLastDownload catched error: ", error);
                });
        }
    }
};
</script>