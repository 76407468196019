var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"mt-n16 white--text"},[_vm._v(".")]),_c('v-card',{staticClass:"mt-0 py-5",attrs:{"elevation":"3"}},[_c('v-row',{staticClass:"px-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"label":"E-mailadres","clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.email),callback:function ($$v) {_vm.$set(_vm.filter, "email", $$v)},expression:"filter.email"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"label":"Voornaam","clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.firstName),callback:function ($$v) {_vm.$set(_vm.filter, "firstName", $$v)},expression:"filter.firstName"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"label":"Achternaam","clearable":"","hide-details":"","dense":""},model:{value:(_vm.filter.lastName),callback:function ($$v) {_vm.$set(_vm.filter, "lastName", $$v)},expression:"filter.lastName"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.addUser}},[_vm._v("Toevoegen")])],1)],1)],1),_c('v-card',{staticClass:"mt-3",attrs:{"elevation":"3"}},[_c('v-card-text',{staticClass:"pb-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.$apollo.queries.tenantStaffs.loading,"items":_vm.tenantStaffs.edges,"expanded":_vm.expanded,"item-key":"node.id","sort-by":"node.user.email","sort-desc":false,"loading-text":"Een ogenblik geduld aub...","hide-default-footer":"","disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"absolute":"","height":"2","indeterminate":""}})]},proxy:true},{key:"item.node.user.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.node.user.email)+" "),(item.node.user.id == _vm.me.id)?_c('v-chip',{attrs:{"small":"","color":"primary","dark":""}},[_vm._v("ik")]):_vm._e()]}},{key:"item.node.isAdmin",fn:function(ref){
var item = ref.item;
return [(item.node.isAdmin)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.node.user.enabled2fa",fn:function(ref){
var item = ref.item;
return [(item.node.user.enabled2fa)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check_circle")]):_vm._e()]}},{key:"item.node.user.lastLogin",fn:function(ref){
var item = ref.item;
return [(item.node.user.lastLogin)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.node.user.lastLogin,"DD-MM-YYYY HH:mm")))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.me.isTenantAdmin,"icon":"","outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editUser(item.node)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"disabled":!_vm.me.isTenantAdmin || item.node.user.id == _vm.me.id,"icon":"","outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.deletingUser(item.node)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Geen gegevens beschikbaar")])])],2)],1),_c('v-card-actions',{staticClass:"justify-center"},[(_vm.hasMoreData)?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onLoadMoreTriggerIntersect),expression:"onLoadMoreTriggerIntersect"}],staticClass:"mb-5",attrs:{"disabled":!_vm.hasMoreData,"loading":_vm.$apollo.queries.energyInvoices.loading,"text":""},on:{"click":_vm.loadMore}},[_vm._v(" Meer ")]):_vm._e()],1)],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteTenantStaffDialog,"item":_vm.deletedTenantStaff},on:{"update:dialog":function($event){_vm.deleteTenantStaffDialog=$event},"confirmed":_vm.onDeleteConfirmed},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Verwijderen - "+_vm._s(_vm.deletedTenantStaff ? _vm.deletedTenantStaff.user.email : ""))]},proxy:true},{key:"content",fn:function(){return [_vm._v("Weet u zeker dat u deze gebruiker wilt verwijderen?")]},proxy:true}])}),_c('GebruikerForm',{attrs:{"dialog":_vm.tenantStaffDialog,"object":_vm.editedTenantStaff},on:{"update:dialog":function($event){_vm.tenantStaffDialog=$event},"update:object":function($event){_vm.editedTenantStaff=$event},"done":_vm.refetchDate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }