<template>
    <v-container fluid class="pa-0 ">
        <v-container class="pa-0">
            <!-- Overview header -->
            <v-card flat>
                <v-card-title>
                    <v-col cols="auto">
                        <h2 class="display-3 font-weight-medium">
                            Marktprijzen
                        </h2>
                    </v-col>
                </v-card-title>
            </v-card>
        </v-container>

        <!--  body -->
        <v-card flat color="grey lighten-4 pt-5" min-height="73vh" fill-width>
            <v-container>
                <v-row>
                    <!-- <v-col cols="12" md="6" lg="4" xl="4">
                        <ValutaChart></ValutaChart>
                    </v-col> -->
                    <v-col cols="12" md="6" lg="4" xl="4">
                        <LebaChart></LebaChart>
                    </v-col>
                    <!-- <v-col cols="12" md="6" lg="4" xl="4">
                        <OlieChart></OlieChart>
                    </v-col> -->
                    <v-col cols="12" md="6" lg="4" xl="4">
                        <ElektrakoersenChart></ElektrakoersenChart>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="4">
                        <TTFGasEndexChart></TTFGasEndexChart>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="4">
                        <APXChart></APXChart>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import helper from "@/utils/helper.js";
// import ValutaChart from "../components/marktprijzen/ValutaChart.vue";
import LebaChart from "../components/marktprijzen/LebaChart.vue";
// import OlieChart from "../components/marktprijzen/OlieChart.vue";
import APXChart from "../components/marktprijzen/APXChart.vue";
import ElektrakoersenChart from "../components/marktprijzen/ElektrakoersenChart.vue";
import TTFGasEndexChart from "../components/marktprijzen/TTFGasEndexChart.vue";

export default {
    title: "Gegevens",
    components: {
        // ValutaChart,
        LebaChart,
        // OlieChart,
        ElektrakoersenChart,
        TTFGasEndexChart,
        APXChart
    },

    apollo: {},

    data() {
        return {};
    },

    computed: {},
    created() {
        this.hasPermission = helper.hasPermission;
    },
    mounted() {},
    methods: {}
};
</script>
