<template>
    <!-- Edit profile -->
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="primary white--text">
                {{ formTitle }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-alert v-if="error" tile type="error" class="mb-0">{{
                error
            }}</v-alert>

            <v-card-text class="pt-10">
                <v-form ref="form">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.firstName"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="Voornaam*"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.lastName"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="Achternaam*"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="editedItem.email"
                                :rules="[rules.required, rules.maxLength(100)]"
                                label="E-mailadres*"
                                type="email"
                                outlined
                                class="subtitle-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions class="pb-5 pr-5">
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="isSaving"
                    text
                    rounded
                    @click="closeUserDialog"
                    >Annuleren</v-btn
                >
                <v-btn
                    outlined
                    rounded
                    :loading="isSaving"
                    color="primary"
                    @click="saveUser"
                    >Opslaan</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
    name: "update-myprofile-form",
    props: ["dialog"],
    data() {
        return {
            error: null,
            isSaving: false,

            editedItem: {
                email: null,
                firstName: null,
                lastName: null
            },
            defaultItem: {
                email: null,
                firstName: null,
                lastName: null
            },
            rules: {
                required: v => !helper.isEmpty(v) || "Dit veld is verplicht",
                minLength: len => v =>
                    (v || "").length >= len ||
                    `Invalid character length, required ${len}`,
                maxLength: len => v => (v || "").length <= len || "Too long"
            }
        };
    },
    computed: {
        me() {
            return this.$store.state.user.me || {};
        },
        formTitle() {
            return "Profiel wijzigen";
        }
    },
    watch: {
        // reset form when dialog open or close
        dialog() {
            this.resetForm();
        }
    },
    created() {
        this.hasPermission = helper.hasPermission;
    },
    methods: {
        resetForm() {
            // reset form state
            this.error = null;
            this.isSaving = false;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }

            // when editing, set form with object
            if (this.dialog) {
                this.editedItem.email = this.me.email;
                this.editedItem.firstName = this.me.firstName;
                this.editedItem.lastName = this.me.lastName;
            }
            // when new or closing, set form with default
            else {
                this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
            }
        },

        closeUserDialog() {
            this.$emit("update:dialog", false);
            this.resetForm();
        },

        saveUser() {
            if (!this.$refs.form.validate()) {
                return;
            }

            // set form state
            this.error = null;
            this.isSaving = true;

            // prepare api call payload
            var payload = { ...this.editedItem };

            this.$apollo
                .mutate({
                    // Query
                    mutation: gql`
                        mutation updateMyProfile(
                            $input: UpdateMyProfileInput!
                        ) {
                            updateMyProfile(input: $input) {
                                user {
                                    id
                                    email
                                    firstName
                                    lastName
                                }
                            }
                        }
                    `,
                    // Parameters
                    variables: {
                        input: { ...payload }
                    }
                })
                .then(() => {
                    this.closeUserDialog();
                    this.$emit("callback");
                    this.$emit("done");
                })
                .catch(error => {
                    if (error.graphQLErrors) {
                        // this.error = error.graphQLErrors[0].message;
                        console.error(error.graphQLErrors[0].message);
                        // this.error =
                        //     "Er is iets misgegaan, probeer het later opnieuw.";
                        this.error = error.graphQLErrors[0].message;
                    }
                })
                .finally(() => {
                    this.isSaving = false;
                });
        }
    }
};
</script>