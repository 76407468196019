var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[_c('Snackbar'),(_vm.isLoggedIn && _vm.isLoggedInAsOtherUser)?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":"","dense":""}},[_c('v-container',[_c('v-row',{staticClass:"align-center mx-0"},[_c('span',[_vm._v(" Ingelogd als ")]),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"white","outlined":""}},[_c('strong',{staticClass:"text-truncate",attrs:{"max-width":"50"}},[_vm._v(" "+_vm._s(_vm.me.firstName)+" "+_vm._s(_vm.me.lastName))]),_c('span',{staticClass:"ml-2 d-none d-md-block"},[_vm._v(" ("+_vm._s(_vm.me.email)+")")])]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"pink"},on:{"click":_vm.logoutAsOtherUser}},[_vm._v("Exit")])],1)],1)],1):_vm._e(),_c('v-main',[(_vm.me.require2fa && !_vm.me.enabled2fa)?_c('v-alert',{staticClass:"mb-0",attrs:{"color":"red lighten-1","dark":"","tile":"","dense":"","transition":"scale-transition"}},[_c('v-container',{staticClass:"py-0 px-6"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("warning")]),_vm._v(" 2FA is vereist, ga naar \"INSTELLINGEN\" om het in te schakelen. ")],1)],1):_vm._e(),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],staticClass:"py-0",attrs:{"flat":"","color":"white"}},[_c('v-row',{staticClass:"ma-0 align-center hidden-lg-and-up"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('a',{attrs:{"href":"/","target":"_blank"}},[_c('v-img',{staticClass:"mt-3",attrs:{"src":require('@/assets/logo-nife-lg-notext.png'),"max-width":"100px","max-height":"41px","contain":""}})],1)]),_c('v-spacer'),_c('v-col',{staticClass:"pt-5",attrs:{"cols":"auto"}},[(!_vm.me.isStaff)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":"grey darken-1"},on:{"click":function($event){return _vm.pushTo('instellingen')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,false,3077389122)},[_c('span',[_vm._v("Instellingen")])]):_vm._e(),(_vm.isLoggedIn)?_c('Notifications'):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":"grey darken-1"},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("logout")])],1)]}}])},[_c('span',[_vm._v("Uitloggen")])])],1)],1),_c('v-row',{staticClass:"ma-0 align-center"},[_c('v-col',{staticClass:"py-1 hidden-md-and-down",attrs:{"cols":"auto"}},[_c('a',{attrs:{"href":"/"}},[_c('v-img',{staticClass:"mt-3",attrs:{"src":require('@/assets/logo-nife-lg-notext.png'),"max-width":"100px","max-height":"41px","contain":""}})],1)]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.me.isStaff)?_c('v-tabs',{staticClass:"justify-center mt-3",attrs:{"center-active":"","hide-details":"","show-arrows":""},model:{value:(_vm.staffTab),callback:function ($$v) {_vm.staffTab=$$v},expression:"staffTab"}},[_c('v-tab',{on:{"click":function($event){return _vm.pushTo('accounts')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("home")]),_vm._v("Accounts")],1),_c('v-tab',{on:{"click":function($event){return _vm.pushTo('instellingen')}}},[_vm._v("Instellingen")])],1):_vm._e(),(_vm.me.isTenantStaff)?_c('v-tabs',{staticClass:"justify-center mt-3",attrs:{"center-active":"","hide-details":"","show-arrows":""},model:{value:(_vm.userTab),callback:function ($$v) {_vm.userTab=$$v},expression:"userTab"}},[_c('v-tab',{on:{"click":function($event){return _vm.pushTo('overzicht')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("home")]),_vm._v("Overzicht")],1),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                                _vm.me.isTenantAdmin ||
                                    _vm.hasPermission(
                                        'energy.view_energyinvoice'
                                    ) ||
                                    _vm.hasPermission(
                                        'energy.view_serviceinvoice'
                                    )
                            ),expression:"\n                                me.isTenantAdmin ||\n                                    hasPermission(\n                                        'energy.view_energyinvoice'\n                                    ) ||\n                                    hasPermission(\n                                        'energy.view_serviceinvoice'\n                                    )\n                            "}],on:{"click":function($event){return _vm.pushTo('energiefacturen')}}},[_vm._v("Facturen")]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                                _vm.me.isTenantAdmin ||
                                    _vm.hasPermission(
                                        'energy.view_electricitymeasurement'
                                    )
                            ),expression:"\n                                me.isTenantAdmin ||\n                                    hasPermission(\n                                        'energy.view_electricitymeasurement'\n                                    )\n                            "}],on:{"click":function($event){return _vm.pushTo('verbruik')}}},[_vm._v("Verbruik")]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                                _vm.me.isTenantAdmin ||
                                    _vm.hasPermission(
                                        'energy.view_energycontract'
                                    )
                            ),expression:"\n                                me.isTenantAdmin ||\n                                    hasPermission(\n                                        'energy.view_energycontract'\n                                    )\n                            "}],on:{"click":function($event){return _vm.pushTo('allcontracten')}}},[_vm._v("Contracten")]),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                                _vm.me.isTenantAdmin ||
                                    _vm.hasPermission('energy.view_contact')
                            ),expression:"\n                                me.isTenantAdmin ||\n                                    hasPermission('energy.view_contact')\n                            "}],on:{"click":function($event){return _vm.pushTo('gegevens')}}},[_vm._v("Contactgegevens")]),_c('v-tab',{on:{"click":function($event){return _vm.pushTo('marktprijzen')}}},[_vm._v("MarktPrijzen")]),_c('v-tab',{staticClass:"pa-0 ma-0",staticStyle:{"min-width":"0px"}})],1):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"pt-5 px-2 hidden-md-and-down",attrs:{"cols":"auto"}},[(!_vm.me.isStaff)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":"grey darken-1"},on:{"click":function($event){return _vm.pushTo('instellingen')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,false,3077389122)},[_c('span',[_vm._v("Instellingen")])]):_vm._e(),(_vm.isLoggedIn)?_c('Notifications'):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":"grey darken-1"},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("logout")])],1)]}}])},[_c('span',[_vm._v("Uitloggen")])])],1)],1)],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],staticClass:"mt-3",attrs:{"flat":"","color":"white"}}),_c('router-view'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn && _vm.me.isTenantStaff),expression:"isLoggedIn && me.isTenantStaff"}],staticClass:"py-1",style:({
                top: '50%',
                left: '-52px',
                transform: 'rotate(90deg)'
            }),attrs:{"dark":"","fixed":"","bottom":"","left":"","tile":"","color":"grey"},on:{"click":function($event){_vm.feedbackFormDialog = !_vm.feedbackFormDialog}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("feedback")]),_vm._v(" Feedback ")],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn && _vm.me.isTenantStaff),expression:"isLoggedIn && me.isTenantStaff"}],staticClass:"py-1",style:({
                bottom: '40px',
                right: '10px'
            }),attrs:{"dark":"","fixed":"","bottom":"","right":"","rounded":"","color":"warning"},on:{"click":function($event){_vm.tutorialsDialog = !_vm.tutorialsDialog}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("help")]),_vm._v(" Help ")],1)],1),_c('FeedbackForm',{attrs:{"dialog":_vm.feedbackFormDialog},on:{"update:dialog":function($event){_vm.feedbackFormDialog=$event}}}),(_vm.tutorialsDialog)?_c('Tutorials',{attrs:{"dialog":_vm.tutorialsDialog},on:{"update:dialog":function($event){_vm.tutorialsDialog=$event}}}):_vm._e(),_c('v-footer',{attrs:{"padless":""}},[_c('v-card',{staticClass:"flex",attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"py-2 grey--text text-center"},[_c('strong',[_vm._v("Disclaimer:")]),_vm._v(" Aan geen van de op deze portal getoonde gegevens kunnen rechten worden ontleend. Wij behouden ons het recht voor om deze gegevens te wijzigen zonder voorafgaande mededeling. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }