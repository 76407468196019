import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import Print from "vue-print-nb";
import "./utils/filters";
import titleMixin from "./mixins/titleMixin";
import VueGtag from "vue-gtag";

Vue.mixin(titleMixin);
Vue.use(Print);

Vue.config.productionTip = false;

// const locale = navigator.language || navigator.userLanguage;
// console.log(locale);

Vue.use(
    VueGtag,
    {
        config: {
            id: "G-TYFYPXF0K8"
        }
    },
    router
);

const moment = require("moment");
require("moment/locale/nl");
Vue.use(require("vue-moment"), {
    moment
});

// console.log(Vue.moment().locale()); //nl

new Vue({
    router,
    store,
    vuetify,
    apolloProvider: createProvider(),
    render: h => h(App)
}).$mount("#app");
